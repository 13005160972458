import { Routes, Route , useNavigate } from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./pages/Main";
import ShopPage from "./pages/ShopPage";
import ServicePage from "./pages/ServicePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import CartPage from "./pages/CartPage";
import DetailsPage from "./pages/DetailsPage";
import React, { useEffect, useState } from "react";
import Privacy from "./components/Privacy";
import Impressum from "./components/Impressum";
import Szechenyi from "./components/Szechenyi";
import PickUpSite from "./components/PickUpSite";
import Dpi from "./components/Dpi";
import PickUpSite2 from "./components/PickUpSite2";
import Admin from "./components/Admin";

function App() {
  const [isAuthorized, setIsAuthorized] = useState(() => localStorage.getItem("isAuthorized") === "true");

const navigate = useNavigate();

  const [cart, setCart] = useState([]);

  const handleClick = (item) => {
    if (cart.some((cartItem) => cartItem._id === item._id)) return;

    setCart([...cart, item]);

    console.log(item); // Ezt eltávolíthatod, ha már nincs szükség rá
  };

  const handleChange = (item, d) => {
    const ind = cart.indexOf(item);
    const arr = cart;
    arr[ind].amount += d;
    if (arr[ind].amount === 0) arr[ind].amount = 1;
    setCart([...arr]);
  };
  useEffect(() => {
    console.log("Cart Changed");
  }, [cart]);

  return (
      <div className="App">
        <Header size={cart.length} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/termekek" element={<ShopPage handleClick={handleClick} />} />
          <Route path="/termekek/:categoryName" element={<ShopPage handleClick={handleClick} />} /> 
          <Route path="/termekek/:categoryName/:subCategoryName" element={<ShopPage handleClick={handleClick} />} /> 
          <Route path="/cart" element={<CartPage cart={cart} setCart={setCart} handleChange={handleChange} />} />
          <Route path="/:category/:subcategory/:name" element={<DetailsPage handleClick={handleClick} />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/admin" element={isAuthorized ? <Admin /> : <Main />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/dpi" element={<Dpi />} />
          <Route path="/impresszum" element={<Impressum />} />
          <Route path="/szechenyi" element={<Szechenyi />} />
          <Route path="/thanks" element={<PickUpSite />} />
          <Route path="/thank" element={<PickUpSite2 />} />
        </Routes>
  
        <Footer />
  
        <CookieConsent
          location="bottom"
          buttonText="Rendben van!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
        >
          Ez a weboldal a jobb felhasználói élmény érdekében cookie-kat használ{" "}
          <span style={{ fontSize: "10px" }}>*Kérem jelölje be.</span>
        </CookieConsent>
      </div>
  );
}

export default App;
