export const sliderData = [
  {
    image: "/images/stern_weber_s320.jpeg",
    heading: "Stern Weber - S320TR",
    desc: "Az új S320TR egy nagy teljesítményű multitouch 7 hüvelykes kijelzővel rendelkezik, amely maximalizálja a dinamikus eszközök és az integrált rendszerek vezérlését.",
    link: "/termekek/fogászati_kezelőegységek", 
  },
  {
    image: "/images/sw_autoklavok.jpg",
    heading: "Autoklávok",
    desc: "Stern Weber Autoklávok: Ezek a sterilizáló berendezések különböző méretű (17, 22, 28 liter) verziókban kaphatók, amelyek biztosítják a fogászati eszközök biztonságos sterilizálását és fertőtlenítését.",
    link: "/termekek/sterilizáló_berendezések",
  },
  {
    image: "/images/x9pro.jpg",
    heading: "Digitális fogászati panorámaröntgen - X9 Pro",
    desc: "Innováció, erő és sokoldalúság Könnyen fejleszthető bármilyen konfigurációra.HD MultiPAN vizsgálat 5 különböző fókuszponttal",
    link: "/termekek/fogászati_röntgenberendezések", 
  },
  {
    image: "/images/variosurg4-prospektus_5.jpg",
    heading: "NSK - VarioSurg 4 & SurgicPro 2",
    desc: "Komplett szett - Sebészeti, parodontológiai és endodonciás programokkal NSK SurgicPro2 implantációs motorral csatlakoztatható",
    link: "/termekek/implantológiai_berendezések",  
  },
];


export const sliderData2 = [
  {
    image: "/images/stern_weber_s320.jpeg",
    heading: "Stern Weber - S320TR",
    desc: "Az új S320TR egy nagy teljesítményű multitouch 7 hüvelykes kijelzővel rendelkezik, amely maximalizálja a dinamikus eszközök és az integrált rendszerek vezérlését.",
    link: "/fogászati_kezelőegységek/stern_weber_kezelőegységek/s320tr", 
  },
  {
    image: "/images/sw_autoklavok.jpg",
    heading: "Autoklávok",
    desc: "Stern Weber Autoklávok: Ezek a sterilizáló berendezések különböző méretű (17, 22, 28 liter) verziókban kaphatók, amelyek biztosítják a fogászati eszközök biztonságos sterilizálását és fertőtlenítését.",
    link: "/termekek/sterilizáló_berendezések/stern_weber_autokláv",
  },
  {
    image: "/images/x9pro.jpg",
    heading: "Digitális fogászati panorámaröntgen - X9 Pro",
    desc: "Innováció, erő és sokoldalúság Könnyen fejleszthető bármilyen konfigurációra.HD MultiPAN vizsgálat 5 különböző fókuszponttal",
    link: "/fogászati_röntgenberendezések/digitális_panorámaröntgen/x9_pro", 
  },
  {
    image: "/images/variosurg4-prospektus_5.jpg",
    heading: "NSK - VarioSurg 4 & SurgicPro 2",
    desc: "Komplett szett - Sebészeti, parodontológiai és endodonciás programokkal NSK SurgicPro2 implantációs motorral csatlakoztatható",
    link: "/implantológiai_berendezések/implantológiai_berendezések/variosurg4_&_surgicpro2",  
  },
];