import { v4 as uuidv4 } from "uuid";

export const categories = [
  {
    id:0,
    name: "Fogászati Kezelőegységek",
    description: "A fogászati kezelőegységek a fogorvosi praxis alapvető elemei, amelyek lehetővé teszik a fogorvosok számára, hogy hatékonyan végezzék el a kezeléseket. Az ergonomikus kialakítás és az innovatív technológia segíti a kényelmes munkavégzést.A Stern Weber modellek, mint például az S200-tól az S380TRC-ig, különböző funkcionalitást és ergonómiai megoldásokat kínálnak. Orvosi-asszisztensi gurulós székek: Ergonomikus, könnyen mozgatható székek, amelyek a fogorvosok és asszisztensek számára biztosítanak kényelmet és megfelelő pozíciót a kezelések során. A T7-től T9-ig terjedő modellek mind a funkcionalitást, mind a komfortot támogatják."
  },
  {
    id:1,
    name: "Fogászati Röntgenberendezések",
    description: "A fogászati röntgenek gyors és pontos diagnosztikai megoldásokat nyújtanak. A panorámaröntgenek, intraorális röntgenek és digitális szenzorok segítségével a fogorvosok részletes képet kapnak a páciens fogazatáról.Digitális fogászati panorámaröntgenek lehetővé teszik a teljes fogazat és az állkapocs átfogó képalkotását. A Myray X5 és X9 Pro modellek különféle 2D és 3D képalkotó megoldásokat kínálnak, amelyek a diagnosztikai pontosságot segítik elő. Az intraorális röntgenek kisméretű képeket készítenek az egyes fogakról, segítve a precíz diagnózist. A Myray RXDC modellek fejlett technológiával biztosítják a tiszta, részletgazdag képeket. A foszforlemezes digitalizálók, intraorális szenzorok és 3D szkennerek modern megoldásokat kínálnak a digitális fogászati képek létrehozásához és feldolgozásához, elősegítve a gyorsabb és pontosabb diagnózist."
  },
  {
    id:2,
    name: "Sterilizáló Berendezések",
    description: "A fogászati sterilizáló berendezések alapvető fontosságúak a fogászati eszközök fertőtlenítéséhez. Az autoklávok és kiegészítők biztosítják a hatékony sterilizálást, csökkentve a fertőzések kockázatát.A Stern Weber autoklávok 17, 22 és 28 literes méretekben kaphatók, biztosítva a fogászati eszközök biztonságos sterilizálását. A Tecno-Gaz B osztályú autoklávok, különösen az Europa B Pro sorozat, ideálisak rendelők számára, míg az Enbio autoklávok szupergyors sterilizálást kínálnak (Enbio Pro 18 perc, Enbio S 15 perc). Kiegészítők, mint a fóliahegesztők és vízdesztillálók, optimalizálják a sterilizálási folyamatot, a Tehtys H10 Plus pedig professzionális mosó- és fertőtlenítő gép."
  },
  {
    id:3,
    name: "Fogászati Kézidarabok",
    description: "A fogászati kézidarabok nélkülözhetetlen eszközök a precíz kezelésekhez. Ide tartoznak az egyenes- és könyökdarabok, mikromotorok, valamint különböző specializált eszközök, mint az endodonciás készülékek vagy a turbinák. Ezek az eszközök nagy teljesítményt és megbízhatóságot nyújtanak a mindennapi fogorvosi munkában."
  },
  {
    id:4,
    name: "Fogászati Anyagok",
    description: "A fogászati anyagok különböző típusú anyagok, amelyeket a fogorvosok használnak a fogak helyreállítására, védelmére, vagy esztétikai megjelenésének javítására. Ezek az anyagok változatos tulajdonságokkal rendelkeznek annak érdekében, hogy különböző fogászati problémákra megoldást nyújtsanak. Főbb kategóriáik a következők:tömőanyagok, lenyomatanyagok, protetikai anyagok, cementek, fogszabályozási anyagok, ideiglenes anyagok."
  },
  {
    id:5,
    name: "Implantológiai Berendezések",
    description: "Az implantológiai berendezések kiváló megoldásokat kínálnak a sebészeti és implantációs eljárások során. A modern technológiák, mint a NSK Surgic Pro sorozat, biztosítják a pontos fúrást és beavatkozást, míg a különféle könyökdarabok és kiegészítők optimalizálják az implantációs műtétek hatékonyságát."
  },
  {
    id:6,
    name: "Elszívók-Kompresszorok",
    description: "Az elszívó berendezések a fogászati berendezések vákuum előállítására szolgálnak. Bármilyen típusú fogászati berendezéshez alkalmazhatóak, amelyen ki van építve a tömlőrendszer a nagyelszíváshoz, és az ülepitő automatika.a kompresszorok biztosítják a szükséges sűrített levegőt a fogászati eszközök működtetéséhez. A modern rendszerek, mint például a Cattani kompresszorok és a Turbo Smart elszívók, magas szintű teljesítményt nyújtanak, miközben minimális zajszintet garantálnak a rendelőkben."
  },
  {
    id:7,
    name: "Elektrokauterek",
    description: "Az elektrokauterek elektromos árammal végzik a szövetek vágását és koagulációját, lehetővé téve a vérzés minimalizálását. A Surgery Plus MONOPOLARE és Surtron 120 MONO/BIPOLARE modellek precíz és biztonságos megoldásokat nyújtanak a fogászati és sebészeti beavatkozásokhoz."
  },
  {
    id:8,
    name: "Fogászati-rendelő Bútorok",
    description: "A fogászati rendelő bútorai nemcsak esztétikai szempontból fontosak, hanem a praktikusság és a kényelem érdekében is. Az Aurora, Elite, Elite Touch és Prestige bútorok ergonomikus kialakításukkal és modern megjelenésükkel növelik a rendelő funkcionalitását és kényelmét."
  },
  {
    id:9,
    name: "Operációs Lámpák",
    description: "A fogászati operációs lámpák segítenek a kezelési terület precíz megvilágításában, optimalizálva a látási viszonyokat a fogászati beavatkozások során. A modern lámpák, mint például az ORION 40DS és a Faro modellek, állítható fényerőt és kiváló megvilágítási minőséget biztosítanak."
  },
  {
    id:10,
    name: "Kiegészítők",
    description: "A fogászati kiegészítők kategóriájában különböző eszközök találhatók, amelyek kiegészítik a mindennapi fogorvosi munkát. Ide tartoznak az éberszedálók, fogfehérítők, polimerizációs lámpák és sterilizáló rendszerek, amelyek növelik a hatékonyságot és a páciensek kényelmét.Az éberszedálók enyhe nyugtatást biztosítanak fogászati kezelések során, míg a professzionális fogfehérítők gyors eredményt nyújtanak. A fóliahegesztők steril csomagolást biztosítanak az eszközöknek, a levegőtisztítók pedig tisztítják a kezelőhelyiség levegőjét. A polimerizációs lámpák a fogtömő anyagok megkeményítésére, a depurátor hegyek pedig fogkő eltávolítására szolgálnak."
  },
  {
    id:11,
    name: "Ápolás és Karbantartás",
    description: "A fogászati eszközök rendszeres karbantartása kulcsfontosságú a hosszú távú működésükhöz. Az olajzó spray-k, fertőtlenítő folyadékok és olajzó berendezések segítenek a fogászati kézidarabok és más eszközök állapotának megőrzésében.Olajzó spray-k: Fogászati eszközök karbantartásához használt kenőanyagok, amelyek meghosszabbítják azok élettartamát.Kézidarab Olajzó berendezések: Automatikus karbantartó rendszerek a fogászati kézidarabok tisztításához és olajozásához.Fertőtlenítő folyadékok: Hatékony megoldások a felületek és eszközök fertőtlenítésére, biztosítva a higiénikus munkakörnyezetet."
  },
  {
    id:12,
    name: "Egyéb Berendezések",
    description: "Ez a kategória olyan mobil eszközöket foglal magába, mint a műszerkocsik, amelyek praktikus megoldásokat nyújtanak a fogorvosi eszközök szervezett tárolására és könnyű elérhetőségére a kezelések során.Mobil eszközkocsik, amelyek lehetővé teszik a szükséges eszközök kényelmes elhelyezését és mozgatását a kezelések során. A különböző modellek ergonomikus kialakítással és változatos fiókelrendezéssel rendelkeznek."
  }
];

export const products = [
  {
    _id: uuidv4(),
    image: "S200.jpg",
    image2: "S200(2).jpg",
    image3: "S200(3).jpg",
    image4: "S200(4).jpg",
    image5: "S200(5).jpg",
    sale: 1,
    typ: "Stern Weber S200",
    name: "S200",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    desc: "Megbízhatóság, minőség, ergonómia.",
    desc2: "Teljes körű kényelem: A Stern Weber márka minden jellegzetes tulajdonságával az S200 modell - fogszabályozó orvosoknak készült verziója is - a beteg kényelmének maximalizálására lett kialakítva.",
    desc3:
      "Az Ön szakmai fejlődésének partnere: Az S200 jóval meghaladja kategóriájának többi képviselőjét, és biztosítja a legújabb Stern Weber által kifejlesztett újításokat: beépítheti az opcionális, könnyen használható funkciókat és speciális eszközöket, amelyek a szakmai színvonalat a következő szintre emelik.",
    desc4:
      "Számos bővítési lehetőséget biztosít: A higiéniai rendszerek széles választékának, az eszközöknek és az opcionális multimédiás alkalmazásoknak köszönhetően a fogorvosok testre szabhatják az S200-at az egyéni igényeknek megfelelően, és élvezhetik a számtalan élvonalbeli eszköz teljes élményét.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    image: "S300.jpg",
    image2: "S300(2).jpg",
    image3: "S300(3).jpg",
    image4: "S300(4).jpg",
    image5: "S300(5).jpg",
    sale: 0,
    slug: "produktum 2",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    typ: "Stern Weber S300",
    name: "S300",
    desc: "Folyamatos innováció, tökéletes integráció, több lehetőség.",
    desc2:
      "Innovatív irányítás: A fogorvos szakemberek számára munkájukhoz teljes kényelmet biztosít, ez az oka annak, hogy az S300 tartalmazza a legújabb fogászati technológiákat, mint például az új 7 hüvelykes multitouch kijelzőt, mely a nagy teljesítményű mikroprocesszornak köszönhetően maximalizálja a dinamikus eszközök és az integrált rendszerek vezérlését.",
    desc3:
      "Ergonómia és komfort: A fogászati egység és a korszerűen kialakított betegszék modern kompaktsága mellett az S300-at úgy tervezték, hogy az exkluzív ergonómia - például a háttámla mozgatása esetén a bevilágított terület nem változik (sliding-system) - és a páciens kényelmének példátlan szintjét kínálja.",
    desc4:
      "Multimédia rendszer: Az S300 kiegészíthető a fogászati egységbe integrált rendszerek széles választékával. A fejlett röntgendiagnosztika, a HD képalapú kommunikációs rendszer és az összes minősített higiéniai eszköz kombinációja teszi az S300-at egy komplett, integrált kezelőközponttá, amely a legigényesebb fogorvos számára is megfelelő.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    image: "S220TRfelso.jpg",
    image2: "S220TRalso.jpg",
    image3: "S220TR(3).jpg",
    image4: "S220TR(4).jpg",
    image5: "S220TR(5).jpg",
    sale: 0,
    slug: "produktum 3",
    typ: "Stern Weber S200TR",
    name: "S200TR",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    desc: "Sokoldalúsága a legjobb, a tökéletes ergonómiai megoldás minden kezelési stílushoz.",
    desc2:
      "Ergonómia: Integrált kezelőközpont padlóra szerelt vizesegységgel, melyet olyan fogorvosok számára terveztek, akik rugalmas, megbízható, különböző funkciókkal felszerelt és csúcsminőségű tartozékokkal rendelkező fogászati kezelőegységet szeretnének, hogy minden igénynek megfeleljen.",
    desc3:
      "Teljesítmény-irányítás: Instrument vezérlőpanel LCD érintőképernyővel az eszközök és a fogászati egység funkcióinak felhasználóbarát vezérlésére. A beállítások azonnal és könnyen olvashatók és vezérelhetők; ezen kívül az adatok / funkciók bősége lehetővé teszi a felhasználók számára a fejlett endodonciai kezelések elvégzését.",
    desc4:
      "Az integráció széleskörű választéka: Az S220TR széles körű exkluzív tartozékokkal rendelkezik, hogy maximalizálja a kényelmet mind a beteg, mind a fogorvos számára: fejlett higiéniai rendszerek a szennyeződés kockázatának kiküszöbölésére és egy beépített rendszer, amely a legmodernebb röntgen diagnosztikai eszközökből áll.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "S320TR",
    image: "S320TR.jpg",
    image2: "S320TR(2).jpg",
    image3: "S320TR(3).jpg",
    image4: "S320TR(4).jpg",
    image5: "S320TR(5).jpg",
    sale: 0,
    slug: "produktum 4",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    desc: "Tiszta innováció a fogorvosoknak.",
    desc2:
      "Az innováció ereje: A legfejlettebb technológia, melyet a fogorvos igényeit teljesen ismerő személyek nagy gondossággal hoztak létre.  Az új S320TR egy nagy teljesítményű multitouch 7 hüvelykes kijelzővel rendelkezik, amely maximalizálja a dinamikus eszközök és az integrált rendszerek vezérlését.",
    desc3:
      "Inspiráló ergonómia: Kompakt kialakítás, könnyebb kulcsfontosságú elemek, exkluzív ergonómiai koncepciók: az új S320TR a fogászati team számára sok előnnyel rendelkezik. Ezenkívül a betegszék - a modern vonalakkal, független vagy szinkronizált mozgásokkal és a háttámla mozgatása esetén a bevilágított terület nem változik (sliding-system)",
    desc4:
      "Maximális potenciál: Maximális klinikai teljesítmény azok számára, akik gyakorolják a konzervatív fogászat, az implantológia és az endodonciális kezelések területén tevékenykednek. Az S320TR egy komplett integrált kezelőközpont, mely megfelel egy vagy több fogorvos számára is, akik egyazon rendelőben dolgoznak.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "S280TRC",
    image: "S280TRCfelso.jpg",
    image2: "S280TRC(2).jpg",
    image3: "S280TRC(3).jpg",
    image4: "S280TRC(4).jpg",
    image5: "S280TRC(5).jpg",
    sale: 0,
    slug: "produktum 5",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    desc: "Magas szintű felszereltség, kényelem.",
    desc2:
      "Mindent az új 7 hüvelykes multi-touch kezelőpanellel irányíthat. A fogászat bármely ágában tevékenykedő fogorvos élvezheti a fejlett irányítórendszer előnyeit, amely kiterjed a speciális kezelésekre, mint például az endodoncia és az implantológia.",
    desc3:
      "Speciális igényekhez igazítható: Az innovatív funkciók és az integrált rendszerek lehetővé teszik a személyre szabott konfigurációkat. A készülék különleges kialakítása felfüggesztett páciensszékkel a fogorvosi team számára exkluzív ergonómiát kínál és a páciens kényelmét is biztosítja.",
    desc4:
      "Integrált multimédia: A nagy felbontású röntgen diagnosztikai és a vizuális kommunikációs rendszerek egészítik ki a választási lehetőségeket, biztosítva az S280TRC működéséhez az összes szükségest eszközt, melyek minden fogorvos számára nélkülözhetetlenek.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "S380TRC",
    image: "S380TRC.jpg",
    image2: "S380TRC(2).jpg",
    image3: "S380TRC(3).jpg",
    image4: "S380TRC(4).jpg",
    image5: "S380TRC(5).jpg",
    sale: 0,
    slug: "produktum 6",
    category: 0,
    subcategory: "Stern Weber Kezelőegységek",
    desc: "Exkluzív innováció, a technológia csúcsa.",
    desc2:
      "A precíziós és a technológiai újítások kombinációja, az S380TRC egy, a kategóriájában egyedülálló betegszéket kínál. Szinkronizált mozgásokkal és laterális ülésfordulással minden funkció kiváló kényelmet biztosít minden kezelési helyzetben.",
    desc3:
      "Tökéletes egyensúly: Az S380TRC-ben a beteg és a fogorvosi személyzet kényelmét és az élvonalbeli teljesítményt kombinálták. Kompakt design, könnyített kulcs-elemek és exkluzív ergonómiai koncepciók, mind tökéletes egyensúlyban.",
    desc4:
      "Tökéletesség a folyamatos fejlődésben: Olyan kezelőegység, amely megfelel minden speciális klinikai igénynek. Az új konzol 7 hüvelykes érintőképernyős kijelzővel és egy erős mikroprocesszorral, a dinamikus műszerek és az integrált rendszerek maximális vezérlésére – ez az S380TRC szíve és lelke.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "T7",
    image: "T7.jpg",
    sale: 0,
    slug: "produktum 7",
    category: 0,
    subcategory: "Orvosi-asszisztensi gurulós székek",
    desc: "Az új T7 szék kölcsönhatásba lép a test anatómiájával, megkönnyítve a fogorvos munkáját azáltal, hogy mindig segíti a helyes testtartást.",
    desc2:
      "A kiváló minőségű anyagok és az innovatív kialakítás biztosítja, hogy a kezelés mindig optimális körülmények között történjen. A puha, könnyen fertőtleníthető anyagból készült kényelmes ülés segíti a fogorvosokat a lábak és a hát megfelelő helyzetében a kezelés során.",
    desc3:
      "Az S-sorozatú fogorvosi székek orvosi használatra tervezett kiegészítők, amelyek biztosítják, hogy a fogorvos vagy az asszisztens megfelelő támogatást kapjon a fogászati kezelések során. Megfelel az ISO 7493 szabványnak.",
    desc4: "A megengedett legnagyobb terhelés 135 kg. Ülésmagasság állítás, dönthető deréktámasz, állítható háttámla magasság",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "T8",
    image: "T8.jpg",
    sale: 0,
    slug: "produktum 8",
    category: 0,
    subcategory: "Orvosi-asszisztensi gurulós székek",
    desc: "A T8 szék tökéletes támogatást nyújt még hosszú kezelések során is, miközben teljes mozgásszabadságot biztosít minden irányban.",
    desc2: "A beállítási és személyre szabási mechanizmusok leegyszerűsítik a rendelő elrendezéséhez való alkalmazkodást és növelik a kezelő hatékonyságát.",
    desc3: "Az S-sorozatú fogorvosi székek orvosi használatra tervezett kiegészítők, amelyek biztosítják, hogy a fogorvos vagy az asszisztens megfelelő támogatást kapjon a fogászati kezelések során.",
    desc4: "Megfelel az ISO 7493 szabványnak. A megengedett legnagyobb terhelés 135 kg. Ülésmagasság állítás, megfordítható háttámla/ karfa",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "T9",
    image: "T9.jpg",
    sale: 0,
    slug: "produktum 9",
    category: 0,
    subcategory: "Orvosi-asszisztensi gurulós székek",
    desc: "A forradalmi nyeregszerű geometriának köszönhetően a T9 a nagyon kis helyigényt páratlan kényelemmel párosítja.",
    desc2: "A lábak függőleges, nyitott helyzete megkönnyíti a páciens megközelítését és a megfelelő háttartás megtartását. Ez csökkenti a fáradtságot, még hosszan tartó kezelés során is üléseken.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Myray Hy-Scan szenzor",
    image: "hyscan.jpg",
    sale: 0,
    slug: "produktum 10",
    category: 1,
    subcategory: "Foszforlemezes digitalizáló",
    desc: "Automata foszforlemezes- röntgendigitalizáló, mely magas képminőséget garantál, számítógéphez USB-vel csatlakoztatható.",
    desc2: "Kompakt méretű, könnyen használható készülék. ",
    desc3: "A hajlítható foszforlemeznek köszönhetően jobban pozícionálható. 4 féle lemezméret: 0 méret: 22 x 31 mm, 762 x 1024 pixel, ",
    desc4: "1 méret: 24 x 40 mm, 792 x 1321 pixel, 2 méret: 31 x 41 mm, 1024 x 1354 pixel, 3 méret: 27 x 54 mm, 891 x 1783 pixel. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "RXDC eXTend",
    image: "rxdcextend.jpg",
    image2: "rxdcextend(2).jpg",
    image3: "rxdcextend(3).jpg",
    image4: "rxdcextend(4).jpg",
    image5: "rxdcextend(5).jpg",
    sale: 0,
    slug: "produktum 11",
    category: 1,
    subcategory: "Intraorális röntgen",
    desc: "Maximális képminőség, minimális dózis. ",
    desc2: "Vezetékes vagy vezeték-nélküli exponálóval. Különleges rugalmasság a vezeték-nélküli vezérlésnek köszönhetően.",
    desc3: "Mindössze 0,4mm fókusz-pont (30 cm-es távolságból) a még élesebb képért és a még precízebb részletekért. 40, 60 vagy 90 cm-es karral. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "RXDC Hypersphere",
    image: "rxdchypersphere.jpg",
    image2: "rxdchypersphere(2).jpg",
    image3: "rxdchypersphere(3).jpg",
    image4: "rxdchypersphere(4).jpg",
    image5: "rxdchypersphere(5).jpg",
    sale: 0,
    slug: "produktum 12",
    category: 1,
    subcategory: "Intraorális röntgen",
    desc: "Vezeték-nélküli exponálás, innovatív gömbcsuklós felfüggesztés a maximális rugalmasság élményéért.",
    desc2: "Mindössze 0,4mm fókusz-pont (30 cm-es távolságból) a még élesebb képért és a még precízebb részletekért.",
    desc3: "40, 60 vagy 90 cm-es karral. ",
    desc4: "Stern Weber kezelőegységre integrálható verzióban is elérhető, a páciens még teljesebb kényelméért! Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Szenzor-X-POD",
    image: "xpod.jpg",
    sale: 0,
    slug: "produktum 13",
    category: 1,
    subcategory: "Intraorális Szenzorok",
    desc: "Megerősített kábel, zárt és akadásmentes visszacsatolás, edzett külső borítás, lekerekített sarkok, vékony profil.",
    desc2: "Megbízható és tartós, két méretben kapható: #1 sensor – 20x30mm aktív terület és #2 sensor – 26x34mm aktív terület. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Szenzor-Zen-X",
    image: "zenx.jpg",
    sale: 0,
    slug: "produktum 14",
    category: 1,
    subcategory: "Intraorális Szenzorok",
    desc: "Ergonomikus design, vékony profil, lekerekített sarkok és flexibilis kábel. ",
    desc2: "HD szenzor, mely többrétegű (CsI + FOP + CMOS). ",
    desc3: "Számítógéphez USB-vel csatlakoztatható az azonnali kép-megjelenítésért. ",
    desc4: "Két méretben kapható: #1 szenzor – 24,9x38,9mm és #2 szenzor – 30,4x41,9mm. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "CU-2 HD",
    image: "cu2hd.jpg",
    sale: 0,
    slug: "produktum 15",
    category: 1,
    subcategory: "Intraorális Kamera",
    desc: "HD (16:9) felbontású intraorális kamera, mely akár a Stern Weber kezelőegységbe is integrálható. 8 LED, 7 magas tisztaságú üveglencse (F/8), 50-szeres nagyítás. ",
    desc2: "Multifunkciós gomb dinamikus nagyítással, széles látószög (90°) Számítógéphez USB-vel csatlakoztatható",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "X5 2D",
    image: "x52dsima.jpg",
    sale: 0,
    slug: "produktum 16",
    category: 1,
    subcategory: "Digitális panorámaröntgen",
    desc: "Könnyű 2D diagnosztika a könnyű és kompakt, falra függeszthető/álló rendszerrel. ",
    desc2: " Egy-kattintásos PAN vizsgálat MRT és automatikus legjobb fókusz-választás technológiával. Quick-Scan 6,6 sec. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "X5 3D",
    image: "x53dsima.jpg",
    sale: 0,
    slug: "produktum 17",
    category: 1,
    subcategory: "Digitális panorámaröntgen",
    desc: "Könnyű 3D/2D diagnosztika a könnyű és kompakt, falra függeszthető/álló rendszerrel.",
    desc2: "3D MultiFOV rendszer: Ultra-magas felbontású CBCT vizsgálat (80qm) 10x10 FOV maximális mérettel egyetlen felvétellel, vagy MultiFOV felvétel készítése az adott terület exponálásának csökkentésével.",
    desc3: "Quick-Scan 6,4 sec. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "X9 Pro",
    image: "x9prosima.jpg",
    image2: "x9prosima(2).jpg",
    image3: "x9prosima(3).jpg",
    sale: 0,
    slug: "produktum 18",
    category: 1,
    subcategory: "Digitális panorámaröntgen",
    desc: "Innováció, erő és sokoldalúság.",
    desc2: "Könnyen fejleszthető bármilyen konfigurációra. ",
    desc3: "HD MultiPAN vizsgálat 5 különböző fókuszponttal. Opcionális Full Touch 10 hüvelykes irányítópanel. QuickPAN 6sec. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "X9 Pro CB3D",
    image: "x9procb3d.jpg",
    sale: 0,
    slug: "produktum 19",
    category: 1,
    subcategory: "Digitális panorámaröntgen",
    desc: "Innováció, erő és sokoldalúság. ",
    desc2: "Könnyen fejleszthető bármilyen konfigurációra.",
    desc3: "Multi FOV. 4x4 - 13x16cm (opcionális). Extrém magas felbontás (68qm). Gyors CB3D scan (3,6-6,4 sec).",
    desc4: "Fül, orr, torok és sinusok vizsgálata FOG szakorvosok számára. Opcionális Full Touch 10 hüvelykes irányítópanel. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "X9 Pro CEPH",
    image: "x9proceph.jpg",
    sale: 0,
    slug: "produktum 20",
    category: 1,
    subcategory: "Digitális panorámaröntgen",
    desc: "Innováció, erő és sokoldalúság. ",
    desc2: "Könnyen fejleszthető bármilyen konfigurációra.",
    desc3: "Ultra-gyors szkennelés, áthelyezhető PAN/CEPH szenzor vagy opcionálisan két szenzor.",
    desc4: "Opcionális Full Touch 10 hüvelykes irányítópanel. QuickCEPH 3,3sec. Kezelőprogram: iRYS",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "SW 17,22,28 literes",
    image: "swautoklav.jpg",
    sale: 0,
    slug: "produktum 21",
    category: 2,
    subcategory: "Stern Weber autokláv",
    desc: "Az SW autokláv leegyszerűsíti a kezelő munkáját, optimalizálja az időt és a költségeket.",
    desc2: "A minőség és a magas szintű funkciók biztosítják a biztonságot, megbízhatóságot és a könnyű használatot a monokróm kijelző és billentyűzet segítségével.",
    desc3: "Beépített Wi-Fi-vel rendelkezik. 17, 22, 28 literes kivitelben",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "SW Platinum 17,22,28 literes",
    image: "swautoklavplatinum.jpg",
    sale: 0,
    slug: "produktum 22",
    category: 2,
    subcategory: "Stern Weber autokláv",
    desc: "50 sterilizációs ciklus mindössze 5 liter csapvízzel!",
    desc2: "A fogyasztása kb. 100ml víz/ciklus.",
    desc3: "Kifejlesztették az első zárt-hurkú sterilizátort, amely egy speciális belső szűrőrendszert használ, amely a következő ciklusokban is újrahasznosítja a vizet.",
    desc4: "Ez a teljesítmény kiküszöböli a pazarlást, csökkenti a költségeket és biztosítja a modern fogászati műtétek hatékonyságának jelentős növekedését.",
    desc5: "A demineralizált víz tárolásával és ellátásával kapcsolatos valamennyi kérdést is megoldja.",
    desc6: "A 7 hüvelykes nagyméretű érintőképernyő, amely az autokláv közepén helyezkedik el, lehetővé teszi, hogy egyszerűen és intuitívan használhassa, mint egy tabletet.",
    desc7: "A felhasználónak állandó hozzáférése van a szükséges adatokhoz, és megtekintheti az összes hasznos információt a képernyőn. 17, 22, 28 literes kivitelben",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "SW Plus 17,22,28 literes",
    image: "swautoklavplus.jpg",
    sale: 0,
    slug: "produktum 23",
    category: 2,
    subcategory: "Stern Weber autokláv",
    desc: "Az integrált demineralizáló szűrőnek köszönhetően az SW-PLUS csapvízzel működhet önálló géppel.",
    desc2:
      "Ez a modell 4,3 hüvelykes érintőképernyővel rendelkezik. Az SW-PLUS sterilizátor egy intelligens és alapvető munkaeszköz, amely kiváló színvonalat képvisel a színes LCD érintőképernyőnek és a felhasználóbarát felületnek is köszönhetően. ",
    desc3: "Minden parancs megfelel a megjelenő ikonoknak, amelyek biztosítják a gép állapotának azonnali visszajelzését. 17, 22, 28 literes kivitelben",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Aura B",
    image: "aurab.jpg",
    sale: 0,
    slug: "produktum 24",
    category: 2,
    subcategory: "Tecno-Gaz autokláv",
    desc: "Egy exkluzív lehetőség, amely biztosítja az időmegtakarítást, a közvetlenség és a maximális támogatást az üzemeltetőnek. ",
    desc2: "Internet kapcsolattal közvetlenül Tecno-Gaz által üzemeltetett honlapon keresztül elérhetőek az autokláv technikai frissítései. Ez a megoldás lehetővé teszi a folyamatos frissítést. ",
    desc3:
      "Internet kapcsolat szükséges a távoli segítségnyújtás érdekében. Az autokláv rögzíti a funkcionális adatokat, és a rendszer tevékenységeket tárolja belső flash memórián, elküldi az összes összegyűjtött adatot és a Tecno-Gaz szerver automatikusan elemzi a helyes működését.",
    desc4: "Ha szükséges a távoli segítségnyújtás szolgáltatás aktiválódik, anélkül, hogy a felhasználó kérné, ez a rendkívüli hatékony megoldás biztosítja az operatív biztonságot.",
    desc5:
      "Tápfeszültség: 230 ± 10% VAC, Maximális áram biztosítékok: 10 A, Tápfeszültség jelentése: 50-60 Hz, Maximális elektromos elnyelt: 1700 W, Nettó tömege termék: 61 Kg. Termék szélessége: 504mm. Termék magassága: 400mm. Termék mélysége: 640mm. Kezdő év a gyártási és forgalmazási: 2009.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Europa B Evo",
    image: "EuropaBEvo.jpg",
    sale: 0,
    slug: "produktum 25",
    category: 2,
    subcategory: "Tecno-Gaz autokláv",
    desc: "Az Europa B Evo egy utolsó generációs autokláv, amelyet a Tecno-Gaz kutatási iroda és más fontos fejlesztési struktúrák együttműködésével fejlesztettek ki.",
    desc2: "Jellemzők:",
    desc3: " AISI 304 rozsdamentes acélból készült kezelt kör alakú kamra. Maximális terhelés ciklusonként 5 kg. 3 biztonsági berendezés az ajtón: elektromágneses, elektromos, dinamikus.",
    desc4:
      "A vezérlőkártya be van helyezve a táblába. Grafikus kijelző. Automatikus légköri beállítási rendszer. Szivattyúval működtetett vízfeltöltő rendszer automatikus leállításával, amikor a tartály megtelt. Ajtótömítés a maximális időtartam biztosítása érdekében.",
    desc5: "Gyújtólap előszerelésekkel és automatikus víz feltöltéssel és ürítéssel. A berendezésbe beépített erkélyes szellőztető rendszer. Védett tálcatartó.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Europa B Pro",
    image: "EUROPABpro.jpg",
    sale: 0,
    slug: "produktum 26",
    category: 2,
    subcategory: "Tecno-Gaz autokláv",
    desc: "Az Europe B Pro új elektronikus  vezérléssel és a legújabb generációs mikroprocesszorral felszerelve működik.",
    desc2:
      "Sterilizálás ciklus 121 ° C-134 ° C-on egyaránt alkalmas anyagok csomagolt, és kicsomagolt állapotban. Az új hűtési rendszer kettős testű radiátor. Előtaggal ciklusok, amelyeket nem lehet manipulálni. Minden ciklus előre be van állítva, és garantálják a biztonságos sterilizálást a kezelt anyagoknál minden alkalommal.",
    desc3:
      "Nincs lehetőség a beavatkozásra és a manipulációra üzemeltetői ciklusok közben, így elkerülhető az esetleges emberi hiba. Minden ciklus biztosítja sterilizálás B típusú terhelést. Minden sterilizálási eljárás frakcionált vákuum rendszerrel. ",
    desc4:
      "Ergonomikus és egyszerű a használata. Bármilyen működési hibát hibaüzenettel jelez. Maximális üzembiztonságot nyújtja. Szintén elérhető a Model 24 literes változatban. Ez a modell különösen alkalmas a közepes és nagy létesítményekre, klinikáknak és központoknak.",
    desc5: "Europe B PRO24 rendszer fel van szerelve fordított ozmózis móddal is. ",
    desc6:
      "Maximálisam biztosítékok: 10 A Tápfeszültség jelentése: 50-60 Hz Maximális elektromos elnyelt: 1700 W Nettó tömege termék: 61 Kg Termék szélessége: 504mm. Termék magassága: 400mm. Termék mélysége: 640mm. Kezdő év a gyártási és forgalmazási: 2011",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Fóliahegesztő Millseal Plus",
    image: "millseal.jpg",
    sale: 0,
    slug: "produktum 27",
    category: 2,
    subcategory: "Autokláv kiegészítő",
    desc: "Manuális fóliahegesztő, a felhasználó választja meg a zacskó hosszát, vágja le azt és forrasztja le a zacskó végét. Folyamatos ciklus, állandó hőmérséklet. Fűtőrész hőmérséklete: 180°C",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Stillo asztali vízdesztilláló",
    image: "stillo.jpg",
    sale: 0,
    slug: "produktum 28",
    category: "Sterilizálás",
    subcategory: "Autokláv kiegészítő",
    desc: "Működése: Amikor a desztilláló be van kapcsolva, a víz hőmérséklete fokozatosan emelkedik és a vegyi anyagok, nehézfémek és egyéb szennyeződések a rozsdamentes acél tartály alján maradnak, így a szennyeződéseket és a kellemetlen szagokat eltávolítja. A gőz összegyűlik a hűtőcsatorna felületén, amely desztillált vízcseppeket képez és áthalad az aktivált faszén tasakon.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nyomtató",
    image: "nyomtato.jpeg",
    sale: 0,
    slug: "produktum 29",
    category: 2,
    subcategory: "Autokláv kiegészítő",
    desc: "Opcionális kiegészítő autoklávokhoz. Külső nyomtató, mellyel jelentéseket vagy vonalkód matricákat lehet készíteni.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Pure víztisztító",
    image: "pure.jpg",
    sale: 0,
    slug: "produktum 30",
    category: 2,
    subcategory: "Autokláv kiegészítő",
    desc: "Pure 100: Demineralizáló 2 ion-cserélő gyanta patronnal. Kiküszöböl minden szennyezett iont, amely jelen van a vízben, hogy magas minőségű demineralizált vizet biztosítson. mPure 500: ökológiai fordított ozmózisos demineralizáló rendszer, mely ellátja az autoklávot demineralizált vízzel; a víz egy ozmotikus membránon megy keresztül annak érdekében, hogy a sterilizálás ideális feltételeit biztosítsa",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Szoftver",
    image: "szoftver.jpg",
    sale: 0,
    slug: "produktum 31",
    category: 2,
    subcategory: "Autokláv kiegészítő",
    desc: "A MyTrace a Cefla nyomon követési szoftvere, mely a PLATINUM sorozat tartozéka (a többi modell esetében extraként kérhető). Ennek a programnak a használatával a sterilizált eszközök minden csoportja vonalkódon keresztül társítható a beteghez. Ez az alapvető szoftver befejezi a sterilizálási folyamatot és jogi védelmet nyújt a fogorvosoknak. A vonalkód olvasó extraként elérhető!",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Autokláv töltő",
    image: "tolto.jpg",
    sale: 0,
    slug: "produktum 32",
    category: 2,
    subcategory: "Autokláv kiegészítő",
    desc: "Egy külső pumpából áll, melyet a sterilizáló működtet, ezen keresztül áramlik a víz a palackból vagy más edényből",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tehtys H10 Plus",
    image: "TethysH10Plus.jpg",
    sale: 0,
    slug: "produktum 33",
    category: 2,
    subcategory: "Mosó-fertőtlenítő gép",
    desc: "A Tethys H10 Plus az új termikus fertőtlenítő, amely egyszerűvé és praktikussá teszi az eszközök tisztításának folyamatát. Ez az innovatív eszköz a sterilizálás előtt felmerülő számos – jellemzően kézi feladat – nagy részét elvégzi, ezáltal csökkentve a személyzet terhelését és a kockázatot.",
    desc2: "A Tethys H10 Plus által a szennyeződésmentesítés, a mosás, a termikus fertőtlenítés és a szárítás egy gyors, automatizált folyamattá válik.",
    desc3: "4 az 1-ben megoldás:",
    desc4: "1. Szennyeződésmentesítés –  az eszközön lévő baktériumok számának csökkentése",
    desc5: "2. Ultrahangos-tisztítás – szerves és szervetlen maradványok eltávolítása az eszközökről",
    desc6: "3. Termikus fertőtlenítés – az eszközökön élő mikroorganizmusok számának drasztikus csökkentése",
    desc7: "4. Forró levegős szárítás – a gőz és a maradék nedvesség eltávolítása a tartályból és a műszerekről. ",
    desc8:
      "Műszaki jellemzők:színes LCD kijelző egyértelmű ikonokkal,belső memória, USB csatlakozóval az adatok mentéséhez,6 program: 3 gyári és 3 személyre-szabható,külső méretek: 470*430*500 mm,súly: 37kg, max. töltési súly: 2kg,tartály effektív kapacitása: 6 liter, max. vízfelhasználás: 15 liter/ciklus.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "SW GoldSpeed Evo D1,D1-L",
    image: "evod1l.jpg",
    sale: 0,
    slug: "produktum 34",
    category: 3,
    subcategory: "Egyenesdarab",
    desc: " Egyenes kézidarab általános fogászati célra.",
    desc2: " 1:1 áttétel. Egyszeres spray. Autoklávozható.",
    desc3: "Fénnyel vagy fény nélkül.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M65",
    image: "M65.jpg",
    sale: 0,
    slug: "produktum 35",
    category: 3,
    subcategory: "Egyenesdarab",
    desc: "Áttétel 1:1 Direkt vezetés.",
    desc2: "HP fúrószárhoz (Ø2.35) / CA fúrószárhoz (Ø2.35) ",
    desc3: "Egyszeres Sprayhűtés.",
    desc4: "* Biztosított fúrószár rögzítő beszerelése szükséges.",
    desc5: "Rozsdamentes acél test.",
    desc6: "Max Fordulat 40,000 min-1.",
    desc7: "Üvegszálas optika",
    desc8: "Tiszta fejrendszer",
    desc9: "* Biztosított fúrószár rögzítő beszerelése szükséges.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Nano65LS",
    image: "Nano65LS.jpg",
    sale: 0,
    slug: "produktum 36",
    category: 3,
    subcategory: "Egyenesdarab",
    desc: "Rövidebb, könnyebb a tökéletesen kiegyensúlyozott “nano sorozat” műszerei úgy lettek tervezve, hogy megfelelően illeszkedjen a kezébe biztosítva az eljárások gördülékeny menetét. A finom és pontos mozgást igénylő implantációs műveletek során a “nano sorozat” hűen tükrözi a felhasználó szándékát.",
    desc2: "Áttétel – 1:1 Direkt vezetés.",
    desc3: "HP / CA fúrószárhoz (Ø2.35) *Biztosított fúrószár rögzítő beszerelése szükséges.",
    desc4: "Egyszeres Sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Fordulat – 40,000 min-1.",
    desc7: "Üvegszálas optika / Tiszta fejrendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X65L",
    image: "x65l.jpg",
    sale: 0,
    slug: "produktum 37",
    category: 3,
    subcategory: "Egyenesdarab",
    desc: "LED fényes.",
    desc2: "Áttétel 1:1 Direkt vezetés",
    desc3: "HP fúrószárhoz (Ø2.35) / CA fúrószárhoz (Ø2.35) * Biztosított fúrószár rögzítő beszerelése szükséges",
    desc4: "Egyszeres Sprayhűtés",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Fordulat 40,000 min-1",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X65",
    image: "x65l.jpg",
    sale: 0,
    slug: "produktum 38",
    category: 3,
    subcategory: "Egyenesdarab",
    desc: "LED fény nélküli.",
    desc2: "Áttétel 1:1 Direkt vezetés",
    desc3: "HP fúrószárhoz (Ø2.35) / CA fúrószárhoz (Ø2.35) * Biztosított fúrószár rögzítő beszerelése szükséges",
    desc4: "Egyszeres Sprayhűtés",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Fordulat 40,000 min-1",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Endo-Mate TC2 MPA",
    image: "endomatetc2.jpg",
    sale: 0,
    slug: "produktum 39",
    category: 3,
    subcategory: "Endodoncia",
    desc: "Endodonciás mikromotor",
    desc2: "Vezeték nélkül",
    desc3: "5 egyedi tárolható program",
    desc4: "Nagy méretű LCD kijelző",
    desc5: "90 perces gyors feltöltés",
    desc6: "Könnyű, ergonómikus kialakítású",
    desc7: " Pontos sebesség és nyomatékszabályozás",
    desc8: "Automatikus oda-vissza funkció",
    desc9: "Egyszerű kezelés és felhasználóbarát működés",
    desc10: "Apex lokátorhoz csatlakoztatható!",
    desc11: "A csomag tartalma:",
    desc12: "1 db Endo – Mate TC2-es motor",
    desc13: "1 db töltő",
    desc14: "1 db MP-F16R fej",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK IpexII apexlokátor",
    image: "ipexII.jpg",
    sale: 0,
    slug: "produktum 40",
    category: 3,
    subcategory: "Endodoncia",
    desc: "Fejlett Apex Locator.",
    desc2:
      "Számos klinikai vizsgálat és ellenőrző teszt alapján, az NSK létrehozta a  SmartLogic-t, a következő generációs Apex Locatiort, amely kompatibilis  különböző fogformákkal, és kiváló pontosságot biztosít a gyökércsúcs észlelésében.",
    desc3: "A biológiailag meghatározó gyökércsúcson – a Precíziós Zónában – az iPex egyértelmű és pontos képet adhat a fájl tippéről az LCD panelen.",
    desc4: "A csomag tartalma:",
    desc5: "Vezérlőegység / iPexII Kábel (1,8m) / iPexII Klip (3 db) / Kampó (3 db) / Akkumlátor (3 db) / iPexII Vizsgáló.",
    desc6: " Jellemzők:",
    desc7: "Tápegység – 1.5V AAA (LR03 vagy R03) akkumulátor.",
    desc8: "Folyamatos használat – Kb. 60 óra.",
    desc9: "Paraméterek – 60 x 60 x 86 (mm).",
    desc10: "Súly – 76g (akkumulátor nélkül).",
    amount: 1,
    price: 0,
  },

  {
    _id: uuidv4(),
    name: "NSK Presto Aqua II",
    image: "prestoaqua2.jpg",
    sale: 0,
    slug: "produktum 41",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "Kenőolajmentes levegő turbina kézidarab LED-del és vízhűtő spray-vel.",
    desc2: "A vizes palacsk könnyen és egyszerűen eltávolítható.",
    desc3: "Zökkenőmentesen beállítható a víz és a permetlevegő mennyisége.",
    desc4: "On-Off funkció a vízhűtő spray-nél.",
    desc5: "A beépített vízpalackkal függetlenül működtethető, vagy a hálózati vízellátáshoz csatlakoztatható.",
    desc6: " Komplett szett tartalma:",
    desc7: "PRESTO AQUA II egység/ PRESTO AQUA kézidarab / QD-J B3 kuplung / Tömlő.",
    desc8: "PRESTO AQUA II-höz/ Lábkapcsoló (AFC-40)/ Másodlagos tömlő a PRESTO-hoz.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Presto II",
    image: "presto2.jpg",
    sale: 0,
    slug: "produktum 42",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "Kenőolajmentes levegő turbina kézidarab LED-del.",
    desc2: "Előnye – Könnyű és kompakt.",
    desc3: "Komplett szett tartalma: PRESTO II egység / PRESTO kézidarab/ Kézidarabtartó/",
    desc4: "Tömlő PRESTO-hoz / Lábkapcsoló (AFC-40) / Másodlagos tömlő a PRESTO-hoz.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: " NSK Presto Aqua LUX",
    image: "prestoaqualux.jpg",
    sale: 0,
    slug: "produktum 43",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "Optimális teljesítmény és pontosság.",
    desc2: "Kenőolajmentes levegő turbina kézidarab LED-del és vízhűtő spray-vel.",
    desc3: "320,000 min-1.",
    desc4: "Nem igényel kenést.",
    desc5: "Forgásmentes csavarmentes kézidarab.",
    desc6: "Alacsony zaj és rezgés.",
    desc7: "Egyedi porálló mechanizmus.",
    desc8: "NSK LED fénye ugyanolyan mint a napfény.",
    desc9: "Előnyei:",
    desc10: "A vizes palack egyszerűen és könnyen eltávolítható.",
    desc11: "On-Off funkció a LED fény és a vízhűtő spray-nél.",
    desc12: "A beépített vízpalackkal függetlenül működtethető, vagy a hálózati vízellátáshoz csatlakoztatható.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Volvere i7",
    image: "volverei73.jpg",
    sale: 0,
    slug: "produktum 44",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "A VOLVERE i7 egy újabb előrelépés az NSK-nél hogy a fogorovosok hozzáférjenek a világszerte elismert fogászati laboratóriumi motorokhoz. A stílusos VOLVERE i7 kompakt, korszerű tervezést, minden lényeges tulajdonságot kínál kiváló ár-érték arányban.",
    desc2: "Könnyű és kompakt, hogy illeszkedjen a legkisebb helyre is.",
    desc3: "Mikroszámítógép-vezérlés.",
    desc4: "Tartalma:",
    desc5: "Standard Micromotor System: VOLVERE i7 Kezelőegység / Motor Kézidarab / Lábkapcsoló / Kézidarab tartó.",
    desc6: "E-Type Micromotor System: VOLVERE i7 Kezelőegység / E-Type Mikromotor / Lábkapcsoló / Kézidarab tartó.",
    desc7: "Kezelőegység:",
    desc8: "Tápegység – AC230V 50/60 Hz.",
    desc9: "Paraméterek – W69 x D185 x H167 mm.",
    desc10: "Súly – 900 g.",
    desc11: "Standard Típusú Motor Kézidarab:",
    desc12: "Fordulat – 1,000~35,000 min.",
    desc13: "Max. forgatónyomaték – 4.1 Ncm.",
    desc14: "Méret – L130 x Ø24.5 mm.",
    desc15: "Súly – 192 g (vezeték nélkül).",
    desc16: "Vezeték hossza v- 1.2 m.",
    desc17: "E-Type Motor Kézidarab:",
    desc18: "Fordulat – 1,000~35,000 min.",
    desc19: "Max. forgatónyomaték – 4.1 Ncm.",
    desc20: "Méret – L150 x Ø24.5 mm.",
    desc21: "Súly – 118 g (without cord).",
    desc22: "Vezeték hossza – 1.2 m.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ultimate XL Torque asztali verzió ()",
    image: "ultimatexlbenchtype.jpg",
    sale: 0,
    slug: "produktum 45",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "A világ vezető laboratóriumi mikromotorja most még jobb lett. Az Ultimate termékcsalád még gördülékenyebb és erősebb lett. A 180°-os vektorvezérlő rendszernek köszönhetően hatékonyabban és stresszmentesebben dolgozhat. A kézidarab formáját rendkívül gondosan tervezték meg, az ergonómia és a funkcionalitás mindig a tervezők szeme előtt tartásával. Az eredmény egy olyan kézidarab, amely jelentősen csökkenti a kéz kifáradását, és megkönnyíti a legkényesebb munkadarabok hosszú távú megmunkálását is. Tapasztalja meg az Ultimate XL új generációs teljesítményét.",
    desc2: "A kézidarab kialakítása az ergonómiai és funkcionális szempontok maximális figyelembevételével történt. ",
    desc3: "A kézidarab futása csendes és vibrációmentes, a forgatónyomaték a teljes fordulatszám tartományban erős és stabil.",
    desc4: "A 30%-kal megnövelt befogó erő anyagok széles körének megmunkálását teszi lehetővé. ",
    desc5: "Könnyű és kompakt karosszéria",
    desc6: "Hosszú távú tartósság",
    desc7: "Ergonomikus alakú kézidarab",
    desc8: "Minimális zaj és rezgés",
    desc9: "Speciális porálló mechanizmus",
    desc10: "Digitális sebesség kijelző",
    desc11: "Karbantartási mód beállítása",
    desc12: "Automatikus körutazás funkció",
    desc13: "Hibakód kijelző funkció",
    desc14: "Műszaki adatok: ",
    desc15: "Mikromotor az 1.000 - 50.000 fordulat / perc fordulatszám tartományra.",
    desc16: "Maximális forgatónyomaték: 8,7 Ncm (Torque változat)",
    desc17: "Kézidarab: 151 x Ø 29 mm, 219 g ",
    desc18: "Kábel: 2 méter",
    desc19: "Csomag tartalma: Vezérlőegység, mikromotor kézidarab, lábkapcsoló(kivéve térdindítósnál), kézidarab állvány.",
    desc20: "UMXL-GT Vezérlő egység specifikációi: Tápegység:  AC 120 V vagy230 V 50/60 Hz Súly: 2.8 kg",
    desc21: "Méretek: szélesség 167 x mélység 247 x magasság 77 mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ultimate XL Torque asztali álló verzió",
    image: "ultimatexltorony.jpg",
    sale: 0,
    slug: "produktum 46",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "A világ vezető laboratóriumi mikromotorja most még jobb lett. Az Ultimate termékcsalád még gördülékenyebb és erősebb lett. A 180°-os vektorvezérlő rendszernek köszönhetően hatékonyabban és stresszmentesebben dolgozhat. A kézidarab formáját rendkívül gondosan tervezték meg, az ergonómia és a funkcionalitás mindig a tervezők szeme előtt tartásával. Az eredmény egy olyan kézidarab, amely jelentősen csökkenti a kéz kifáradását, és megkönnyíti a legkényesebb munkadarabok hosszú távú megmunkálását is. Tapasztalja meg az Ultimate XL új generációs teljesítményét.",
    desc2: "A kézidarab kialakítása az ergonómiai és funkcionális szempontok maximális figyelembevételével történt. ",
    desc3: "A kézidarab futása csendes és vibrációmentes, a forgatónyomaték a teljes fordulatszám tartományban erős és stabil.",
    desc4: "A 30%-kal megnövelt befogó erő anyagok széles körének megmunkálását teszi lehetővé. ",
    desc5: "Könnyű és kompakt karosszéria",
    desc6: "Hosszú távú tartósság",
    desc7: "Ergonomikus alakú kézidarab",
    desc8: "Minimális zaj és rezgés",
    desc9: "Speciális porálló mechanizmus",
    desc10: "Digitális sebesség kijelző",
    desc11: "Karbantartási mód beállítása",
    desc12: "Automatikus körutazás funkció",
    desc13: "Hibakód kijelző funkció",
    desc14: "Műszaki adatok: ",
    desc15: "Mikromotor az 1.000 - 50.000 fordulat / perc fordulatszám tartományra.",
    desc16: "Maximális forgatónyomaték: 8,7 Ncm (Torque változat)",
    desc17: "Kézidarab: 151 x Ø 29 mm, 219 g ",
    desc18: "Kábel: 2 méter",
    desc19: "Csomag tartalma: Vezérlőegység, mikromotor kézidarab, lábkapcsoló(kivéve térdindítósnál), kézidarab állvány.",
    desc20: "UMXL-DT Vezérlő egység specifikációi:Tápegység:  AC 120 V vagy230 V 50/60 Hz Súly: 3.3 kg ",
    desc21: "Méretek: szélesség 81 x mélység 270 x magasság 230 mm ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ultimate XL Torque térdinditós verzió ",
    image: "ultimatexlterd.jpg",
    sale: 0,
    slug: "produktum 47",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "A világ vezető laboratóriumi mikromotorja most még jobb lett. Az Ultimate termékcsalád még gördülékenyebb és erősebb lett. A 180°-os vektorvezérlő rendszernek köszönhetően hatékonyabban és stresszmentesebben dolgozhat. A kézidarab formáját rendkívül gondosan tervezték meg, az ergonómia és a funkcionalitás mindig a tervezők szeme előtt tartásával. Az eredmény egy olyan kézidarab, amely jelentősen csökkenti a kéz kifáradását, és megkönnyíti a legkényesebb munkadarabok hosszú távú megmunkálását is. Tapasztalja meg az Ultimate XL új generációs teljesítményét.",
    desc2: "A kézidarab kialakítása az ergonómiai és funkcionális szempontok maximális figyelembevételével történt. ",
    desc3: "A kézidarab futása csendes és vibrációmentes, a forgatónyomaték a teljes fordulatszám tartományban erős és stabil.",
    desc4: "A 30%-kal megnövelt befogó erő anyagok széles körének megmunkálását teszi lehetővé. ",
    desc5: "Könnyű és kompakt karosszéria",
    desc6: "Hosszú távú tartósság",
    desc7: "Ergonomikus alakú kézidarab",
    desc8: "Minimális zaj és rezgés",
    desc9: "Speciális porálló mechanizmus",
    desc10: "Digitális sebesség kijelző",
    desc11: "Karbantartási mód beállítása",
    desc12: "Automatikus körutazás funkció",
    desc13: "Hibakód kijelző funkció",
    desc14: "Műszaki adatok: ",
    desc15: "Mikromotor az 1.000 - 50.000 fordulat / perc fordulatszám tartományra.",
    desc16: "Maximális forgatónyomaték: 8,7 Ncm (Torque változat)",
    desc17: "Kézidarab: 151 x Ø 29 mm, 219 g ",
    desc18: "Kábel: 2 méter",
    desc19: "Csomag tartalma: Vezérlőegység, mikromotor kézidarab, lábkapcsoló(kivéve térdindítósnál), kézidarab állvány.",
    desc20: "UMXL-KT Vezérlő egység specifikációi:Tápegység:  AC 120 V vagy230 V 50/60 Hz Súly: 3.2 kg",
    desc21: "Méretek: szélesség 90 x mélység 270 x magasság 230 mm ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ultimate XL Torque padló verzió",
    image: "ultimatexlpadlo.jpg",
    sale: 0,
    slug: "produktum 48",
    category: 3,
    subcategory: "Fogtechnika-Labor",
    desc: "A világ vezető laboratóriumi mikromotorja most még jobb lett. Az Ultimate termékcsalád még gördülékenyebb és erősebb lett. A 180°-os vektorvezérlő rendszernek köszönhetően hatékonyabban és stresszmentesebben dolgozhat. A kézidarab formáját rendkívül gondosan tervezték meg, az ergonómia és a funkcionalitás mindig a tervezők szeme előtt tartásával. Az eredmény egy olyan kézidarab, amely jelentősen csökkenti a kéz kifáradását, és megkönnyíti a legkényesebb munkadarabok hosszú távú megmunkálását is. Tapasztalja meg az Ultimate XL új generációs teljesítményét.",
    desc2: "A kézidarab kialakítása az ergonómiai és funkcionális szempontok maximális figyelembevételével történt. ",
    desc3: "A kézidarab futása csendes és vibrációmentes, a forgatónyomaték a teljes fordulatszám tartományban erős és stabil.",
    desc4: "A 30%-kal megnövelt befogó erő anyagok széles körének megmunkálását teszi lehetővé. ",
    desc5: "Könnyű és kompakt karosszéria",
    desc6: "Hosszú távú tartósság",
    desc7: "Ergonomikus alakú kézidarab",
    desc8: "Minimális zaj és rezgés",
    desc9: "Speciális porálló mechanizmus",
    desc10: "Digitális sebesség kijelző",
    desc11: "Karbantartási mód beállítása",
    desc12: "Automatikus körutazás funkció",
    desc13: "Hibakód kijelző funkció",
    desc14: "Műszaki adatok: ",
    desc15: "Mikromotor az 1.000 - 50.000 fordulat / perc fordulatszám tartományra.",
    desc16: "Maximális forgatónyomaték: 8,7 Ncm (Torque változat)",
    desc17: "Kézidarab: 151 x Ø 29 mm, 219 g ",
    desc18: "Kábel: 2 méter",
    desc19: "Csomag tartalma: Vezérlőegység, mikromotor kézidarab, lábkapcsoló(kivéve térdindítósnál), kézidarab állvány.",
    desc20: "UMXL-FT Vezérlő egység specifikációi: Tápegység:  AC 120 V vagy230 V 50/60 Hz Súly: 3.8  kg",
    desc21: "Méretek: szélesség 230 x mélység 292 x magasság 113mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed Evo E16",
    image: "e16.jpg",
    sale: 0,
    slug: "produktum 49",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Endodonciai használatra.",
    desc2: "16:1 áttétel.",
    desc3: "Miniatűr fej a kezelt terület eléréséhez.",
    desc4: "Autoklávozható.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed Evo E4",
    image: "e4.jpg",
    sale: 0,
    slug: "produktum 50",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Endodonciai használatra.",
    desc2: "4:1 áttétel.",
    desc3: "Kompatibilis a Stern Weber integrált Apex lokátorával és az i-XS4 mikromotor reciprok mozgásirányával.",
    desc4: "Autoklávozható.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed Evo M5-L",
    image: "m5l.jpg",
    sale: 0,
    slug: "produktum 51",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Gyorsító könyökdarab.",
    desc2: "1:5 áttétel.",
    desc3: "Négyszeres spray.",
    desc4: "Autoklávozható fej átmérője 10mm.",
    desc5: "Fényes.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed Evo S1 , S1-L",
    image: "s1l.jpg",
    sale: 0,
    slug: "produktum 52",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Konzervatív és általános fogászathoz.",
    desc2: "1:1 áttétel.",
    desc3: "Egyszeres spray.",
    desc4: "Autoklávozható fej átmérője 9,5mm.",
    desc5: "S1-L Fénnyel vagy S1 fény nélkül.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M15 ",
    image: "M15.jpg",
    sale: 0,
    slug: "produktum 53",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M15 Nem fényes verzió",
    desc2: "Áttétel – 4:1 csökkenő.",
    desc3: "Max. fordulat – 10,000 min-1.",
    desc4: "Fejméret –  átmérő 9.6 × H 13.4 mm.",
    desc5: "Rozsdamentes acéltest",
    desc6: "Egyszeres sprayhűtés.",
    desc7: "Tiszta fejrendszer/Nyomógombos befogópofa.  ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M15L ",
    image: "M15.jpg",
    sale: 0,
    slug: "produktum 54",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M15L Fényes verzió",
    desc2: "Áttétel – 4:1 csökkenő.",
    desc3: "Max. fordulat – 10,000 min-1.",
    desc4: "Fejméret –  átmérő 9.6 × H 13.4 mm.",
    desc5: "Rozsdamentes acéltest",
    desc6: "Egyszeres sprayhűtés.",
    desc8: "Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa.  ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M25 ",
    image: "M25.jpg",
    sale: 0,
    slug: "produktum 55",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M25 Nem fényes verzió",
    desc2: "Áttétel – 1:1 direkt vezetés.",
    desc3: "Max. fordulat – 40,000 min-1.",
    desc4: "Fejméret – átmérő 9.6 × H 13.4 mm.",
    desc5: "CA fúrószárhoz (Ø2.35).",
    desc6: "Rozsdamentes acéltest.",
    desc7: "Egyszeres sprayhűtés.",
    desc8: "Tiszta fejrendszer/Nyomógombos befogópofa",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M25L ",
    image: "M25.jpg",
    sale: 0,
    slug: "produktum 56",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M25L Fényes verzió",
    desc2: "Áttétel – 1:1 direkt vezetés.",
    desc3: "Max. fordulat – 40,000 min-1.",
    desc4: "Fejméret – átmérő 9.6 × H 13.4 mm.",
    desc5: "CA fúrószárhoz (Ø2.35).",
    desc6: "Rozsdamentes acéltest.",
    desc7: "Egyszeres sprayhűtés.",
    desc8: "Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M95 ",
    image: "M95.jpg",
    sale: 0,
    slug: "produktum 57",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M95 Nem fényes verzió",
    desc2: "Áttétel – 1:5 növekvő.",
    desc3: "Max. fordulat – 200,000 min-1.",
    desc4: "Fejméret – átmérő 9.9 × H 14.5 mm.",
    desc5: "FG fúrószárhoz (Ø1.6).",
    desc6: "Rozsdamentes acéltest.",
    desc7: "Négyszeres sprayhűtés.",
    desc8: "Tiszta fejrendszer/Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M95L ",
    image: "M95.jpg",
    sale: 0,
    slug: "produktum 58",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "M95L Fényes verzió",
    desc2: "Áttétel – 1:5 növekvő.",
    desc3: "Max. fordulat – 200,000 min-1.",
    desc4: "Fejméret – átmérő 9.9 × H 14.5 mm.",
    desc5: "FG fúrószárhoz (Ø1.6).",
    desc6: "Rozsdamentes acéltest.",
    desc7: "Négyszeres sprayhűtés.",
    desc8: "Üvegszálas optikaTiszta fejrendszer/Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK nano15LS ",
    image: "nano15LS.jpg",
    sale: 0,
    slug: "produktum 59",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Áttétel – 4:1 csökkenő.",
    desc2: "CA fúrószárhoz (átmérő 2.35).",
    desc3: "Egyszeres sprayhűtés.",
    desc4: "Titánium burkolat DURACOAT bevonattal.",
    desc5: "Max. fordulat – 10,000 min-1.",
    desc6: "Anti hő rendszer/Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    desc7: "",
    desc8: "",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK nano25LS ",
    image: "nano25LS.jpg",
    sale: 0,
    slug: "produktum 60",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Áttétel – 1:1 direkt vezetés.",
    desc2: "CA fúrószárhoz ( átmérő 2.35).",
    desc3: "Egyszeres sprayhűtés.",
    desc4: "Titánium burkolat DURACOAT bevonattal.",
    desc5: "Max. fordulat – 40,000 min-1.",
    desc6: "Anti hő rendszer/Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK nano95LS ",
    image: "nano95LS.jpg",
    sale: 0,
    slug: "produktum 61",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Áttétel – 1:5 növekvő.",
    desc2: "FG fúrószárhoz (átmérő 1.6).",
    desc3: "Négyszeres sprayhűtés.",
    desc4: "Titánium burkolat DURACOAT bevonattal.",
    desc5: "Max. fordulat – 200,000 min-1.",
    desc6: "DLC Bevonat/Anti hő rendszer/Üvegszálas optika/Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter/Kivételes tartósság.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X10",
    image: "X10.jpg",
    sale: 0,
    slug: "produktum 62",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X10 Nem fényes verzió.",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "CA fúrószár ( átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 2,500 min-1.",
    desc7: "Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X12L ",
    image: "X12.jpg",
    sale: 0,
    slug: "produktum 63",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X12L Fényes verzió",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "CA fúrószár ( átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 2,500 min-1.",
    desc7: "Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X12 ",
    image: "X12.jpg",
    sale: 0,
    slug: "produktum 63",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X12 Nem ényes verzió",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "CA fúrószár ( átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 2,500 min-1.",
    desc7: "Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X15",
    image: "X15.jpg",
    sale: 0,
    slug: "produktum 65",
    category: 3,
    desc: "X15 Nem fényes verzió",
    desc2: "Áttétel – 10:1 csökkenő.",
    desc3: "CA fúrószár ( átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 4,000 min-1.",
    desc7: "Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X15L ",
    image: "X15.jpg",
    sale: 0,
    slug: "produktum 66",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X15L Fényes verzió",
    desc2: "Áttétel – 10:1 csökkenő.",
    desc3: "CA fúrószár ( átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 4,000 min-1.",
    desc7: "Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X20",
    image: "X20.jpg",
    sale: 0,
    slug: "produktum 67",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X20 Nem fényes verzió",
    desc2: "Áttétel – 1:1 direkt vezetés.",
    desc3: "FG fúrószárhoz (átmérő 1.6).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 40,000 min-1.",
    desc7: "Tiszta fejrendszer / Nyomógombos befogópofa / Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X25L ",
    image: "X25.jpg",
    sale: 0,
    slug: "produktum 68",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X25L Fényes verzió",
    desc2: "Áttétel 1:1 direkt vezetés.",
    desc3: "CA fúrószárhoz (átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 40,000 min-1.",
    desc7: "Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa /Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X25 ",
    image: "X25.jpg",
    sale: 0,
    slug: "produktum 69",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X25 Nem fényes verzió",
    desc2: "Áttétel 1:1 direkt vezetés.",
    desc3: "CA fúrószárhoz (átmérő 2.35).",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 40,000 min-1.",
    desc7: "Tiszta fejrendszer/Nyomógombos befogópofa /Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X35 ",
    image: "X35.jpg",
    sale: 0,
    slug: "produktum 70",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X35 Nem fényes verzió",
    desc2: "Áttétel – 10:1 csökkenő.",
    desc3: "Kézi-, és Motorfájlhoz / 60 fokos Csavar.",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 4,000 min-1.",
    desc7: "Nyomógombos befogópofa / Mikrofilter.",
    desc8: "",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X35L ",
    image: "X35.jpg",
    sale: 0,
    slug: "produktum 71",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X35L Fényes verzió",
    desc2: "Áttétel – 10:1 csökkenő.",
    desc3: "Kézi-, és Motorfájlhoz / 60 fokos Csavar.",
    desc4: "Egyszeres sprayhűtés",
    desc5: "Titánium burkolat DURACOAT bevonattal",
    desc6: "Max. fordulat – 4,000 min-1.",
    desc7: "Üvegszálas optika / Nyomógombos befogópofa / Mikrofilter.",
    desc8: "",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X55",
    image: "X55.jpg",
    sale: 0,
    slug: "produktum 72 ",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X55 nem fényes verzió",
    desc2: "Áttétel – 4:1 csökkenő.",
    desc3: "Profin / EVA Tippekhez.",
    desc4: "Függőleges mozgás (Lökethossz -1.4mm).",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 10,000 min-1.",
    desc8: "Mikrofilter.",
    desc9: "Nem tartalmazza a lamineer tippeket.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X55L",
    image: "X55.jpg",
    sale: 0,
    slug: "produktum 73 ",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X55L Fényes verzió",
    desc2: "Áttétel – 4:1 csökkenő.",
    desc3: "Profin / EVA Tippekhez.",
    desc4: "Függőleges mozgás (Lökethossz -1.4mm).",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 10,000 min-1.",
    desc8: "Üvegszálas optika / Mikrofilter.",
    desc9: "Nem tartalmazza a lamineer tippeket.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X70L",
    image: "X85.jpg",
    sale: 0,
    slug: "produktum 74 ",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X70L Fényes verzió",
    desc2: "Áttétel – 64:1 csökkenő.",
    desc3: "Motorfájlhoz / 360 fokos forgás.",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 600 min-1.",
    desc8: "Üvegszálas optika/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X70",
    image: "X85.jpg",
    sale: 0,
    slug: "produktum 75 ",
    category: "Kézidarabok",
    subcategory: "Könyökdarabok",
    desc: "X70 Nem fényes verzió",
    desc2: "Áttétel – 64:1 csökkenő.",
    desc3: "Motorfájlhoz / 360 fokos forgás.",
    desc4: "Egyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 600 min-1.",
    desc8: "Üvegszálas optika/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X57",
    image: "X57.jpg",
    sale: 0,
    slug: "produktum 76 ",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X57 Nem fényes verzió",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "Screw-in Cups és szénkefe / 360 fokos forgás.",
    desc4: "Titánium burkolat DURACOAT bevonattal.",
    desc5: "Max. fordulat – 2,500 min-1.",
    desc7: "Max. fordulat – 600 min-1.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X57L",
    image: "X57.jpg",
    sale: 0,
    slug: "produktum 77 ",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X57L Fényes verzió",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "Screw-in Cups és szénkefe / 360 fokos forgás.",
    desc4: "Titánium burkolat DURACOAT bevonattal.",
    desc5: "Max. fordulat – 2,500 min-1.",
    desc7: "Max. fordulat – 600 min-1.",
    desc8: "Üvegszálas optika",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X85 ",
    image: "X85.jpg",
    sale: 0,
    slug: "produktum 78",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X85 Nem fényes verzió",
    desc2: "Miniatűr fej.",
    desc3: "Áttétel – 1:5 növekvő.",
    desc4: "FG fúrószárhoz (átmérő 1.6).",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 200,000 min-1.",
    desc8: "*Csak rövid fúrószár.",
    desc9: "Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X85L ",
    image: "X85.jpg",
    sale: 0,
    slug: "produktum 79",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X85L Fényes verzió",
    desc2: "Miniatűr fej.",
    desc3: "Áttétel – 1:5 növekvő.",
    desc4: "FG fúrószárhoz (átmérő 1.6).",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURACOAT bevonattal.",
    desc7: "Max. fordulat – 200,000 min-1.",
    desc8: "*Csak rövid fúrószár.",
    desc9: "Üvegszálas optika/Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X95",
    image: "X95.jpg",
    sale: 0,
    slug: "produktum 80",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X95 Nem fényes verzió",
    desc2: "Áttétel – 1:5 növekvő.",
    desc3: "FG fúrószárhoz ( átmérő 1.6).",
    desc4: "Négyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 200,000 min-1.",
    desc7: "Üvegszálas optika/Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X95L ",
    image: "X95.jpg",
    sale: 0,
    slug: "produktum 81",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "X95L fényes verzió",
    desc2: "Áttétel – 1:5 növekvő.",
    desc3: "FG fúrószárhoz ( átmérő 1.6).",
    desc4: "Négyszeres sprayhűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max. fordulat – 200,000 min-1.",
    desc7: "Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z10L ",
    image: "Z10.jpg",
    sale: 0,
    slug: "produktum 82",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Z10L Fényes verzió",
    desc2: "Áttétel – 16:1 csökkenő.",
    desc3: "CA fúrószárhoz (átmérő 2.35).",
    desc4: "Fejméret – H13.4 x  átmérő 8.9.",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURAGRIP bevonattal.",
    desc7: "Max. fordulat 2,500 min-1.",
    desc8: "Anti hő rendszer/Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z15L ",
    image: "Z15.jpg",
    sale: 0,
    slug: "produktum 83",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Z15L Fényes verzió",
    desc2: "Áttétel – 4:1 csökkenő.",
    desc3: "CA fúrószárhoz ( átmérő 2.35).",
    desc4: "Fejméret – H13.4 x átmérő 8.9.",
    desc5: "Egyszeres sprayhűtés.",
    desc6: "Titánium burkolat DURAGRIP bevonattal.",
    desc7: "Max. fordulat-  10,000 min-1.",
    desc8: "Anti hőrendszer/Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z25L ",
    image: "Z25.jpg",
    sale: 0,
    slug: "produktum 84",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Z25L Fényes verzió",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "CA fúrószárhoz ( átmérő 2.35).",
    desc4: "Fejméret – H13.7 x átmérő 8.9.",
    desc5: "Egyszeres Sprayhűtés.",
    desc6: "Titánium burkolat DURAGRIP bevonattal.",
    desc7: "Max. fordulat – 40,000 min-1",
    desc8: "Anti hőrendszer/Üvegszálas optika/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z45L ",
    image: "Z45.jpg",
    sale: 0,
    slug: "produktum 85",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Z45L Fényes verzió",
    desc2: "45 fokosos fej.",
    desc3: "Áttétel – 1:4.2 növekvő.",
    desc4: "FG fúrószárhoz (átmérő 1.6).",
    desc5: "Fejméret – átmérő 9.6 x H14.5 mm.",
    desc6: "Négyszeres sprayhűtés (vízsugár/ köd).",
    desc7: "Titánium burkolat DURAGRIP bevonattal.",
    desc8: "Max. fordulat – 168,000 min-1.",
    desc9: "DLC Bevonat/Anti hő rendszer/Üvegszálas optika/Kerámia csapágyak/Tiszta fejrendszer/Nyomógombos befogópofa/Mikrofilter.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z84L ",
    image: "Z84.jpg",
    sale: 0,
    slug: "produktum 86",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Z84L Fényes verzió",
    desc2: "Miniatűr Fej.",
    desc3: "Áttétel – 1:4.5.",
    desc4: "FG fúrószárhoz ( átmérő 1.6).",
    desc5: "Fejméret – H12.6 x átmérő 8.9.",
    desc6: "Négyszeres Sprayhűtés.",
    desc7: "Titánium burkolat DURAGRIP bevonattal.",
    desc8: "Max Fordulat – 180,000 min-1.",
    desc9: "*Csak rövid fúrószár.",
    desc10: "DLC Bevonat/Anti hőrendszer/Üvegszálas optika/Kerámia csapágyak/Tiszta fejrendszer",
    desc11: " /Nyomógombos befogópofa/Mikrofilter/Kivételes tartósság.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK PTL-CL-LED kuplung ",
    image: "PTLCLLED.jpg",
    sale: 0,
    slug: "produktum 87",
    category: 3,
    subcategory: "Kuplungok",
    desc: "NSK csatlakozással",
    desc2: "Rozsdamentes acél test.",
    desc3: "Titánbevonat.",
    desc4: "Visszahúzódó szelep.",
    desc5: "Kuplungba beépített LED",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK PTL-CL-LED III kuplung ",
    image: "PTLCLLEDIII.jpg",
    sale: 0,
    slug: "produktum 88",
    category: 3,
    subcategory: "Kuplungok",
    desc: "NSK csatlakozással",
    desc2: "Rozsdamentes acél test.",
    desc3: "Titánbevonat.",
    desc4: "Visszahúzódó szelep.",
    desc5: "Vízmennyiség-szabályozóval.",
    desc6: "Kuplungba beépített LED",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK KCL-LED kuplung ",
    image: "KCLLED.jpg",
    sale: 0,
    slug: "produktum 89",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "KaVo MULTIflex LUX csatlakozással",
    desc2: "Rozsdamentes acél test",
    desc3: "Titánbevonat",
    desc4: " Visszahúzódó szelep",
    desc5: "Kuplungba beépített LED",
    desc6: "Vízmennyiség-szabályozóval",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SCL-LED kuplung",
    image: "SCLLED.jpg",
    sale: 0,
    slug: "produktum 90",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Sirona csatlakozású Gyors Kuplung",
    desc2: "Rozsdamentes acél test",
    desc3: "Titánbevonat",
    desc4: "Visszahúzódó szelep",
    desc5: "Kuplungba beépített LED",
    desc6: "Vízmennyiség-szabályozóval",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK NBX LED mikromotor",
    image: "nbxled.jpg",
    sale: 0,
    slug: "produktum 91",
    category: 3,
    subcategory: "Mikromotorok",
    desc: "Max. 40,000 min-1. Ultrakompakt és könnyű külső titán test/Minimális hőtermelés/Csak mikromotor(a vezeték nincs benne).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK NLXnano mikromotor",
    image: "nlxnano.jpg",
    sale: 0,
    slug: "produktum 92",
    category: 3,
    subcategory: "Mikromotorok",
    desc: "Csak NLX nano mikromotor (vezeték nincs benne) / Szolid Titán test / Autoklávozható akár 135°C.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK  NLX Plus ",
    image: "nlxplus.jpg",
    sale: 0,
    slug: "produktum 93",
    category: 3,
    subcategory: "Mikromotorok",
    desc: "Csak NLX plus mikromotor (vezeték nincs benne)/Szolid Titán test/Autoklávozható akár 135°C.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Prophy-Mate neo homokfúvó PMNG-PTL-P ",
    image: "prophymateneo.jpg",
    sale: 0,
    slug: "produktum 94",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "NSK csatlakozással",
    desc2: "Könnyűsúlyú",
    desc3: "360°-ban elforgatható nagy nyomás alatt is",
    desc4: "Fogkő és elszíneződés eltávolítására",
    desc5: "60°és 80°-os fejjel",
    desc6: "Gyors csatlakozással",
    desc7: "135°C-ig autoklávozható",
    desc8: "Egyszerű tisztítás",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Prophy-Mate neo homokfúvó PMNG-KV-P  ",
    image: "prophymateneo.jpg",
    sale: 0,
    slug: "produktum 95",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Kavo csatlakozással",
    desc2: "Könnyűsúlyú",
    desc3: "360°-ban elforgatható nagy nyomás alatt is",
    desc4: "Fogkő és elszíneződés eltávolítására",
    desc5: "60°és 80°-os fejjel",
    desc6: "Gyors csatlakozással",
    desc7: "135°C-ig autoklávozható",
    desc8: "Egyszerű tisztítás",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Prophy-Mate neo homokfúvó PMNG-SR-P  ",
    image: "prophymateneo.jpg",
    sale: 0,
    slug: "produktum 96",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Sirona csatlakozással",
    desc2: "Könnyűsúlyú",
    desc3: "360°-ban elforgatható nagy nyomás alatt is",
    desc4: "Fogkő és elszíneződés eltávolítására",
    desc5: "60°és 80°-os fejjel",
    desc6: "Gyors csatlakozással",
    desc7: "135°C-ig autoklávozható",
    desc8: "Egyszerű tisztítás",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Prophy-Mate neo homokfúvó PMNG-WH-P  ",
    image: "prophymateneo.jpg",
    sale: 0,
    slug: "produktum 97",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "W&H csatlakozással",
    desc2: "Könnyűsúlyú",
    desc3: "360°-ban elforgatható nagy nyomás alatt is",
    desc4: "Fogkő és elszíneződés eltávolítására",
    desc5: "60°és 80°-os fejjel",
    desc6: "Gyors csatlakozással",
    desc7: "135°C-ig autoklávozható",
    desc8: "Egyszerű tisztítás",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Prophy-Mate neo homokfúvó PMNG-BA-P  ",
    image: "prophymateneo.jpg",
    sale: 0,
    slug: "produktum 98",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "BienAir csatlakozással",
    desc2: "Könnyűsúlyú",
    desc3: "360°-ban elforgatható nagy nyomás alatt is",
    desc4: "Fogkő és elszíneződés eltávolítására",
    desc5: "60°és 80°-os fejjel",
    desc6: "Gyors csatlakozással",
    desc7: "135°C-ig autoklávozható",
    desc8: "Egyszerű tisztítás",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Perio-Mate homokfúvó PER-PTL-P  ",
    image: "periomate.jpg",
    sale: 0,
    slug: "produktum 99",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "NSK csatlakozással",
    desc2: "A subgingivális felhasználásra készült intraorális homokfúvó új távlatokat nyit a parodontitis és a periimplantitis kezelésében, valamint a biofilm kíméletes eltávolításában.",
    desc3: "A kézidarab két ujjal könnyen forgatható, hozzáférhetővé téve fúvóka számára a nehezen elérhető tasakokat is.",
    desc4: "A portartályon lévő gyűrű segítségével a kifújt por mennyisége egyszerűen, a kezelés megszakítása nélkül szabályozható.",
    desc5: "Csomag tartalma:",
    desc6: "1 db Perio-Mate készülék (kézidarab + portartály)",
    desc7: "40 steril egyszerhasználatos fúvóka",
    desc8: "1x160 g Perio-Mate por",
    desc9: "Egyéb kiegészítők beleértve 1 tisztító készletet",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Perio-Mate homokfúvó PER-KV-P  ",
    image: "periomate.jpg",
    sale: 0,
    slug: "produktum 100",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Kavo csatlakozással",
    desc2: "A subgingivális felhasználásra készült intraorális homokfúvó új távlatokat nyit a parodontitis és a periimplantitis kezelésében, valamint a biofilm kíméletes eltávolításában.",
    desc3: "A kézidarab két ujjal könnyen forgatható, hozzáférhetővé téve fúvóka számára a nehezen elérhető tasakokat is.",
    desc4: "A portartályon lévő gyűrű segítségével a kifújt por mennyisége egyszerűen, a kezelés megszakítása nélkül szabályozható.",
    desc5: "Csomag tartalma:",
    desc6: "1 db Perio-Mate készülék (kézidarab + portartály)",
    desc7: "40 steril egyszerhasználatos fúvóka",
    desc8: "1x160 g Perio-Mate por",
    desc9: "Egyéb kiegészítők beleértve 1 tisztító készletet",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Perio-Mate homokfúvó PER-SR-P  ",
    image: "periomate.jpg",
    sale: 0,
    slug: "produktum 101",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Sirona csatlakozással",
    desc2: "A subgingivális felhasználásra készült intraorális homokfúvó új távlatokat nyit a parodontitis és a periimplantitis kezelésében, valamint a biofilm kíméletes eltávolításában.",
    desc3: "A kézidarab két ujjal könnyen forgatható, hozzáférhetővé téve fúvóka számára a nehezen elérhető tasakokat is.",
    desc4: "A portartályon lévő gyűrű segítségével a kifújt por mennyisége egyszerűen, a kezelés megszakítása nélkül szabályozható.",
    desc5: "Csomag tartalma:",
    desc6: "1 db Perio-Mate készülék (kézidarab + portartály)",
    desc7: "40 steril egyszerhasználatos fúvóka",
    desc8: "1x160 g Perio-Mate por",
    desc9: "Egyéb kiegészítők beleértve 1 tisztító készletet",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Perio-Mate homokfúvó PER-WH-P  ",
    image: "periomate.jpg",
    sale: 0,
    slug: "produktum 102",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "W&H csatlakozással",
    desc2: "A subgingivális felhasználásra készült intraorális homokfúvó új távlatokat nyit a parodontitis és a periimplantitis kezelésében, valamint a biofilm kíméletes eltávolításában.",
    desc3: "A kézidarab két ujjal könnyen forgatható, hozzáférhetővé téve fúvóka számára a nehezen elérhető tasakokat is.",
    desc4: "A portartályon lévő gyűrű segítségével a kifújt por mennyisége egyszerűen, a kezelés megszakítása nélkül szabályozható.",
    desc5: "Csomag tartalma:",
    desc6: "1 db Perio-Mate készülék (kézidarab + portartály)",
    desc7: "40 steril egyszerhasználatos fúvóka",
    desc8: "1x160 g Perio-Mate por",
    desc9: "Egyéb kiegészítők beleértve 1 tisztító készletet",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Perio-Mate homokfúvó PER-BA-P  ",
    image: "periomate.jpg",
    sale: 0,
    slug: "produktum 103",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "BienAir csatlakozással",
    desc2: "A subgingivális felhasználásra készült intraorális homokfúvó új távlatokat nyit a parodontitis és a periimplantitis kezelésében, valamint a biofilm kíméletes eltávolításában.",
    desc3: "A kézidarab két ujjal könnyen forgatható, hozzáférhetővé téve fúvóka számára a nehezen elérhető tasakokat is.",
    desc4: "A portartályon lévő gyűrű segítségével a kifújt por mennyisége egyszerűen, a kezelés megszakítása nélkül szabályozható.",
    desc5: "Csomag tartalma:",
    desc6: "1 db Perio-Mate készülék (kézidarab + portartály)",
    desc7: "40 steril egyszerhasználatos fúvóka",
    desc8: "1x160 g Perio-Mate por",
    desc9: "Egyéb kiegészítők beleértve 1 tisztító készletet",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Flash-Pearl por ",
    image: "FlashPearl.jpg",
    sale: 0,
    slug: "produktum 104",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Eredeti dobozában stílusosan csomagolva, praktikus mérőpohárral az Ön kényelme érdekében. Minden palack 300 g-ot tartalmaz.",
    desc2: "4 palack/csomag",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Varios Combi Pro ",
    image: "VariosCombiPro.jpg",
    sale: 0,
    slug: "produktum 105",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Még hatékonyabb és kényelmesebb szájhigiéniai kezelések a Varios Combi Pro segítségével",
    desc2: "Varios2 LUX Ultrahangos-depurátorral",
    desc3: "Prophy homokfúvó kézidarabbal (supragingivalis alkalmazás)",
    desc4: "Automata átmosató rendszerrel (kb. 45 másodperc)",
    desc5: "Egyszerű karbantartás, könnyen szétszerelhető alkatrészekkel",
    desc6: "Karcálló LCD kijelzővel",
    desc7: "Szett tartalma:",
    desc8: "-Prophy-kézidarab 60°-os fúvókával",
    desc9: "-Prophy-kézidarab tömlő ",
    desc10: "-Prophy-portartály",
    desc11: "-Flash Pearl prophylaxis-por (5*15g)",
    desc12: "-Biztosíték-szett",
    desc13: "-Varios2 LUX ultrahangos depurátor-kézidarab",
    desc14: "-Varios2 LUX kézidarab tömlő",
    desc15: "-3* ultrahangos-tip",
    desc16: "-sterilizációs doboz",
    desc17: "-Varios Combi Pro hűtőfolyadék-tartály (400ml)",
    desc18: "-Multifunkciós lábkapcsoló",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Varios 970 ",
    image: "Varios970.jpg",
    sale: 0,
    slug: "produktum 106",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Varios 970 ultrahangos depurátor készülék",
    desc2: "iPiezo motor a stabil teljesítmény érdekében",
    desc3: " Rendkívül vékony és könnyű kézidarab",
    desc4: "Széles dep.hegyválaszték a profilaxis, parodontológia, endodoncia és helyreállító kezelésekhez",
    desc5: "LED-es kivitelben",
    desc6: "vízrendszerre csatlakoztatható",
    desc7: "Jellemzők:",
    desc8: "Tápegység AC 120 V vagy 230 V 50/60 Hz",
    desc9: "Frekvencia 28-32 kHz",
    desc10: "Max. Kimenet 11 W (G mód) ",
    desc11: "Méretek Sz 160 x M 270 x Ma 190 mm (tartályt is beleértve)",
    desc12: "Súlya: 2,1 kg (a tartozékok kivételével) ",
    desc13: "Tartály kapacitás: 400 ml (x 2 palack)",
    desc14: "Csomag tartalma:",
    desc15: "-Vezérlőegység",
    desc16: "-VariosLux LED kézidarab  vezetékkel",
    desc17: "-Lábkapcsoló",
    desc18: "-3 depurátorhegy (G4, G6, G8)",
    desc19: "-2  db tartály",
    desc20: "-sterilizáló kazetta",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ti-Max S970 ",
    image: "TiMaxS970.jpg",
    sale: 0,
    slug: "produktum 107",
    category: 3,
    subcategory: "Prophylaxis",
    desc: "Ti-Max S970 nem fényes verzió",
    desc2: "Jellemzők:",
    desc3: "Titánium burkolat DURACOAT® bevonattal",
    desc4: "Oszcilláció frekvenciája 5,800~6,200 Hz",
    desc5: "*3 level Power Ring",
    desc6: "Tartalmaz három depurátorhegyet (S1, S2 & S3), Csavarkulcs (CR-20) and Tip Cover (L)",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Ti-Max S970L ",
    image: "TiMaxS970.jpg",
    sale: 0,
    slug: "produktum 108",
    category: 3,
    subcategory: "Könyökdarabok",
    desc: "Ti-Max S970L Fényes verzió",
    desc2: "Jellemzők:",
    desc3: "Titánium burkolat DURACOAT® bevonattal",
    desc4: "Oszcilláció frekvenciája 5,800~6,200 Hz",
    desc5: "*3 level Power Ring",
    desc6: "Tartalmaz három depurátorhegyet (S1, S2 & S3), Csavarkulcs (CR-20) and Tip Cover (L), Üvegszálas optika.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed R20-L",
    image: "r20l.jpg",
    sale: 0,
    slug: "produktum 109",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Sebészeti használatra.",
    desc2: "20:1 áttétel.",
    desc3: "Egyszeres spray.",
    desc4: "Autoklávozható, szétszedhető az alapos tisztításhoz.",
    desc5: "Belső Kirschner/Meyer hűtőrendszer és külső spray.",
    desc6: "LED generátoros.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Surgic Pro OPT Sebészeti berendezés",
    image: "Surgic-Pro-OPT.jpg",
    sale: 0,
    slug: "produktum 112",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "Erőteljes nyomaték (80Ncm-ig)",
    desc2: "Kicsi és könnyű mikromotor",
    desc3: "LED 32.000 LUX fényerővel",
    desc4: "Kiváló teljesítmény és megbízhatóság",
    desc5: "Tartalma",
    desc6: "Vezérlőegység",
    desc7: "1 db SGL70M LED Mikromotor ",
    desc8: "FC-78 lábkapcsoló ",
    desc9: "1 db X-SG20L sebészeti könyökdarab (20:1 áttétel) ",
    desc10: "hűtőcső (5 db.) + egyéb kiegészítők",
    desc11: "Jellemzők:",
    desc12: "Tápegység – AC120V or 230V 50/60 Hz.",
    desc13: "Max. Szivattyú kimenet – 75 mL/min.",
    desc14: "Programok – 8 Program / Implantációs rendszerek.",
    desc15: "Paraméterek – 265 x 220 x 100 (mm).",
    desc16: "Torque 5-80 Ncm.",
    desc17: "Motor fordulat – 200~40,000 min-1.",
    desc18: "Fényerő(LED Mikromotor) – 32,000 LUX felett.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Surgic Pro OPT+D Sebészeti berendezés",
    image: "Surgic-Pro-OPT-D_2.jpg",
    sale: 0,
    slug: "produktum 113",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "Erőteljes nyomaték (80Ncm-ig)",
    desc2: "Kicsi és könnyű mikromotor",
    desc3: "LED 32.000 LUX fényerővel",
    desc4: "Kiváló teljesítmény és megbízhatóság",
    desc5: "Tartalma",
    desc6: "Adattárolásra alkalmas vezérlőegység",
    desc7: "1 db SGL70M LED Mikromotor ",
    desc8: "FC-78 lábkapcsoló ",
    desc9: "1 db X-DSG20L sebészeti könyökdarab (20:1 áttétel) – szétszerelhető ",
    desc10: "hűtőcső (5 db.) + egyéb kiegészítők",
    desc11: "Jellemzők:",
    desc12: "Tápegység – AC120V or 230V 50/60 Hz.",
    desc13: "Max. Szivattyú kimenet – 75 mL/perc.",
    desc14: "Programok – 8 Program / Implantációs rendszerek.",
    desc15: "Paraméterek – W268 x D220 x H100 (mm).",
    desc16: "USB I/F 1 port.",
    desc17: "Beépített memória – 100 min.",
    desc18: "Torque 5-80 Ncm.",
    desc19: "Motor Fordulat – 200~40,000 min-1.",
    desc20: "Fényerő (LED Mikromotor) – 32,000 LUX felett.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Surgic Pro NON OPT Sebészeti berendezés",
    image: "Surgic-Pro-NON-OPT_2.jpg",
    sale: 0,
    slug: "produktum 114",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "Erőteljes nyomaték (80Ncm-ig)",
    desc2: "Kicsi és könnyű mikromotor",
    desc3: "LED 32.000 LUX fényerővel",
    desc4: "Kiváló teljesítmény és megbízhatóság",
    desc5: "Tartalma",
    desc6: "Vezérlőegység",
    desc7: "1 db SGL70M fény nélküli mikromotor ",
    desc8: "FC-78 lábkapcsoló ",
    desc9: "1 db SG20 sebészeti könyökdarab  nem fényes (20:1 áttétel)  ",
    desc10: "hűtőcső (5 db.) + egyéb kiegészítők",
    desc11: "Jellemzők:",
    desc12: "Tápegység – AC120V or 230V 50/60 Hz.",
    desc13: "Max. szivattyú kimenet – 75 mL/min.",
    desc14: "Programok – 8 Program / Implantációs rendszerek.",
    desc15: "Paraméterek – 265 x 220 x 100 (mm).",
    desc16: "Torque 5-80 Ncm.",
    desc17: "Motor fordulat – 200~40,000 min-1.",
    desc18: "Fényerő (LED Mikromotor) – 32,000 LUX felett.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK VarioSurg4 Sebészeti berendezés",
    image: "variosurg4.jpg",
    sale: 0,
    slug: "produktum 115",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "A VarioSurg ultrahangos kötéssebészeti rendszer a VarioSurg 4 bevezetésével fejlődött. Az  úttörő nagy teljesítményű ultrahangos technológiának és a különféle eljárásokhoz használható hegyek változatos kínálatának köszönhetően a VarioSurg 4 gyors és bonyolult csonteltávolítást és formázást tesz lehetővé.",
    desc2: "A technika minimálisan invazív, korlátozza a környező lágyrészek károsodását a hagyományos eljárásokhoz képest. Az olyan új gyakorlati előnyökkel, mint a vezeték nélküli lábvezérlés, a Surgic Pro2-vel való integráció és kompatibilitás, valamint a kifinomult, stílusos kialakítás, a VarioSurg 4 felülmúlja a klinikusok elvárásait, miközben stresszmentes környezetet teremt.",
    desc3: "Főbb jellemzők : Jelentős teljesítménynövelés: 3 mód (SURG, ENDO, PERIO) /Választható sorozatfelvétel mód (3 frekvencia) / 9 program (SURG x 5, ENDO x 2,PERIO x 2) / ",
    desc4: "Állítható LED fényerősség /Automatikus tisztítási mód /Visszacsatolás funkció / Automatikus hangolás funkció / Dinamikus kapcsolat funkció",
    desc5: "Alkalmazási területek: Szájsebészet,Csontműtét",
    desc6: "VarioSurg 4 komplett szett tartalma: VarioSurg4 Control Unit/LED kézidarab 2méteres vezetékkel/FC-86 lábkapcsoló/Sterilizáló kazetta",
    desc7: "Handpiece Stand/Szerelék (3 darab) és egyéb tartozékok / Alap H-S Kit (H-SG1, SG3, SG5, SG6D, SG7D, SG11 és Hegytartó)", 
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK VarioSurg4 NON FT Sebészeti berendezés",
    image: "variosurg4_non_ft.jpg",
    desc: "A VarioSurg4 lábkapcsoló nélküli ultrahangos kötéssebészeti rendszer a VarioSurg 4 bevezetésével fejlődött. Az  úttörő nagy teljesítményű ultrahangos technológiának és a különféle eljárásokhoz használható hegyek változatos kínálatának köszönhetően a VarioSurg 4 gyors és bonyolult csonteltávolítást és formázást tesz lehetővé.",
    desc2: "A technika minimálisan invazív, korlátozza a környező lágyrészek károsodását a hagyományos eljárásokhoz képest. Az olyan új gyakorlati előnyökkel, mint a vezeték nélküli lábvezérlés, a Surgic Pro2-vel való integráció és kompatibilitás, valamint a kifinomult, stílusos kialakítás, a VarioSurg 4 felülmúlja a klinikusok elvárásait, miközben stresszmentes környezetet teremt.",
    desc3: "Főbb jellemzők : Jelentős teljesítménynövelés: 3 mód (SURG, ENDO, PERIO) /Választható sorozatfelvétel mód (3 frekvencia) / 9 program (SURG x 5, ENDO x 2,PERIO x 2) / ",
    desc4: "Állítható LED fényerősség /Automatikus tisztítási mód /Visszacsatolás funkció / Automatikus hangolás funkció / Dinamikus kapcsolat funkció",
    desc5: "Alkalmazási területek: Szájsebészet,Csontműtét",
    desc6: "VarioSurg 4 komplett szett tartalma: VarioSurg4 Control Unit/LED kézidarab 2méteres vezetékkel/Sterilizáló kazetta",
    desc7: "Handpiece Stand/Szerelék (3 darab) és egyéb tartozékok / Alap H-S Kit (H-SG1, SG3, SG5, SG6D, SG7D, SG11 és Hegytartó)", 
    sale: 0,
    category: 5,
    subcategory: "Implantológiai berendezések",
    
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Surgic Pro2 Sebészeti berendezés",
    image: "surgicpro2.jpg",
    sale: 0,
    slug: "produktum 117",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "A nemzetközileg elismert Surgic Pro sebészeti impalntációs készülék „Surgic Pro2” -vé fejlődött.",
    desc2: "Három előrehaladási pont van, működés, biztonság és vezeték nélküli bővíthetőség.",
    desc3: "Program : 8 Program / Implant Systems",
    desc4: "Méretek: 24 x235 x90 mm",
    desc5: "Max. Szivattyú kimenet 75 mL/min",
    desc6: "Nyomaték:5-80 Ncm",
    desc7: "Motor: 200-40,000 perc-1",
    desc8: "32,000 LUX fényerővel ",
    desc9: "Áram ellátás: AC 100-240 V 50/60 Hz",
    desc10: "Lábkapcsoló funkciói:",
    desc11: "Hűtőfolyadék -áramlás hangerőgombja, PRG (program) gomb, előre/hátra gomb, sebességszabályozó pedál",
    desc12: "Tartalma:",
    desc13: "Vezérlőegység ",
    desc14: "1 db SGL80M LED Mikromotor",
    desc15: "Vezetéknélküli lábkapcsoló ",
    desc16: "1 db X-SG20L sebészeti könyökdarab (20:1 áttétel)",
    desc17: "hűtőcső (3 db.) + egyéb kiegészítők",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Osseo 100+ implantátum stabilitás mérő",
    image: "osseo100.jpg",
    sale: 0,
    slug: "produktum 118",
    category: 5,
    subcategory: "Implantológiai berendezések",
    desc: "Az  Osseo 100+ implantátum stabilitását és osszeointegrációját méri , hogy támogassa az implantátum behelyezésének idejét.",
    desc2: "Különösen fontos, ha rövidebb kezelési idővel dolgozik, vagy kockázatos betegeket kezel",
    desc3: "Jellemzők:",
    desc4: "Vezetéknélküli kapcsolat Surgic Pro2-vel",
    desc5: "Tápellátás 5VDC, 1 VA Töltő bemenet 100-240 VAC, 5VA Műszer súlya 100 g Akkumulátor teljes töltési ideje Kb. 3 óra.* Az akkumulátor folyamatos vezetési ideje Kb. 1 óra.*",
    desc6: "*A használati körülményektől függően változik.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 5051nou",
    image: "5051.jpg",
    sale: 0,
    slug: "produktum 119",
    category: 5,
    subcategory: "Implantológiai könyökdarabok",
    desc: "Könyökdarab sebészeti használatra.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső és belső hűtéssel.",
    desc5: "Nyomógombos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 5053nou",
    image: "5053.jpg",
    sale: 0,
    slug: "produktum 121",
    category: 5,
    subcategory: "Implantológiai könyökdarabok",
    desc: "Nem fényes Könyökdarab sebészeti használatra.",
    desc2: "20:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső és belső hűtéssel.",
    desc5: "Nyomógombos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 5109nou",
    image: "5109.jpg",
    sale: 0,
    slug: "produktum 122",
    category: 5,
    subcategory: "Implantológiai könyökdarabok",
    desc: "Könyökdarab sebészeti használatra.",
    desc2: "2:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső és belső hűtéssel.",
    desc5: "Nyomógombos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 5200nou",
    image: "5200.jpg",
    sale: 0,
    slug: "produktum 123",
    category: 5,
    subcategory: "Implantológiai könyökdarabok",
    desc: "Könyökdarab sebészeti használatra.",
    desc2: "16:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső és belső hűtéssel.",
    desc5: "Nyomógombos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 5201nou",
    image: "5200.jpg",
    sale: 0,
    slug: "produktum 123",
    category: 5,
    subcategory: "Implantológiai könyökdarabok",
    desc: "Könyökdarab sebészeti használatra.",
    desc2: "32:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső és belső hűtéssel.",
    desc5: "Nyomógombos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1710nou",
    image: "1710.jpg",
    sale: 0,
    slug: "produktum 125",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Egyenes darab.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső hűtéssel.",
    desc5: "Hossz: 44 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1910nou",
    image: "1910.png",
    sale: 0,
    slug: "produktum 126",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Egyenes darab.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső hűtéssel.",
    desc5: "Hossz: 70 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1951nou",
    image: "1951.jpg",
    sale: 0,
    slug: "produktum 127",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Egyenes darab.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső hűtéssel.",
    desc5: "Hossz: 95 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1960nou",
    image: "1960.jpg",
    sale: 0,
    slug: "produktum 128",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Egyenes darab.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső hűtéssel.",
    desc5: "Hossz: 70 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1961nou",
    image: "1961.jpg",
    sale: 0,
    slug: "produktum 129",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Egyenes darab.",
    desc2: "1:1 áttétel.",
    desc3: "Befogó – 2,35mm átmérő.",
    desc4: "Külső hűtéssel.",
    desc5: "Hossz: 95 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG65",
    image: "XSG65.jpg",
    sale: 0,
    slug: "produktum 130",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "XSG65 Nem fényes verzió",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "HP fúrószárhoz (átmérő 2.35).",
    desc4: "Sprayhűtés / Külső hűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max Fordulat – 40,000 min-1.",
    desc7: "Tiszta fejrendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG65L",
    image: "XSG65.jpg",
    sale: 0,
    slug: "produktum 130",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "XSG65L Fényes verzió",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "HP fúrószárhoz (átmérő 2.35).",
    desc4: "Sprayhűtés / Külső hűtés.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max Fordulat – 40,000 min-1.",
    desc7: "Üvegszálas optika/Tiszta fejrendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG20L",
    image: "XSG20.jpg",
    sale: 0,
    slug: "produktum 131",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "XSG20L Fényes verzió",
    desc2: "Áttétel – 20:1 Csökkenő.",
    desc3: "Külső és belső hűtés (Kirschner és Meyer).",
    desc4: "Max Fordulat – 2,000 min-1.",
    desc5: "Titánium burkolat DURACOAT bevonattal.",
    desc6: "Max forgatónyomaték – 80 Ncm.",
    desc7: "Üvegszálas optika/Nyomógombos befogópofa/Dupla tömítő rendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SG20",
    image: "sg20.jpg",
    sale: 0,
    slug: "produktum 132",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "SG20 nem fényes verzió",
    desc2: "Áttétel – 20:1 Csökkenő.",
    desc3: "Külső és belső hűtés (Kirschner és Meyer).",
    desc4: "Max Fordulat – 2,000 min-1.",
    desc5: "Rozsdamentes acéltest",
    desc6: "Max forgatónyomaték – 80 Ncm.",
    desc7: "Nyomógombos befogópofa/Dupla tömítő rendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-DSG20L",
    image: "XDSG20.jpg",
    sale: 0,
    slug: "produktum 134",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "XDSG20L Fényes,szétszedhető verzió",
    desc2: "Áttétel – 20:1 Csökkenő.",
    desc3: "Külső és belső hűtés (Kirschner és Meyer).",
    desc4: "Max Fordulat – 2,000 min-1.",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Max forgatónyomaték – 80 Ncm.",
    desc7: "Üvegszálas optika / Nyomógombos befogópofa Dupla tömítő rendszer.",
    desc8: "*Nem tartalmazza a lamineer tippeket.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG25L",
    image: "XSG25L.jpg",
    sale: 0,
    slug: "produktum 135",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "X-SG25L Fényes verzió",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "CA fúrószárhoz (Ø2.35)",
    desc4: "Sprayhűtés / Külső hűtés.",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Max Fordulat – 40,000 min-1.",
    desc7: "Üvegszálas optika / Nyomógombos befogópofa/ Tiszta fejrendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG93",
    image: "XSG93.jpg",
    sale: 0,
    slug: "produktum 136",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "X-SG93 nem fényes verzió",
    desc2: "Áttétel – 1:3 Növekvő.",
    desc3: "FG fúrószárhoz (Ø1.6).",
    desc4: "Háromszoros Sprayhűtés/ Külső hűtés.",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Max Fordulat – 120,000 min-1.",
    desc7: "Tiszta fejrendszer / Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X-SG93L",
    image: "XSG93.jpg",
    sale: 0,
    slug: "produktum 137",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "X-SG93L Fényes verzió",
    desc2: "Áttétel – 1:3 Növekvő.",
    desc3: "FG fúrószárhoz (Ø1.6).",
    desc4: "Háromszoros Sprayhűtés/ Külső hűtés.",
    desc5: "Titánium burkolat DURACOAT® bevonattal.",
    desc6: "Max Fordulat – 120,000 min-1.",
    desc7: "Üvegszálas optika / Tiszta fejrendszer / Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z-SG45",
    image: "ZSG45L.jpg",
    sale: 0,
    slug: "produktum 138",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Z-SG45 nem fényes verzió",
    desc2: "Áttétel – 1:3 Növekvő.",
    desc3: "FG fúrószárhoz (Ø1.6).",
    desc4: "Háromszoros Sprayhűtés / Külső hűtés.",
    desc5: "Titánium burkolat DURAGRIP bevonattal.",
    desc6: "Max Fordulat – 120,000 min-1.",
    desc7: "DLC Bevonat / Anti hő rendszer / Üvegszálas optika (Z-SG45L) / Kerámia csapágyak / Tiszta fejrendszer / Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z-SG45L",
    image: "ZSG45L.jpg",
    sale: 0,
    slug: "produktum 139",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Z-SG45L Fényes verzió",
    desc2: "Áttétel – 1:3 Növekvő.",
    desc3: "FG fúrószárhoz (Ø1.6).",
    desc4: "Háromszoros Sprayhűtés / Külső hűtés.",
    desc5: "Titánium burkolat DURAGRIP bevonattal.",
    desc6: "Max Fordulat – 120,000 min-1.",
    desc7: "DLC Bevonat / Anti hő rendszer / Üvegszálas optika (Z-SG45L) / Kerámia csapágyak / Tiszta fejrendszer / Nyomógombos befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK nanoSG20LS",
    image: "nanoSG20LS.jpg",
    sale: 0,
    slug: "produktum 140",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Áttétel – 20:1 Csökkenő.",
    desc2: "Külső és belső hűtés (Kirschner és Meyer).",
    desc3: "Titánium burkolat DURACOAT® bevonattal.",
    desc4: "Max Fordulat – 2,000 min-1.",
    desc5: "Max forgatónyomaték – 80 Ncm.",
    des6: "Üvegszálas optika / Nyomógombos befogópofa / Dupla tömítő rendszer.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SGA-E2S",
    image: "SGAE2S.jpg",
    sale: 0,
    slug: "produktum 141",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Fejméret 20°-os Kézidarabok.",
    desc2: "Áttétel – 1: 2 Növekvő.",
    desc3: "Sebészeti fúrószárhoz (Ø2.35).",
    desc4: "Max Fordulat – 80,000 min-1.",
    desc5: "* Csavaros befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SGA-ES",
    image: "SGAES.jpg",
    sale: 0,
    slug: "produktum 142",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Fejméret 20°-os Kézidarabok.",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "Sebészeti fúrószárhoz (Ø2.35).",
    desc4: "Max Fordulat – 40,000 min-1.",
    desc5: "*Csavaros befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SGS-ES",
    image: "SGSES.jpg",
    sale: 0,
    slug: "produktum 143",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "Fejméret Egyenes Kézidarab.",
    desc2: "Áttétel – 1:1 Direkt Vezetés.",
    desc3: "Sebészeti fúrószárhoz (Ø2.35).",
    desc4: "Max Fordulat – 40,000 min-1.",
    desc5: "*Csavaros befogópofa.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SGS-E2S",
    image: "SGSE2S.jpg",
    sale: 0,
    slug: "produktum 144",
    category: 5,
    subcategory: "Sebészeti kézidarabok",
    desc: "",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 1706 Infúziós szerelék",
    image: "1706szerelek.jpg",
    sale: 0,
    slug: "produktum 148",
    ccategory: 5,
    subcategory: "Kiegészítők",
    desc: "Infúziós szerelék MD11/MD30 motorhoz egy könyökdarab hűtésére.",
    desc2: "2 méter hosszú.",
    desc3: "Steril, egyszer használatos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 6022 Infúziós szerelék",
    image: "6022szerelek.jpg",
    sale: 0,
    slug: "produktum 149",
    category: 5,
    subcategory: "Kiegészítők",
    desc: "Infúziós szerelék Dispenser DP30-hoz.",
    desc2: "4 méter hosszú.",
    desc3: "Steril, egyszer használatos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 6024 Infúziós szerelék",
    image: "6024szerelek.jpg",
    sale: 0,
    slug: "produktum 150",
    category: 5,
    subcategory: "Kiegészítők",
    desc: "Infúziós szerelék MD20/HighSurg11/HighSurg30 berendezésekhez.",
    desc2: "3 méter hosszú.",
    desc3: "Steril, egyszer használatos.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SG70M",
    image: "SGL70M.jpg",
    sale: 0,
    slug: "produktum 151",
    category: 5,
    subcategory: "Sebészeti mikromotorok",
    desc: "Nem fényes verzió",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK SGL70M",
    image: "SGL70M1.jpg",
    sale: 0,
    slug: "produktum 152",
    category: 5,
    subcategory: "Sebészeti mikromotorok",
    desc: "Fényes verzió",
    desc2: "Az NSK klinikusok visszajelzésének elemzésével kifejlesztette a Surgic Pro SGL70M-et a, hogy ez az 5. generációs sebészeti mikromotor megfeleljen a szakmai követelményeknek.",
    desc3: "Könnyű és kompakt Surgic Pro SGL70M LED fényt tartalmaz a nagy láthatóság érdekében, akár 80Ncm nyomatékkal alkalmas a különböző sebészeti beavatkozásokhoz, és előkészíti a fejlett kezelési környezetet.",
    desc4: "81.9 mm, 280 g* Beleértve a motorkábelt.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-gaz Tecno25",
    image: "tecno25.jpg",
    sale: 0,
    slug: "produktum 153 ",
    category: 5,
    subcategory: "Szívóberendezés",
    desc: "",
    desc2: "Általános adatok",
    desc3: "Sebészeti szívó: nagy vákuum | magas áramlás.",
    desc4: "EN ISO 60601-1.",
    desc5: "Elektromos veszélyek elleni védelem: 1. osztály.",
    desc6: "Védelem a közvetlen és közvetett érintkezés ellen: 1. osztály.",
    desc7: "Az anyagok behatolása elleni védelem szintje: IPX1.",
    desc8: "Védettség az AD-FT zónában (CE164-4): Nem megfelelő.",
    desc9: "Biztonsági szint érzéstelenítő keverékek és / vagy robbanóanyagok jelenlétében: Nem megfelelő.",
    desc10: "Technikai adatok",
    desc11: "Elektromos tápegység: 230V ˜ 50 | 60 Hz.",
    desc12: "Elektromos teljesítmény (max.): 140 W.",
    desc13: "Elektromos védelem: fuse 2A.",
    desc14: "Akkumulátor: NA.",
    desc15: "Szívás",
    desc16: "Szivattyú: nem kenhető dugattyúval.",
    desc17: "Áramlás: max. szivattyú áramlási sebessége: 40 L / perc.",
    desc18: "Áramlási sebesség használat közben stabilizált áramlási sebesség (max.): 25 L /perc.",
    desc19: "Maximum depression: 0,8 Bar.",
    desc20: "Hosszúság: 410 mm.",
    desc21: "Mélyéség: 205 mm.",
    desc22: "Magasság 1L tartállyal ( 2L-es tartállyal): 260 (335) mm.",
    desc23: "Súly: 4,4 kg.",
    desc24: "Hangszint: NA.",
    desc25: "Használjon korlátozásokat: Lásd a használati útmutatót.",
    desc26: "Megfelelőség: MDD 93/42 CEE – EN60601-1-2 – EN10079-1 EN60601-1 (CE166-5).",
    desc27: "A készülék várható műszaki élettartama: 5 év.",
    desc28: "Vákuum beállítás",
    desc29: "Vákuumos beállító szelep (baktérium | vírusos): Installed with scale.",
    desc30: "Fokozatos vákuummérő: ø 50 0 | -0,8 Bar.",
    desc31: "Hidrofób szűrő.",
    desc32: "Szűrési képesség (baktérium | vírusos): 0,2 µm (> 99,99999%).",
    desc33: "Anyag: PTFE",
    desc34: "Csövek",
    desc35: "Anyaga: Orvosi minőségű szilíciu.",
    desc36: "Átmérő: ø belső =5 ø külső =9 (mm).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag Vacuson",
    image: "vacuson.jpg",
    sale: 0,
    slug: "produktum 154",
    category: 5,
    subcategory: "Szívóberendezés",
    desc: "Vacuson 40, 40 liter/perc, vákuum -0,9 bar, univerzális pumpa.",
    desc2: "Szett tartalma:",
    desc3: "Vezérlőegység VACUSON 40 (4275), manometer 0-1 bar, vákuumszabályzó, 2 tartálytartó, 3 csatlakozóval: az ON/OFF lábkapcsolóhoz, lábkapcsoló szabályzó, tápkábel csatlakozó.",
    desc4: "1 db on/off lábkapcsoló (15012).",
    desc5: "1 db 5 méteres tápkábel.",
    desc6: "10 db bakterológiai szűrő, eldobható (4246).",
    desc7: "Szívó tartály, 2 liter, törhetetlen.",
    des8: "Szívó tartályhoz fedő, túlfolyóval.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Mobil elszívó-Aspi-Jet",
    image: "aspijet.jpg",
    sale: 0,
    slug: "produktum 155 ",
    category: 6,
    subcategory: "Elszívók",
    desc: "Nedves elszvó",
    desc2: "7 y típus esetében: IIa osztályú orvostechnikai eszköz.",
    desc3: "Automatikus leeresztés a pumpa segítségével.",
    desc4: "Zajszint 10-es számú plasztik tip-pel 58 dB (A).",
    desc5: "Zajszint 20-as számú plasztik tip-pel 62 dB (A).",
    desc6: "Sz= 500 mm Mé= 350 mm Ma= 760 mm.",
    desc7: "Bruttó súly= 43 kg",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Micro-Smart",
    image: "microsmart.jpg",
    sale: 0,
    slug: "produktum 156",
    category: 6,
    subcategory: "Elszívók",
    desc: "Nedves elszvó",
    desc2: "Teljesítmény 1.12 kW – 6.3 A.",
    desc3: "Átfolyás max. 916 l/min.",
    desc4: "1-2 kezelőegységhez ajánlott.",
    desc5: "Zajszint 64 dB (A) – 71 dB (A) (doboz nélkül 60Hz – 120 Hz).",
    desc6: "Zajszint 63 dB (A) – 68.5 dB (A) (zajcsökkentő dobozzal 60Hz – 120 Hz).",
    desc7: "Sz= 370 mm Mé= 340 mm Ma= 590 mm.",
    desc8: "Bruttó súly= 32 kg.",
    desc9: "Amalgám szeparátorral szerelt verzió is kérhető – Hydrocyclone ISO 6, TÜV tesztelt, ISO 11143 szabványnak megfelelő.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Turbo-Smart",
    image: "turbosmart.jpg",
    sale: 0,
    slug: "produktum 157",
    category: 6,
    subcategory: "Elszívók",
    desc: "Nedves elszvó",
    desc2: "Teljesítmény: 1.22 kW – 7 A.",
    desc3: "Maximum 1400 l/min. átfolyás.",
    desc4: "Két kezelőegységhez ajánlott (B verzió esetén 4 kezelőegység is működtethető).",
    desc5: "Doboz nélkül 70Hz – 85Hz: 68.4 dB (A) – 69 dB (A).",
    desc6: "Zajcsökkentő dobozzal 70Hz – 85Hz: 66.4 dB (A) – 67 dB (A).",
    desc7: "Sz= 390 mm Mé= 350 mm Ma= 630 mm.",
    desc8: "Bruttó súly= 49 kg.",
    desc9: "Amalgám szeparátorral szerelt verzió is kérhető: Hydrocyclone ISO 18, TÜV tesztelt, ISO 11143 szabványnak megfelelő.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Jet",
    image: "tecnojet.jpg",
    sale: 0,
    slug: "produktum 158",
    category: 6,
    subcategory: "Elszívók",
    desc: "Száraz elszvó",
    desc2: "Egyfázisú teljesítmény 1.1 kW – 7.5 A, háromfázis 1.5 kW – 6/3.5 A.",
    desc3: "Átfolyás max. 2170 l/min.",
    desc4: "Két kezelőegységhez ajánlott",
    desc5: "Zajszint 65 dB (A).",
    desc6: "Sz= 303 mm Mé= 543 mm Ma= 319 mm.",
    desc7: "Bruttó súly= 27 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Uni-Jet 75",
    image: "unijet.jpg",
    sale: 0,
    slug: "produktum 159",
    category: 6,
    subcategory: "Elszívók",
    desc: "Száraz elszvó",
    desc2: "Egyfázisú teljesítmény 0.37 kW – 2.9 A, átfolyás max. 1250 l/min.",
    desc3: "Zajszint 62 dB (A).",
    desc4: "Egy kezelőegységhez ajánlott",
    desc5: "Sz= 231.5 mm Mé= 246 mm Ma= 251 mm.",
    desc6: "Bruttó súly= 10 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Flux-Jet",
    image: "fluxjet.jpg",
    sale: 0,
    slug: "produktum 160",
    category: 6,
    subcategory: "Elszívók",
    desc: "Száraz elszvó",
    desc2: "Egyfázisú teljesítmény 1.1 kW – 7.6 A, háromfázisú teljesítmény 1.5 kW – 6.4/3.7A, maximum átfolyás 3300 l/min.",
    desc3: "1 vákuum bemenethez ajánlott",
    desc4: "Zajszint 68.5 dB (A).",
    desc5: "Sz= 334 mm Mé= 405 mm Ma= 341 mm.",
    desc6: "Bruttó súly= 27 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Maxi-Jet 2S",
    image: "maxijet.jpg",
    sale: 0,
    slug: "produktum 161",
    category: 6,
    subcategory: "Elszívók",
    desc: "Száraz elszvó",
    desc2: "Kimeneti teljesítmény – 3 fázisú 4 kW – 16,7/9,7 A, max. átfolyás 8333 l/min.",
    desc3: "12 munkahely egyidejű működéséhez ajánlott.",
    desc4: "Zajszint 76.5 dB (A).",
    desc5: "Sz= 556 mm Mé= 422 mm Ma= 443 mm.",
    desc6: "Bruttó súly= 60 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Uni-Jet 75",
    image: "mediojet.jpg",
    sale: 0,
    slug: "produktum 162",
    category: 6,
    subcategory: "Elszívók",
    desc: "Száraz elszvó",
    desc2: "Háromfázisú teljesítmény 2.2 kW – 9/5.2 A, maximum átfolyás 5000 l/min.",
    desc3: "Zajszint 76 dB (A).",
    desc4: "2 vákuum bemenethez ajánlott.",
    desc5: "Sz= 395 mm Mé= 332 mm Ma= 415 mm.",
    desc6: "Bruttó súly= 35 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "AC 100 Kompresszor",
    image: "ac100.jpg",
    sale: 0,
    slug: "produktum 163",
    category: 6,
    subcategory: "Kompresszorok",
    desc: "1 munkahelyes kompresszor, 1 kezelőegységhez ajánlott.",
    desc2: "Elérhető szárítóval vagy nélküle, illetve zajcsökkentő dobozzal vagy nélküle.",
    desc3: "Egyfázisú motor 50 Hz – 0.55 kW – 3.8 A.",
    desc4: "Légtartály: 30 liter.",
    desc5: "5 bar nyomáson előállított levegő: 67.5 N l/min.",
    desc6: "Zajszint 70 dB (A) (doboz nélkül).",
    desc7: "Sz= 620 mm Mé= 460 mm Ma= 720 mm.",
    desc8: "Bruttó súly= 61 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "AC 200 Kompresszor",
    image: "ac200.jpg",
    sale: 0,
    slug: "produktum 164",
    category: 6,
    subcategory: "Kompresszorok",
    desc: "2 munkahelyes kompresszor, 2 kezelőegységhez ajánlott.",
    desc2: "Elérhető szárítóval vagy nélküle, illetve zajcsökkentő dobozzal vagy nélküle.",
    desc3: "Egyfázisú motor 60 Hz – 1.5 kW – 9.2 A.",
    desc4: "Légtartály: 30 liter.",
    desc5: "5 bar nyomáson előállított levegő: 160 N l/min.",
    desc6: "Sz= 620 mm Mé= 460 mm Ma= 720 mm.",
    desc7: "Bruttó súly= 68 kg.",
    desc8: "Zajszint 71 dB (A) (doboz nélkül).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "AC 300 Kompresszor",
    image: "ac300.jpg",
    sale: 0,
    slug: "produktum 164",
    category: 6,
    subcategory: "Kompresszorok",
    desc: "3 munkahelyes kompresszor, 3 kezelőegységhez ajánlott.",
    desc2: "Elérhető szárítóval vagy nélküle, illetve zajcsökkentő dobozzal vagy nélküle.",
    desc3: "Egyfázisú motor 60 Hz – 1.75 kW – 12.2 A.",
    desc4: "Légtartály: 45 liter.",
    desc5: "5 bar nyomáson előállított levegő: 238 N l/min.",
    desc6: "Zajszint 73.6 dB (A).",
    desc7: "Sz= 620 mm Mé= 520 mm Ma= 750 mm.",
    desc8: "Nettó súly= 65 kg Bruttó súly= 79 kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "ORION 40DS operációs lámpa",
    image: "orion40ds.jpg",
    sale: 0,
    slug: "produktum 165",
    category: 9,
    subcategory: "",
    desc: "Olyan műtéti eljárások elvégzésének megvilágítására tervezték, ahol az eljárást végző sebésznek kisebb lámpatesttel szükséges a munkafelületet bevilágítani.",
    desc2: "Kiválóan alkalmazható szájsebészeti- és esztétikai plasztikai- műtétek során.",
    desc3: "Az Orion 40 DS garantálja, hogy fényvetületét más mennyezeti fényforrás vetülete ne zavarja meg.",
    desc4: "6 szegmensre osztott lámpafej",
    desc5: "30 db elliptikus reflektor, szegmensenként 5db led izzóval",
    desc6: "130 000 lux maximális megvilágítási erősség",
    desc7: "O-glass edzett üveg: áttetsző, színtartó, antisztatikus, karcálló",
    desc8: "Elérhető mennyezeti, falra szerelhető,mobil verzióban",
    desc9: "Gyártó: Tecno-Gaz",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Faro EDI operációs lámpa",
    image: "faroedi.jpg",
    sale: 0,
    slug: "produktum 166",
    category: 9,
    subcategory: "",
    desc: "Az EDI egy fejlett tervezésű fogászati ​​lámpa, amely garantálja a szájüreg optimális megvilágítását. ",
    desc2: "Az EDI fogászati ​​lámpa halogén fényforrását egy dupla hátsó parabolaüveg szórja szét, így biztosítva az árnyék nélküli fényt és a jól definiált világító spotlámpát: 145 x 90 mm.",
    desc3: "A kialakítása illeszkedik az ergonómiához és a beavatkozás egyszerűségéhez, a sima és lekerekített felületek könnyen tisztíthatók.",
    desc4: "Általános jellemzők:",
    desc5: "Tápegység:17 V – 50/60 Hz, Transzformátor:230V – 50/60Hz,Felvett teljesítmény:95 VA.",
    desc6: "Világítás forrás:1 halogén 17 V – 95 W.",
    desc7: ":Fix vagy kivehető fogyantyúk (kivehető esetén azokt autoklávozhatóak).",
    desc8: "Joystick vezérlő bekapcsolással",
    desc9: "Mikroprocesszorral szabályozott kényszerszellőztető rendszer",
    desc10: "RAL színek teljes választéka",
    desc11: "Az EDI bármilyen típusú fogászati kezelő​​egységre felhelyezhető,  vagy mennyezetre, padlóra, oszlopra, falra rögzítve,",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Faro  MAIA operációs lámpa",
    image: "maia.jpg",
    sale: 0,
    slug: "produktum 167",
    category: 9,
    subcategory: "",
    desc: "A Maia ideális választás minden fogorvos számára, aki professzionális LED-lámpát keres, megbízható megoldást, hosszú élettartamot és magas szintű világítási teljesítményt versenyképes áron.",
    desc2:
      "A FARO fényvisszaverő fény exkluzív szabadalmaztatott optikai projektje elkerüli a két használt LED-forrás közvetlen kitettségét. Az egyes reflektorok 258 kis fényvisszaverő oldala teljesen egyenletes, 170 x 95 mm-es megvilágított mezőt garantál.",
    desc3:
      "Az elektronikus világításvezérlés joystick-kal, szenzorral vagy fogorvosi kezelőegységen aktiválható. Állandó színhőmérséklet fenntartása mellett a maximálistól a minimális folyamatos intenzitásig állítja a fényt.",
    desc4: "A használt fényintenzitás automatikus mentése a fogászati ​​egység kikapcsolása és az öblítés funkció után.",
    desc5: "A tényleges árnyék minimális méretei, amelyeket jogszabály ír elő: Ø 12 mm. A MAIA fogászati ​​lámpa ülői hatása mindössze 9 x 6 mm-es ellipszist hoz létre.",
    desc6: "Általános jellemzők:",
    desc7: "Tápegység: 17-24 Vac 50/60 Hz – 22-3. , Transzformátor: 230V – 50/60Hz, Teljesítmény: max. 9 VA.",
    desc8: "Világítás forrás: 2 LED.",
    desc9: "Hűtőrendszer: Statikus hűtőborda (nincs ventilátor szükséges).",
    desc10: "Fogantyúk: kivehetőek és autoklávozható.",
    desc11: "Komplett RAL színskála.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz Master Flux éberszedáló",
    image: "MasterFlux.jpg",
    sale: 0,
    slug: "produktum 168",
    category: 10,
    subcategory: "Éberszedálók",
    desc: "Automata szedációs gép.",
    desc2: "A fogászati ​​fóbia probléma igen elterjedt, még a felnőttek körében is.",
    desc4: "Vannak olyan napi rutin eljárások, mint például a  fogkő eltávolítás, amelyek gyakran problémákat okoznak. Az éber-szedáló megoldja ezeket a problémákat.",
    desc5: "A Master Flux az egyetlen nyugtató gép, amelyet Olaszországban fejlesztettek.",
    desc6: "Különösen a legkényesebb pillanatokban segíti a beteg nyugtatását. Minden fázisban annak érdekében, hogy a kezelés illetve munkamenet nyugodtan és együttműködve történjen.",
    desc7: "A stabilitási vizsgálatok alapján, a Master Flux Plus megfelel az EN 60204 irányelveknek.",
    desc8: "Mester Flux Plus komplett:",
    desc9: "Áramlásszabályozó doboz.",
    desc10: "Lélegzet-szabályozás táska.",
    desc11: "1 redőzött tömlőt.",
    desc12: "1 áramkör felnőtt maszk.",
    desc13: "1 áramkör gyermek maszk.",
    desc14: "1 OX szűkítő tömlővel és csatlakozóval.",
    desc15: "1 N2O szűkítő tömlővel és csatlakozóval.",
    desc16: "Az éber szedációs módszer során, a relaxa-gáz hatására csökken a páciens féleleme, szorongása az esetlegesen rá váró fogászati kezeléstől.",
    desc17: "A relaxa-gáz relaxációs állapotot idéz elő, mely által:",
    desc18: "– A beteg fájdalomküszöb szintje megemelkedik, tehát a fájdalomcsillapítás erőteljesebbé válik.",
    desc19: "– A beteg éber és együttműködő marad.",
    desc20: "– A garatreflexek működnek.",
    desc21: "– A kezelési idő gyorsabban telik.",
    desc22: "A Master Flux éber szedációs készülékkel nitrogénoxidul és az oxigén gáz keveréke tetszőleges arányban (0-70%) változtatható, mindig a beteg igényeinek megfelelő adagolással.",
    desc23: "A Master Flux készülékkel bódítani, altatni nem lehet, csupán az éber szedáció elérésére alkalmas.",
    desc24: "A Master Flux alkalmazása teljesen biztonságos. Használatakor tisztán dinox gáz nem juthat a tüdőbe, mert az oxigén gáz vezérli a gázok áramlását.",
    desc25: "A Master Flux készüléket rosszullét, sürgősségi ellátás esetén is lehet alkalmazni. A beteg számára oxigénnel való inhalálást ill. lélegeztetést lehet biztosítani.",
    desc26: "Elérhető fali, illetve mobil verzióban is.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz Master Flux Plus Mobile éberszedáló",
    image: "MasterfluxPlusmobile.jpg",
    sale: 0,
    slug: "produktum 169",
    category: 10,
    subcategory: "Éberszedálók",
    desc: "Az éber szedációs módszer során relaxa-gáz hatására csökken a páciens féleleme, szorongása az esetlegesen rá váró fogászati kezeléstől.",
    desc2: "A relaxa-gáz relaxációs állapotot idéz elő, mely által:",
    desc3: "– A beteg fájdalomküszöb szintje megemelkedik, tehát a fájdalomcsillapítás erőteljesebbé válik.",
    desc4: "– A beteg éber és együttműködő marad.",
    desc5: "– A garatreflexek működnek.",
    desc6: "– A kezelési idő gyorsabban telik.",
    desc7: "A Master Flux éber szedációs készülékkel nitrogénoxidul és az oxigén gáz keveréke tetszőleges arányban (0-70%) változtatható, mindig a beteg igényeinek megfelelő adagolással.",
    desc8: "A Master Flux készülékkel bódítani, altatni nem lehet, csupán az éber szedáció elérésére alkalmas.",
    desc9: "A Master Flux alkalmazása teljesen biztonságos, mert használatakor tisztán dinox gáz nem juthat a tüdőbe, mert az oxigén gáz vezérli a gázok áramlását.",
    desc10: "A Master Flux készüléket rosszullét, sürgősségi ellátás esetén is lehet alkalmazni. A beteg számára oxigénnel való inhalálást ill. lélegeztetést lehet biztosítani.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz Clip fogfehérítő",
    image: "clip.jpg",
    sale: 0,
    slug: "produktum 170",
    category: 10,
    subcategory: "Fogfehérítők",
    desc: "Clip fehérítés lámpa egy új rendszer a fogászati ​​fehérítésben.",
    desc2: "A lámpa, egyszerű és praktikus kialakítású.",
    desc3: "Kompakt, kis méretű, táskában hordozható készülék a betegszékhez rögzíthető, flexibilis karral, mely könnyű pozicionálást tesz lehetővé.",
    desc4: "A beépített LED-ek hőmérsékletét a készülék folyamatosan ellenőrzi, és a hűtőrendszer megakadályozza azok túlhevülését.",
    desc5: "Bekapcsoláskor 10 perces kezelési ciklus indul, ezt követően a készülék automatikusan kikapcsol. Szükség esetén a készülék manuálisan is kikapcsolható.",
    desc6: "A tápegység elhelyezhető a földön, pulton vagy a falra is rögzíthető.",
    desc7: "A lámpa használatával a nehezen elérhető területek is könnyen fehéríthetők.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz Pearl fogfehérítő",
    image: "Pearl.jpg",
    sale: 0,
    slug: "produktum 171",
    category: 10,
    subcategory: "Fogfehérítők",
    desc: "A Tecno-Gaz kifejlesztette a Pearl terméket, amely egy új kimagasló színvonalú berendezés a fogfehérítő rendszerek piacán.",
    desc2: "480 és 520nm közti hullámhosszt használ. A lámpa speciális kijelzőjének köszönhetően, lehetővé válik az ultraibolya és infravörös fény elnyelése.",
    desc3: "Technikai adatok",
    desc4: "Magasság: 1400 mm",
    desc5: "Szélesség: 45 mm",
    desc6: "Átmérő: 500 mm",
    desc7: "Súly: 15 kg",
    desc8: "Áramellátás: 230 VOLT",
    desc9: "Frekvencia: 50 Hz",
    desc10: "Elnyelt energia: 50 VA",
    desc11: "Áramerősség: F 1 A",
    desc12: "Hullám (kézidarab): 495 nm",
    desc13: "Max. fényerő: 30.000 mw",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz One fóliahegesztő",
    image: "one.jpg",
    sale: 0,
    slug: "produktum 172",
    category: 10,
    subcategory: "Fóliahegesztők",
    desc: "A One a legújabb elektromosan irányított fóliahegesztő berendezés, amely lehetővé teszi apapír/műanyag kombinációjú anyag hővel történő összehegesztését.",
    desc2: "A hegesztés a lehető legkisebb helyen megy végbe, mely a berendezés speciális ergonomikus kialakításának köszönhető.",
    desc3: "A vízszintesen elhelyezett görgök megkönnyítik munkánkat, így a lehető legkevesebb helyet foglalja, így bármelyik szekrényben könnyen elfér.",
    desc4: "A fűtő elem közvetlenül melegszik a hegesztésnél így elkerülhetjük a gyakori periodikus karbantartást mellyel pénzt és időt spórolhatunk meg.",
    desc5: "Technikai adatok",
    desc6: "Feszültség: 230V – 50/60 Hz.",
    desc7: "Hegesztő szélessége: 12 mm.",
    desc8: "Hegesztő hosszúsága: 300mm.",
    desc9: "Mélysége: 302 mm.",
    desc10: "Magassága: 332 mm.",
    desc11: "Szélesség: 498 mm.",
    desc12: "Maximális elszívás: 1000 W.",
    desc13: "Súly: 10kg.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Tecno-Gaz SterilAirPRO ",
    image: "SterilAirPRO.jpg",
    sale: 0,
    slug: "produktum 173",
    category: 10,
    subcategory: "Levegőtisztítók",
    desc: "Sterilair PRO egy új levegő sterilizátor, amely biztosítja minden mikroorganizmus megszüntetését, beleértve a spórákat is.",
    desc2: "Ez a készülék folyamatosan működik, maximális kezelői védelmet nyújtva.",
    desc3: "A Sterilair PRO egyszerű és ergonomikus kialakítású.",
    desc4: "Nagyon csendes, mérete csökkent, könnyű plug & play telepítés, a lefedettség pedig 100 m3.",
    desc5: "Tecno-Gaz az egyetlen olyan vállalat, amely egy átfogó sterilizálási program hatékony, ergonomikus és biztonságos.",
    desc6: "Besugárzás rendszer UV-C lámpák nagy frekvencián.",
    desc7: "Nem tartalmaz cserélhető szűrőt.",
    desc8: "Sterilair PRO készülék nem tisztítja a levegőt, de sterilizálja.",
    desc9: "Üzemzavar jelentés rendszerrel ellátott.",
    desc10: "Fázisok száma: 1",
    desc11: "Térfogatáram m3 / h: 120",
    desc12: "Zajszint dBA: 32",
    desc13: "Lámpák: n. 4. UV-C cső 25W G13T8 (7 W UVGI)",
    desc14: "Hullámhossz: 253,7 nm",
    desc15: "Energia Ultravioletta: 69 ìW/cm2 1 m (egy lámpa)",
    desc16: "Berendezés: Por védett szűrő. Elektronikus programozó szerkezet.",
    desc17: "Fertőtlenítő lámpa vezérlés:  Anti UV-C fény útmutató",
    desc18: "Tápfeszültség: 230 ± 10% VAC",
    desc19: "Maximális áram biztosítékok: 2 A",
    desc20: "Tápfeszültség jelentése: 50-60 Hz",
    desc21: "Maximális elektromos elnyelt: 150 W",
    desc22: "Nettó tömege termék: 15 kg",
    desc23: "HIBC Code: + E309SA210ZSA4D",
    desc24: "Kockázati osztály orvosi eszköz: classe I",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "TKD Moon Evolution polimerizációs lámpa",
    image: "tkdmoon.jpg",
    sale: 0,
    slug: "produktum 174",
    category: 10,
    subcategory: "Polimerizációs lámpák",
    desc: "Nagy teljesítményű polimerizációs lámpa 6 különböző programmal,amelyek közül három fokozatos lágyindítással rendelkezik.",
    desc2: "A fogászati ​​egységre szerelhető",
    desc3: "Nagy teljesítményű LED által generált fény",
    desc4: "Egyenes vagy pisztolyhelyzet",
    desc5: "A lámpa 8 mm-es optikai szállal és 2 év garanciával rendelhető.",
    desc6: "Intenzitás: 1.000 – 1.800 mW/cm². ",
    desc7: "Hullámhossz 430 – 490 nm.",
    desc8: "20 ÷ 36 V dc; 24 ±10 % Vac; Maximum 350 mA.",
    desc9: "Méretek: ∅39mm (csuklós)/ ∅28mm (test) x 225mm .",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: " TKD DEFINITIVE-LED mikromotor",
    image: "TKDDefinitiveLed.jpg",
    sale: 0,
    slug: "produktum 175",
    category: 3,
    subcategory: "Mikromotorok",
    desc: "Nagy hatékonyságú, kefe nélküli háromfázisú elektromikromotorok professzionális fogászati használatra.",
    desc2: "A mikromotorok bármilyen egyenes vagy derékszögű kézidarabhoz alkalmazhatók, belső permetezéssel és csatlakozással, amely megfelel az ISO 3964 szabványnak.",
    desc3: "A DEFINITIVE LED modell LED fényforrással van felszerelve, és bármilyen száloptikával ellátott kézidarabhoz használható.",
    desc4: "A generált 25000-LUX nappali minőségű megvilágítás tökéletesen stabil marad a teljes sebességtartományban.",
    desc5: "A mikromotorok nem feltétlenül igényelnek kenést.",
    desc6: "A mikromotorokat a speciális BMC40 elektronikus kártyával együtt kell használni, amelyet úgy terveztek, hogy könnyen felszerelhető legyen bármilyen fogászati egységbe, nagyon egyszerű vezetékekkel.",
    desc7: "A mikromotor 2 éves garanciával rendelkezik",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Egyszerhasználatos Infúziós szerelék",
    image: "nskszerelek.jpg",
    sale: 0,
    slug: "produktum 176",
    category: 5,
    subcategory: "Kiegészítők",
    desc: "Infúziós szerelék NSK SurgicPro, Surgic XT,VarioSurg4 készülékekhez",
    desc2: "Kiszerelés 5db/csomag ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Autoklávozható Infúziós szerelék",
    image: "nskszerelekautoklav.jpg",
    sale: 0,
    slug: "produktum 177",
    category: 5,
    subcategory: "Kiegészítők",
    desc: "Infúziós szerelék NSK SurgicPro, Surgic XT,VarioSurg4 készülékekhez",
    desc2: "Kiszerelés 1 db/csomag ",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Goldspeed Silent Power Evo turbina - 4L, 4L-K",
    image: "4l.jpg",
    sale: 0,
    slug: "produktum 178",
    category: 3,
    subcategory: "Turbinák",
    desc: "Rozsdamentes acél.",
    desc2: "Midwest csatlakozás, Stern Weber/ Kavo kuplung.",
    desc3: "Fényes.",
    desc4: "4 szórófej (4+4 fúvóka).",
    desc5: "Max. 350.000 rpm.",
    desc7: "Autoklávozható.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M900",
    image: "m900l.jpg",
    sale: 0,
    slug: "produktum 179",
    category: 3,
    subcategory: "Turbinák",
    desc: "M900 nem fényes verzió",
    desc2: "Standard fej.",
    desc3: "Teljesítmény – 26W.",
    desc4: "Fordulat – 325,000-430,000 min-1.",
    desc5: "Fejméret – Ø12.1 x H13.3 mm.",
    desc7: "Négyszeres sprayhűtés.",
    desc8: "Rozsdamentes acél test.",
    desc9: "Kerámia csapágyak / Tiszta fejrendszer / Nyomógombos befogópofa / Egyszerű Fejbelsőrész csere.",
    desc10: "Elérhető Sirona,W&H,BienAir csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M900L",
    image: "m900l.jpg",
    sale: 0,
    slug: "produktum 180",
    category: 3,
    subcategory: "Turbinák",
    desc: "M900L Fényes verzió",
    desc2: "Standard fej.",
    desc3: "Teljesítmény – 26W.",
    desc4: "Fordulat – 325,000-430,000 min-1.",
    desc5: "Fejméret – Ø12.1 x H13.3 mm.",
    desc7: "Négyszeres sprayhűtés.",
    desc8: "Rozsdamentes acél test.",
    desc9: "Üvegszálas optika/ Kerámia csapágyak / Tiszta fejrendszer / Nyomógombos befogópofa / Egyszerű Fejbelsőrész csere.",
    desc10: "Elérhető Sirona,W&H,BienAir csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M800",
    image: "m800l.jpg",
    sale: 0,
    slug: "produktum 181 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "M800 Nem fényes verzió",
    desc2: "Mini fej.",
    desc3: "Teljesítmény – 23W",
    desc4: "Fordulat – 380,000-450,000 min-1",
    desc5: "Fejméret – Ø10.6 x H12.4 mm.",
    desc7: "Négyszeres sprayhűtés",
    desc8: "Rozsdamentes acél test.",
    desc9: "Elérhető Sirona,W&H,BienAir csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK M800L",
    image: "m800l.jpg",
    sale: 0,
    slug: "produktum 182 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "M800L Fényes verzió",
    desc2: "Mini fej.",
    desc3: "Teljesítmény – 23W",
    desc4: "Fordulat – 380,000-450,000 min-1",
    desc5: "Fejméret – Ø10.6 x H12.4 mm.",
    desc7: "Négyszeres sprayhűtés",
    desc8: "Rozsdamentes acél test.",
    desc9: "Elérhető Sirona,W&H,BienAir csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Pana-Max 2",
    image: "pana_max2_ptl_.jpg",
    sale: 0,
    slug: "produktum 187 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "A Pana-Max2 sokkal több kitartást és energiát kínál, mint a korábbi modellek, mellette ugyanolyan könnyen kezelhető.",
    desc2: "A karcsú kialakítása miatt maximális láthatóságot biztosít az üzemeltetési területen, könnyen elérve a szájüreg minden területét.",
    desc3: "Rozsdamentes acél test.",
    desc4: "Kerámia csapágyak.",
    desc5: "Tiszta fejrendszer.",
    desc6: "NSK Ultra befogópofa.",
    desc7: "Egyszeres Sprayhűtés.",
    desc8: "Fordulat – 300,000~400,000 min-1 (PTL / KV), 380,000~480,000 min-1 (M4 / B2), Fejméret : ø11.2 x H13.4 mm.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Pana-Max Plus",
    image: "Pana_max_plus.jpg",
    sale: 0,
    slug: "produktum 188 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "A nagy sebességű kézidarabok sorozatát a Pana-Max PLUS-ot tovább fejlesztették, hogy megfeleljen az elvárásoknak​​ a modern fogászati műtétek területén.",
    desc2: "Kialakított pontossága biztosítja a klinikai eljárások elvégzéséhez szükséges teljesítményt és megbízhatóságot.",
    desc3: "Rozsdamentes acél test.",
    desc4: "ISB turbina.",
    desc5: "Kerámia csapágyak.",
    desc7: "Tiszta fejrendszer.",
    desc8: "Nyomógombos befogópofa.",
    desc9: "Négyszeres Sprayhűtés.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK S-Max pico",
    image: "smax_pico.jpg",
    sale: 0,
    slug: "produktum 189 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "Innovatív légturbina ultra mini fejjel és szuper vékony testformával. Az NSK S-Max pico széria a minimális beavatkozásokhoz (M.I.) lett fejlesztve.",
    desc2: "Az NSK precizításnak köszönhetően a kialakított ultra mini fej és a szupervékony kézidarab lehetővé teszi nagyobb rugalmasság érdekében az operatív terület szélesebb nézetét.",
    desc3: "Rozsdamentes acél test.",
    desc4: "ISB Turbina.",
    desc5: "Üvegszálas optika.",
    desc6: "Kerámia csapágyak.",
    desc7: "Tiszta fejrendszer.",
    desc8: "Egyszeres Sprayhűtés.",
    desc9: "Teljesítmény – 9 W, Fordulat: 380,000~450,000 min-1.",
    desc10: "Fejméret – ø8.6 x H9.0 mm.",
    desc11: "Rövid fúrószárhoz / ultra vékony fúrószárhoz.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK X450L",
    image: "X450l.jpg",
    sale: 0,
    slug: "produktum 190 ",
    category: 3,
    subcategory: "Turbinák",
    desc: "LED fényes.",
    desc2: "Titánium burkolat DURACOAT® bevonattal.",
    desc3: "Üvegszálas optika.",
    desc4: "Kerámia csapágyak.",
    desc5: "Tiszta fejrendszer.",
    desc6: "Nyomógombos befogópofa.",
    desc7: "Hármas fúvóka.",
    desc8: "Teljesítmény – 21 W (X450L, X450)",
    desc9: "18W (KL, WLED, WHL, MWL, STL, 5H, M4).",
    desc10: "Fordulat – 380,000~450,000 min-1.",
    desc11: "Fejméret – ø11.2 x H13.5 mm.",
    desc12: "Extra hosszú fúrószárhoz (25mm).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Daily Oil Plus - Olajzó spray",
    image: "dailyoil.jpg",
    sale: 0,
    slug: "produktum 191 ",
    category: 11,
    subcategory: "",
    desc: "Olajzó spray a turbinákhoz és a fogászati ​​kézi műszerekhez.",
    desc2: "A forgó műszerek, turbinák, kézi darabok és könyökdarabok ultramagas teljesítményéhez tervezett kenőanyag.",
    desc3: "A műszer belső mechanikájának maximális védelme és a teljesítmény fenntartása az idő előrehaladtával napi szinten és minden autoklávozás előtt.",
    desc4: "Optimális felhasználás minden eszközön a termékhez kapott szilikon adapter-készletnek köszönhetően.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Peroxy Ag+ - Fertőtlenítő folyadék",
    image: "peroxy.jpg",
    sale: 0,
    slug: "produktum 192 ",
    category: 11,
    subcategory: "",
    desc: "Fertőtlenítő folyadék a fogászati kezelőegység vízrendszeréhez.",
    desc2: "Hidrogén-peroxid és ezüstion-alapú fertőtlenítő, kiváló széles spektrumú biocid hatással. Kifejezetten a Stern Weber márkához tartozó fogászati ​​egységek higiéniai rendszereihez fejlesztették ki.",
    desc3: "Gyors hatású: 10 perces intenzív fertőtlenítési ciklusú kontaktus megöli a baktériumok 99,999% -át.",
    desc4: "A vízellátás higiéniai minőségének biztosítása: folyamatos 600 ppm automata adagoló-rendszerrel vagy kézi adagolással a palackba.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Ster1 Plus - Felületi fertőtlenítő",
    image: "ster1.jpg",
    sale: 0,
    slug: "produktum 193 ",
    category: 11,
    subcategory: "",
    desc: "STER 1 PLUS fertőtlenítő folyadék a kezelőegység felületéhez",
    desc2: "– Tuberkulocid, baktericid, fungicid, virucid.",
    desc3:
      "Kifejezetten a fogászati kezelőegység és a betegszék felületeinek tisztítására és fertőtlenítésére szolgál. Kiválóan alkalmas még kényes felületekre is, köszönhetően a kiegyensúlyozott formulának, csökkentett alkoholtartalommal (40%).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Ster3 Plus - - Átmosó folyadék",
    image: "ster3.jpg",
    sale: 0,
    slug: "produktum 194 ",
    category: 11,
    subcategory: "",
    desc: "STER 3 PLUS Koncentrált fertőtlenítő folyadék szívórendszerekhez.",
    desc2: "Nagy tisztító és fertőtlenítő erő az ajánlott hígítás mellett (60 ml termék 1 liter vízhez).",
    desc3: "Praktikus automatikus használat esetén: tegyen 6% -os oldatot az automata kanülmosó rendszer tartályába.",
    desc4: "Hatékony kézi felhasználás: forró vízzel hígítva (6%-os oldat) a kanülön keresztül lassan hagyja felszívni a szívócsövek alapos átmosása érdekében.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: " NSK iCare olajzó berendezés",
    image: "icare.jpg",
    sale: 0,
    slug: "produktum 195 ",
    category: 11,
    subcategory: "",
    desc: "Az iCare új szabványt állít fel a kézidarab-karbantartási rendszerek területén. Egy letisztult és stílusos kompakt gép, mely több funkcióval biztosítja a könnyű teljes tisztítást, kenést.",
    desc2: "A megfelelő megoldás a kézidarab típusa alapján van beállítva. A karbantartás egy gombnyomással indítható.",
    desc3:
      "A befogópofa tisztítórendszere az eredeti helyén tartja a befogópofákat, az öblítő kiválasztási mód lehetővé teszi a tisztítási idő megváltoztatását a helyzettől függően az igazán átfogó karbantartás érdekében.",
    desc4: "Stílusos és kompakt testének köszönhetően az iCare helytakarékos és teljes mértékben-összhangban van a munkakörnyezetével.",
    desc5: "Kettős Standard Portok + Kettős Forgás Portok",
    desc6: "MODEL: iCare C2 Type (120V)",
    desc7: "MODEL: iCare C2 Type (230V)",
    desc8: "Egyetlen Standard Port + Hármas Forgás Portok",
    desc9: "MODEL: iCare C3 Type (120V)",
    desc10: "MODEL: iCare C3 Type (230V)",
    desc11: "Tartalmaz:",
    desc12: "iCare egység / Tápkábel / Befogópofa tisztító fúvóka / Karbantartási olaj az iCare-hez (1 liter) / / Porszűrő szett / Olaj felszívó lap / Légcső.",
    desc13: "Tápegység – AC 100-240V 50/60Hz.",
    desc14: "Levegő nyomás – 0.5-0.6 MPa (5.0-6.0 kgf/cm2).",
    desc15: "Súly – 6.5 kg (C2 típus / C3 típus).",
    desc16: "Paraméterek – 280 x 240 x 350 (mm).",
    desc17: "Folyadék tartály kapacitása – 1.2 L.",
    desc18: "* Csak a Midwest 4-lyukú típushoz csatlakoztatható.",
    desc19: "* Szükség van levegő csővezetékre (5.0-6.0 kgf/cm2).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK iCare+",
    image: "icare+.jpg",
    sale: 0,
    slug: "produktum 196 ",
    category: 11,
    subcategory: "",
    desc: "Az iCare új szabványt állít fel a kézidarab-karbantartási rendszerek területén. Egy letisztult és stílusos kompakt gép, mely több funkcióval biztosítja a könnyű teljes tisztítást, kenést.",
    desc2: "A megfelelő megoldás a kézidarab típusa alapján van beállítva. A karbantartás egy gombnyomással indítható.",
    desc3:
      "A befogópofa tisztítórendszere az eredeti helyén tartja a befogópofákat, az öblítő kiválasztási mód lehetővé teszi a tisztítási idő megváltoztatását a helyzettől függően az igazán átfogó karbantartás érdekében.",
    desc4: "Stílusos és kompakt testének köszönhetően az iCare helytakarékos és teljes mértékben-összhangban van a munkakörnyezetével.",
    desc5: "Tápegység – AC 100-230 V 50/60 Hz.",
    desc6: "Kapacitás –  akár 4 műszerig.",
    desc7: "Levegő nyomás –  0.5-0.6 MPa (5.0-6.0 kgf/cm2 ).",
    desc8: "Súly – 14 kg.",
    desc9: "Paraméterek – 400 x 405 x 355 (mm).",
    desc10: "Az olajtartály kapacitása – 500 mL.",
    desc11: "A tisztító és fertőtlenítő termék palackok kapacitása – 500 mL.",
    desc12: "Tartalma – iCare egység/Tápkábel L-alakú dugóval/Légcső/leírás DVD-n.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Nouvag 2128 Olajzó spray",
    image: "2128spray.jpg",
    sale: 0,
    slug: "produktum 197 ",
    category: 11,
    subcategory: "",
    desc: "Az eszközök és mikromotorok tisztítására és karbantartására.",
    desc2: "500 ml",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK PANA Spray Plus Olajzó spray",
    image: "panasprayplus.jpg",
    sale: 0,
    slug: "produktum 198 ",
    category: 11,
    subcategory: "",
    desc: "Nagy és alacsony fordulatszámú kézidarabokhoz és légmotorokhoz alkohol tartalmú kenőanyag",
    desc2: "Egy üveg 480 ml-t tartalmaz",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "3Di IOS - 3D Digital Impression Intra Oral Scanner",
    image: "3dios.jpg",
    image2: "3dios2.jpg",
    image3: "3dios3.jpg",
    image4: "3dios4.jpg",
    image5: "3dios5.jpg",
    sale: 0,
    slug: "produktum 199 ",
    category: 1,
    subcategory: "Intraorális szkenner ",
    desc: "3D Digital Impression Intra Oral Scanner",
    desc2: "Egyszerűen kezelhető, precíz, gyors, a fogorvosi rendelő munkamenetébe tökéletesen integrálható.",
    desc3: "Miért a 3Di IOS?",
    desc4: "Könnyű, ergonomikus kézidarab súlya csupán 150 g",
    desc5: "autoklávozható, 0-180°elforgatható tip",
    desc6: "egyszerű használat: szkennelés egyetlen gombnyomásra",
    desc7: "felhasználóbarát, letisztult kezelőfelület",
    desc8: "iRys-integrálhatóság, nyílt rendszer: STL, PLY és OBJ fájlformátumok",
    desc9: "további licenszdíjak nélkül rendelkezésre állnak a szoftverfrissítések",
    desc10:
      "3D intraorális szkenner virtuális modellek készítéséhez, amelyeket a következő alkalmazási területeken lehet használni: inlay, onlay, overlay, koronák, hidak, makett, scanbody az Implanto-protetikához, fogszabályozás.",
    desc11: "Ergonomikus kézidarab kivehető autoklávozható végződéssel, párásodás-gátló rendszerrel, a kézidarab beépített gombjával a beolvasáshoz és a feldolgozáshoz – egyszerű működtetés.",
    desc12: "Támasztó tartó a kézidarabhoz.",
    desc13: "USB 3.0 csatlakozás 2m kábellel.",
    desc14: "100 – 240 V tápegység.",
    desc15: "3Di IOS szkenner szoftver.",
    desc16: "Nyílt rendszer: STL, PLY és OBJ fájlok tárolása.",
    desc17: "Garancia: 24 hónap",
    desc18: "Jellemzők:",
    desc19: "Folyamatos videoszkennelés",
    desc20: "Színes letapogatás",
    desc21: "0-15 mm-es fókuszterület",
    desc22: "Opálosító por használata nélkül",
    desc23: "USB 3.0 csatlakozás",
    desc24: "Automatikus kalibráló rendszer",
    desc25: "Alapméretek + kézidarab: 306 x 98 x 72 mm (Kézidarab mérete: 265 x 45 x 45 mm, tartó mérete 193 x 99 x 72 mm)",
    desc26: "A kézidarab súlya 150 g (pl. 3Shape Trios3: 340g, Carestream CS3600: 325g, Medit i500: 276g)",
    desc27: "Bemutató videók az alábbi linkekre kattintva érhetők el:",
    desc28: "3Di IOS teljes fogív szkennelés",
    desc29: "3Di IOS Implant munkafolyamat",
    desc30: "3Di IOS prothesis munkafolyamat",
    desc31: "A szett tartalma:",
    desc32: "3Di IOS készülék (kézidarab) csatlakozókábellal és tápegységgel",
    desc33: "összesen 3 db autoklávozható tip (egyenként 150 alkalommal autoklávozhatóak)",
    desc34: "USB 3.0 kábel",
    desc35: "USB kulcs a szoftverrel (3DiScan Suite Software)",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Scanner - 3Di TS",
    image: "3dits.jpg",
    sale: 0,
    slug: "produktum 200 ",
    category: 1,
    subcategory: " Intraorális szkenner",
    desc: "Szkenner, amely megfelel a digitális fogászat kihívásainak.",
    desc2: "Megkönnyíti a fogorvos és a páciens közti kommunikációt a vizuális eszközök segítségével.",
    desc3: "Gyors szkennelési idő – pl. teljes fogív összesen 35 sec, egyetlen fog összesen 49 sec.",
    desc4: "Biztonságosabb és hatékonyabb diagnózis és terápia a különböző digitális eszközök integrációjával.",
    desc5: "Fogszabályozó szakorvosok számára is ideális, hiszen a lenyomatot szkennelve, 3D nyomtató segítségével könnyen elkészíthetőek a láthatatlan fogszabályozó sínek.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Aurora",
    image: "aurora2_2.jpg",
    sale: 0,
    slug: "produktum 201",
    category: 8,
    subcategory: "Bútorok",
    desc: "A ZILFOR bútorok sorozatának úttörője. Az Aurora természetes összetételének egyszerűsége és ennek köszönhetően szilárdsága, biztonságos „menedéket” jelent azok számára, akik olyan bútorok kombinációját keresik, mely egyszerű, klasszikus, de modern stílussal rendelkezik.",
    desc2: "Az ergonómikus megoldások folyamatos kutatása határozzák meg a minőségét.",
    desc3: "Az Aurora elemei garantálják a higiéniát, a tisztaságot és a praktikusságot funkcionális és személyre szabott környezetben.",
    desc4: "Az Aurora sorozat sokoldalúságát is mutatja, hogy tökéletesen passzol az üveg vagy a Corian munkalap eleganciájával.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Elite",
    image: "elite_2.jpg",
    sale: 0,
    slug: "produktum 202",
    category: 8,
    subcategory: "Bútorok",
    desc: "Karakter eleganciával.",
    desc2: "Professzionális megoldás, melyben a funkcionalitás és az elegancia tökéletesen kombinálódik.",
    desc3: "A lineáris formáknak és az elemek sokoldalúságának köszönhetően az Elite szinte minden környezetben alkalmazható.",
    desc4:
      "Kezdetben úgy tervezték, hogy Corian fedlappal kombinálható legyen, de idővel az Elite tökéletesen kombinálódott üveglappal is, amely az egyik legizgalmasabb anyag és így még kifinomultabbá teszi a kinézetét.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Elite Touch",
    image: "elite-touch2_2.jpg",
    sale: 0,
    slug: "produktum 203",
    category: 8,
    subcategory: "Bútorok",
    desc: "Karakter eleganciával.",
    desc2: "Funkcionális kapacitás csak egy kattintással.",
    desc3: "Az Elite falra függesztett változatban is kapható – a lábazat nélkül, tökéletes megoldás.",
    desc4: "Könnyű tisztítás, higiénia szempontjából. Egyben lenyűgöző esztétikai hatást kelt.",
    desc5: "Azon szakemberek számára, akik teljesen lineáris és elegáns bútorzatot szeretnének az Elite Touch a megfelelő választás. Fogantyúk nélkül, a fiókok és ajtók praktikus push-pull típusú nyitásával.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Prestige",
    image: "prestige2_2.jpg",
    sale: 0,
    slug: "produktum 204",
    category: 8,
    subcategory: "Bútorok",
    desc: "A stílus és a funkció tökéletes összhangja.",
    desc2: "Szilárd, kompakt, tiszta vonalak.  A Prestige ideális megoldás azon fogorvosok számára, akik a maximális funkcionalitás mellett a stílust is szem előtt tartják.",
    desc3: "Könnyen tisztítható és tökéletesen higiénikus elemek.",
    desc4: "Az elemek egyénre szabhatóak a színválaszték, a fogantyúk és a fedlapok tekintetében.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C2RCHM Gurulós kocsi",
    image: "C2RCHM_2.jpg",
    sale: 0,
    slug: "produktum 205",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Maxi sebészeti gurulós kocsi 2 üvegpolccal, 1 fiókkal és 3 univerzális aljzattal.",
    desc2: "Méretei: 92 x 38 x 48 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C2RCHP Gurulós kocsi",
    image: "C2RCHP_colore_2.jpg",
    sale: 0,
    slug: "produktum 206",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Kis sebészeti gurulós kocsi 2 üvegpolccal és 3 univerzális aljzattal.",
    desc2: "Méretek: 88 x 34 x 42 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C2RK3 Gurulós kocsi",
    image: "C2RK3_2.jpg",
    sale: 0,
    slug: "produktum 207",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Gurulós kocsi 2 polccal, 3 fiókkal és 3 univerzális aljzattal.",
    desc2: "Méretek: 80 x 40 x 48 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C3R Gurulós kocsi",
    image: "C3R_2.jpg",
    sale: 0,
    slug: "produktum 208",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Gurulós kocsi 3 polccal és 2 univerzális aljzattal.",
    desc2: "Méretek: 79 x 45 x 40 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C3RK Gurulós kocsi",
    image: "c3rk_2.jpg",
    sale: 0,
    slug: "produktum 209",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Gurulós kocsi 3 polccal és 3 univerzális aljzattal.",
    desc2: "Méretek: Mé48 x Sz40 x M80 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C3RK1 Gurulós kocsi",
    image: "c3rk1_2.jpg",
    sale: 0,
    slug: "produktum 210",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Gurulós kocsi 3 polccal, 1 fiókkal és 3 univerzális aljzattal.",
    desc2: "Méretek: 80 x 40 x 48cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C3RQ Gurulós kocsi",
    image: "c3rq_2.jpg",
    sale: 0,
    slug: "produktum 211",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Eszköztartó kocsi 3 polccal és 2 univerzális aljzattal.",
    desc2: "Méretek: 78 x 45 x 35 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "C3RV Gurulós kocsi",
    image: "c3rv_2.jpg",
    sale: 0,
    slug: "produktum 212",
    category: 12,
    subcategory: "Műszerkocsik",
    desc: "Gurulós kocsi 3 üvegpolccal és 2 univerzális aljzattal.",
    desc2: "Méretek: 79.5 x 37 x 49 cm (Magasság x Szélesség x Mélység).",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Elektrokauter Surgery Plus MONOPOLARE 644/00",
    image: "Elektrokauter-surgery-plus.jpg",
    sale: 0,
    slug: "produktum 213",
    category: 7,
    subcategory: "",
    desc: "Rádiófrekvenciás alapon működő fogászati elektrokauter.",
    desc2:
      "A funkciók kiválasztásával lehetővé teszi a tiszta vágás CUT-t, a cut CUT1 és CUT-2 koagulációt és a tiszta koagulációt (COAG). A fedélzeti mikrovezérlő és a digitális kijelző minden körülmények között garantálja az állandó biztonságot és megbízhatóságot.",
    desc3: "A SURGERY PLUS kiemelkedő professzionális műtéti eredményeket kínál ergonómikus és kiemelkedő biztonsági funkcióinak köszönhetően.",
    desc4: "Technikai adatok:",
    desc5: "Maximális teljesítmény vágás esetén: 50 W",
    desc6: "Maximális teljesítmény vágás-alvasztás esetén: 45W/40W",
    desc7: "Maximális teljesítmény alvasztás esetén: 40W",
    desc8: "Működési frekvencia: 600kHz",
    desc9: "Választható bemeneti feszültség: 115/230V",
    desc10: "Hálózati frekvencia: 50-60Hz",
    desc11: "Teljesítmény: 280 VA",
    desc12: "Méretek: 190*85*239 mm",
    desc13: "Súly:2,5 kg",
    desc14: "Csomag tartalma:",
    desc15: "1 db monopoláris autoklávozható kézidarab",
    desc16: "1 db kábel a semleges elektródához",
    desc17: "10 db elektróda",
    desc18: "1 db semleges elektróda",
    desc19: "1 db lábkapcsoló",
    desc20: "1 db hálózati kábel",
    desc21: "Gyártó : Carlo de Giorgi",
    desc22: "Bipoláris csipesz 20 cm hegy 1 mmBipoláris csipesz 20 cm hegy 1 mm: Bipoláris kábel 3 m",
    desc23: "Bipoláris csipesz 20 cm hegy 1 mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Elektrokauter Surtron 120 MONO/BIPOLARE 642/00",
    image: "Elektrokauter-surton-120.jpg",
    sale: 0,
    slug: "produktum 214",
    category: 7,
    desc: "A SURTRON 120 egy magas frekvenciájú elektrosebészeti berendezés, amely könnyű és közepes sebészeti beavatkozásokhoz alkalmas. Teljesítményei lehetővé teszik a tiszta CUT-ot, a vágott koagulációs BLEND-et, a felszíni koagulációt FORCED COAG-ot, a mély koagulációt necroiss SOFT COAG hiányában és az adapterrel a BIPOLAR koagulációt.",
    desc2:
      "A semleges elektróda csatlakozását folyamatosan ellenőrzik. A beteg / lemez érintkezésének biztonsági ellenőrzése spli semleges elektróddal. A monopoláris kimeneti funkciók, valamint a kimeneti teljesítmény leadásának kezelése a fogantyú segítségével lehetővé teszi a műtéti művelet végrehajtását anélkül, hogy elfordítaná a sebész figyelmét a műtéti területtől.",
    desc3: "A bipoláris csipesz használatának lehetősége",
    desc4: "Minimálisan invazív műtéti kezelés megengedett",
    desc5: "A munkakörülmények megtervezése",
    desc6: "A legutóbb használt beállítások memóriája",
    desc7: "Áramellátás és / vagy fogantyúval",
    desc8: "Digitális szabályozás és kijelzés a kimeneti teljesítményről",
    desc9: "Az akusztikus jelző intenzitásának beállítása",
    desc10: "Normál csomag tartalma:",
    desc11: "Autoklávozható fogantyú kapcsolókkal",
    desc12: "Válogatott elektródák (10 db)",
    desc13: "Kábel a rúd semleges elektródához",
    desc14: "Acél semleges elektróda",
    desc15: "Vízálló lábkapcsoló",
    desc16: "Gyártó : Carlo de Giorgi",
    desc17: "Méretek: 250 x 100 x 290 mm",
    desc18: "Teljesítmény max. 120 Watt",
    desc19: "Súly:5 kg",
    desc20: "!Kötelező készlet bipoláris működéshez!",
    desc21: "Adapter bipoláris működéshez",
    desc22: "Bipoláris csipesz 20 cm hegy 1 mmBipoláris csipesz 20 cm hegy 1 mm: Bipoláris kábel 3 m",
    desc23: "Bipoláris csipesz 20 cm hegy 1 mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani AC400 kompresszor",
    image: "AC400.jpg",
    sale: 0,
    slug: "produktum 215 ",
    category: 6,
    subcategory: "Kompresszor",
    desc: "Olajmentes dupla-hengeres, duplafejű sűrített levegő kompresszor légszárítóval",
    desc2: "2 egyfázisú motor 230V – 50 Hz – 2.4kW – 15.4 A.",
    desc3: "légtartály: 100 liter",
    desc4: "levegőszállítás 5 real bar nyomáson: 320 N l/min.",
    desc5: "zajszint: 73 dB (A).",
    desc6: "méretek: Sz= 1155 mm Mé= 550 mm Ma= 745 mm",
    desc7: "nettó súlya: 112 kg (bruttó súlya: 139 kg)",
    desc8: "4-5 kezelőegységhez ajánlott",
    desc9: "Egyéb verzió:",
    desc10: "400V – 50 Hz, háromfázisú",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani AC600 kompresszor",
    image: "AC600.jpg",
    sale: 0,
    slug: "produktum 216 ",
    category: 6,
    subcategory: "Kompresszor",
    desc: "három-cilinderes, dupla-fejű kompresszor 2 levegő szárítóval",
    desc2: "2 három-fázisú motor 400V – 50Hz – össz. 3 kW – 7,4A ",
    desc3: "légtartály 150 liter",
    desc4: "5 bar nyomáson előállított levegő 476 N l/perc",
    desc5: "zajszint: 74 dB",
    desc6: "Sz= 1320mm Mé=590mm Ma=890mm",
    desc7: "Nettó súly: 137 kg, bruttó súly: 173 kg",
    desc8: "6-9 kezelőegységhez ajánlott maximum",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani hangszigetelő doboz AC100, AC200 és AC300 modellekhez",
    image: "hangszigetelodoboz.jpg",
    sale: 0,
    slug: "produktum 217",
    category: 6,
    subcategory: "Hangszigetelő doboz",
    desc: "A hangszigetelő doboz epoxid-poliészter porral festett elektrogalvanizált fémlemezből készült akár kültéri telepítéshez is.",
    desc2: "Egy hangszigetelő doboz három modellhez, beltéri és kültéri telepítéshez (esővízzel, közvetlen napsugárzással való érintkezés hideg vagy meleg éghajlaton).",
    desc3: "Egy munkahelyes kompresszor esetén: zajszint 50 dB (A)",
    desc4: "Két munkahelyes kompresszor esetén: zajszint 51 dB (A)",
    desc5: "Három munkahelyes kompresszor esetén: zajszint 51 dB (A)",
    desc6: "Sz= 840 mm Mé= 700 mm Ma= 887 mm",
    desc7: "Nettó súly= 84 kg (Bruttó súly= 116 kg)",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Turbo Smart Hydrocyclone ISO 18 amalgámszeparátorral hangszigetelt dobozban",
    image: "TurboSmartHydrocyclone.jpg",
    sale: 0,
    slug: "produktum 204 ",
    category: 6,
    subcategory: "Központi nedves elszívó",
    desc: "A doboz fémből készült, belül hangszigetelő anyaggal bélelve, és 18 dB-lel (A) csökkenti a zajszintet „A” (max. két kezelőegységhez) és „B” (max. 4 – 5 kezelőegységhez) változatban is.",
    desc2: "Turbo-Smart “A” (max. két kezelőegységhez) verzió 70Hz - 85Hz: 48.5 dB (A) - 49.5 dB (A)",
    desc3: "Turbo-Smart “B” (max. 4 – 5 kezelőegységhez) verzió 70Hz -110Hz: 48.5 dB (A) - 52.2 dB (A)",
    desc4: "Szé= 676 mm Mé= 555 mm Ma= 832 mm",
    desc5: "A Turbo-Smart doboz belsejében van.",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani Uni Jet-75 szívómotor dobozban",
    image: "unijet75withbox.jpg",
    sale: 0,
    slug: "produktum 218",
    category: 6,
    subcategory: "Szívómotor",
    desc: "Irányító panellal, hangszigetelt dobozban",
    desc2: "Teljesítmény: 0,4 kW, 1250 l/min",
    desc3: "Zajszint 58 dB (A).",
    desc4: "Sz= 315 mm Mé= 280 mm Ma= 330 mm",
    desc5: "nettó súly= 17 kg (bruttó súly= 20 kg)",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani miniszeparátor 90°",
    image: "Cattaniminiszeparator90.jpg",
    sale: 0,
    slug: "produktum 219",
    category: 6,
    subcategory: "Miniszeparátor",
    desc: "Tartalma: miniszeparátor 90°-os fedéllel, lefolyó egység, AC 45 panel, Ø 25 egyenes csőtartó, Ø 30 egyenes csőtartó, Ø 25 hajlított csőtartó, Ø 30 hajlított csőtartó.",
    desc2: "Tartály mérete: Sz= 195 mm Mé= 135 mm Ma= 340 mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Cattani miniszeparátor 180°",
    image: "Cattaniminiszeparator180.jpg",
    sale: 0,
    slug: "produktum 220",
    category: 6,
    subcategory: "Miniszeparátor",
    desc: "Tartalma: miniszeparátor 180°-os fedéllel, lefolyó egység, AC 45 panel, Ø 25 egyenes csőtartó, Ø 30 egyenes csőtartó, Ø 25 hajlított csőtartó, Ø 30 hajlított csőtartó.",
    desc2: "Tartály mérete: Sz= 195 mm Mé= 135 mm Ma= 340 mm",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "Depurátor hegyek",
    image: "DepurátorhegyekCefla.jpg",
    sale: 0,
    slug: "produktum 221",
    category: 10,
    desc: "30–C1 supragingivalis alkalmazásra",
    desc2: "31–C2 supragingivalis alkalmazásra",
    desc3: "32–C4 supragingivalis, metszőfogak és őrlőfogak nyaka",
    desc4: "33–P1 Profilaxis. Supra és subgingivalis alkalmazás, interproximalis terek és barázdák.",
    desc5: "34–R1 Fogüreg előkészítés (kis méretű beavatkozások), különösen alkalmas elülső fogak és őrlőfogak interdentális üregeihez.",
    desc6: "35–R2 Fogüreg előkészítés (kis méretű beavatkozások), különösen alkalmas elülső fogak és őrlőfogak interdentális üregeihez.",
    desc7: "36–R3 Fogüreg előkészítés (kis méretű beavatkozások), különösen alkalmas elülső fogak és őrlőfogak interdentális üregeihez.",
    desc8: "37–RR Fogüreg előkészítés (kis méretű beavatkozások), különösen alkalmas elülső fogak és őrlőfogak interdentális üregeihez.",
    desc9: "38–RL Fogüreg előkészítés (kis méretű beavatkozások), különösen alkalmas elülső fogak és őrlőfogak interdentális üregeihez.",
    desc10: "39–E4 csatornatágító gyémánt bevonatú",
    desc11: "40–E3 a guttapercha laterális termikus kondenzációjához",
    desc12: "41–P3 Subgingivalis és gyökérfelszíni alkalmazás, periodontális tasaköblítés antibakteriális szerekkel.",
    desc13: "42–E1 120°-os tokmány endodonciai reszelők rögzítéséhez",
    desc14: "43–E2 95°-os tokmány endodonciai reszelők rögzítéséhez",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z990L",
    image: "Z990L.png",
    sale: 0,
    slug: "produktum 222",
    category: 3,
    subcategory: "Turbinák",
    desc: "Z990L Fényes verzió",
    desc2: "Standard Fej",
    desc3: "Teljesítmény: 44 W",
    desc4: "Fordulat 280,000-360,000 min-1",
    desc5: "Fej méret ø12.1 x H 12.7 mm",
    desc6: "Négyszeres sprayhűtés.",
    desc7: "Titánium DURAGRIP bevonattal",
    desc8: "DINAMIKUS TELJESÍTMÉNYRENDSZER DURAPOWER TOKMÁNY / Kerámia csapágyak / cellás üvegoptika / Tiszta fejrendszer / Gyorsleállító nyomógombos tokmány / Egyszerű rotorcsere / Mikroszűrő / Fej 100°-os szögben",
    desc9: "Elérhető NSK, KaVo,Sirona,W&H,Bien-Air csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
    name: "NSK Z890L",
    image: "Z890L.png",
    sale: 0,
    category: 3,
    subcategory: "Turbinák",
    desc: "Z890L Fényes verzió",
    desc2: "Mini Fej",
    desc3: "Teljesítmény: 41 W",
    desc4: "Fordulat 320,000-400,000 min-1",
    desc5: "Fej méret ø10.6 x H 10.8 mm",
    desc6: "Négyszeres sprayhűtés.",
    desc7: "Titánium DURAGRIP bevonattal",
    desc8: "DINAMIKUS TELJESÍTMÉNYRENDSZER DURAPOWER TOKMÁNY / Kerámia csapágyak / cellás üvegoptika / Tiszta fejrendszer / Gyorsleállító nyomógombos tokmány / Egyszerű rotorcsere / Mikroszűrő / Fej 100°-os szögben",
    desc9: "Elérhető NSK, KaVo,Sirona,W&H,Bien-Air csatlakozással",
    amount: 1,
    price: 0,
  },
  {
    _id: uuidv4(),
     name: "Enbio Pro Gyorsautokláv",
     image: "enbiopro.jpg",
     image2: "enbiopro(2).jpg",
     image3: "enbiopro(3).jpg",
     image4: "enbiopro(4).jpg",
     category: 2,
     subcategory: "Enbio Gyorsautoklávok",
     desc: "Szupergyors sterilizálás és ideális hely a legtöbb műszerhez, beleértve az általános méretű implantológiai tálcákat is. Minden folyamatadat automatikusan USB-lemezre kerül.",
     desc2: "Gyors sterilizálás csomagolás nélkül (134°C): 9 perc. Sterilizálás csomagolva (134°C): 18 perc. Sterilizálás csomagolva (121°C): 30 perc. ",
     desc3: "Súly: 20 Kg. Csomag befogadó képesség: kb. 16 db. Max. befogadott súly: kb 1 kg. Kamra térfogata: 5,3 L Hasznos kamra méret: 28×19×7 cm. ",
     desc4: "Külső méretek: 25×20×56cm Vízellátás:Az Enbio külső tartályból desztillált vizet használ.",
     amount: 1,
     price: 0,
   },
 {
    _id: uuidv4(),
     name: "Enbio S Gyorsautokláv",
     image: "enbios.jpg",
     image2: "enbios(2).jpg",
     image3: "enbios(3).jpg",
     category: 2,
     subcategory: "Enbio Gyorsautoklávok",
     desc: "Az EnbioS  kis helyen is elfér. A rövid ciklusidő  miatt akár lehetővé teszik a műszerek sterilizálását két beteg között. Minden folyamatadat automatikusan USB-lemezre kerül.",
     desc2: "Gyors sterilizálás csomagolás nélkül (134°C): 9 perc. Sterilizálás csomagolva (134°C): 18 perc. Sterilizálás csomagolva (121°C): 30 perc. ",
     desc3: "Súly: 15 Kg. Csomagok száma: kb. 8 db. Max. ajánlott súly: 0,5 kg. Kamra térfogata: 2,7 L. Hasznos kamra méret: 28×18×3,5 cm. ",
     desc4: "Vízellátás:Az Enbio külső tartályból desztillált vizet használ.",
     amount: 1,
     price: 0,
   },
   {
    _id: uuidv4(),
     name: "VarioSurg4 & SurgicPro2",
     image: "variosurg4xsurgicpro2.jpg",
     category: 5,
     subcategory: "Implantológiai berendezések",
     desc: "A Bluetooth®-kompatibilis kapcsolati funkció* lehetővé teszi a vezeték nélküli kapcsolatot a SurgicPro2 és a VarioSurg4 között. Mindkét műszer vezérlése egyetlen lábkapcsolóval. ",
     desc2: "Mindkét rendszer ezen integrációja a közös interfész teljes szinergiát tesz lehetővé, megkönnyítve az egyszerű eljárások széles körét és stresszmentes működését. Ez az összetartó kialakítás hozzájárul egy kifinomultabb és egységesebb rendszerhez.",
     desc3: "A felhasználóbarát kijelző egyértelműen jelzi melyik rendszer aktív és biztosítja a biztonságos és pontos működést.",
     amount: 1,
     price: 0,
   },
    {
      _id: uuidv4(),
      name: "Soft Pearl",
      image: "SoftPearl.jpg",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      sale: 0,
      typ: "Soft Pearl",
      category: 3,
      subcategory: "Prophylaxis",
      desc: "Supragingival glicin alapja miatt kellemes ízű vízben oldódó profilaxis por , 4x 250g-os palack,Gyengéden eltávolítja az enyhe és közepes elszíneződéseket a supragingivális területen",
      desc2: "A SOFT pearl  kiválóan alkalmas gyermekek és allergiában szenvedők számára",
      desc3: "65 µm átlagos részecskeméretet választottak a SOFTpearl esetében, mivel ez a részecskeméret  alkalmas az enyhe elszíneződések, és a lepedék alapos eltávolítására",
      desc4: "",
      amount: 1,
      price: 0,
    },];

export default products;
