import React, { useState } from "react";
import axios from "axios";
import "../style/admin.css";
import { useNavigate } from "react-router-dom";
import { categories } from "../data.js";

const Admin = () => {
  const [product, setProduct] = useState({
    name: "",
    sale: 0,
    category: 0,
    subcategory: "",
    desc: "",
    amount: 1,
    price: 0,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "category") {
      const categoryValue = value ? parseInt(value, 10) : 0; 
      setProduct({ ...product, [name]: categoryValue });
      console.log(categoryValue);
    } else {
      setProduct({ ...product, [name]: value });
    }
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setProduct({ ...product, [name]: files[0] });
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("category", product.category);
    formData.append("subcategory", product.subcategory);
    formData.append("desc", product.desc);
    formData.append("amount", product.amount);
    formData.append("price", product.price);

    // Képek feltöltése
    if (product.image) {
      formData.append("image", product.image);
    }
    if (product.image2) {
      formData.append("image2", product.image2);
    }
    if (product.image3) {
      formData.append("image3", product.image3);
    }
    if (product.image4) {
      formData.append("image4", product.image4);
    }
    if (product.image5) {
      formData.append("image5", product.image5);
    }



    try {
      const response = await axios.post("http://localhost:5000/save-product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert(response.data);
      setProduct({
        name: "",
        category: 0,
        subcategory: "",
        desc: "",
        amount: 1,
        price: 0,
        image: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
      });
    } catch (error) {
      console.error("Hiba a termék mentése során:", error);
      alert("Hiba történt a termék mentésekor.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthorized");
    navigate("/");
  };

  return (
    <div className="admin-container">
      <h1>Új Termék Hozzáadása</h1>
      <form onSubmit={handleSubmit} className="admin-form">
        <div className="form-group">
          <label>Termék neve:</label>
          <input type="text" name="name" value={product.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Kategória:</label>
          <select name="category" value={product.category} onChange={handleChange}>
            <option value="">Válassz kategóriát</option>
            {categories.map((cat) => (
              <option key={cat.id} value={Number(cat.id)}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Alkategória:</label>
          <input type="text" name="subcategory" value={product.subcategory} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Leírás:</label>
          <textarea name="desc" value={product.desc} onChange={handleChange} rows={5} />
        </div>
        <div className="form-group">
          <label>Ár:</label>
          <input type="number" name="price" value={product.price} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Mennyiség:</label>
          <input type="number" name="amount" value={product.amount} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>1.Kép feltöltése:</label>
          <input type="file" name="image" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label>2.Kép feltöltése:</label>
          <input type="file" name="image2" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label>3.Kép feltöltése:</label>
          <input type="file" name="image3" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label>4.Kép feltöltése:</label>
          <input type="file" name="image4" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label>5.Kép feltöltése:</label>
          <input type="file" name="image5" onChange={handleFileChange} />
        </div>
        <div className="button-container">
          <button type="submit" className="submit-button">
            Mentés
          </button>
          <button type="button" className="logout-button" onClick={handleLogout}>
            Kijelentkezés
          </button>
        </div>
      </form>
    </div>
  );
};

export default Admin;
