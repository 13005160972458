import React, { useState, useEffect } from "react";
import Products from "../components/Products";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { products, categories } from "../data";
import ReactPaginate from "react-paginate";
import "../style/shoppage.css";
import SearchEngine from "../components/SearchEngine";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";


const ShopPage = ({ handleClick }) => {
  const [data, setData] = useState(products);
  const [search, setSearch] = useState("");
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentCategoryDescription, setCurrentCategoryDescription] = useState(""); 

  const productPerPage = 9;
  const pagesVisited = pageNumber * productPerPage;

  const navigate = useNavigate(); 
  const { categoryName, subCategoryName } = useParams();  

  const formatToUrl = (text) => {
    return text ? text.toLowerCase().replace(/\s+/g, "_") : ""; 
  };

  useEffect(() => {
    if (categoryName) {
      const normalizedCategoryName = categoryName.replace(/_/g, " ").toLowerCase(); 
      const category = categories.find((cat) => cat.name.toLowerCase() === normalizedCategoryName);

      if (category) {
        let filteredProducts = products.filter((product) => product.category === category.id);

        if (subCategoryName) {
          const normalizedSubCategoryName = subCategoryName.replace(/_/g, " ").toLowerCase(); 
          filteredProducts = filteredProducts.filter(
            (product) => product.subcategory?.toLowerCase() === normalizedSubCategoryName
          );
        }

        setData(filteredProducts);
        const filteredSubCategories = [...new Set(filteredProducts.map((prod) => prod.subcategory))];
        setSubCategories(filteredSubCategories);
        setExpandedCategory(category.id);
        setCurrentCategoryDescription(category.description);
        setPageNumber(0);
      } else {
        setData([]);
      }
    } else {
      setData(products);
      setCurrentCategoryDescription(""); 
    }
  }, [categoryName, subCategoryName]);


  const displayProducts = data
    .filter((value) => {
      if (search === "") {
        return value;
      } else if (
        value.name?.toLowerCase().includes(search.toLowerCase()) ||
        value.subcategory?.toLowerCase().includes(search.toLowerCase()) ||
        value.desc?.toLowerCase().includes(search.toLowerCase())
      ) {
        return value;
      }
    })
    .slice(pagesVisited, pagesVisited + productPerPage)
    .map((item) => {
      const category = categories.find(cat => cat.id === item.category); 
      const categoryName = category ? category.name : ""; 
      return (
        
        <Products 
          handleClick={handleClick} 
          category={categoryName} 
          item={item} 
          key={item._id} 
        />
      );
    });

  const pageCount = Math.ceil(data.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const filterByCategory = (categoryName) => {
    const category = categories.find(cat => cat.name.toLowerCase() === categoryName.toLowerCase());
    if (expandedCategory === category?.id) {
      setExpandedCategory(null);
      setData(products); 
      setCurrentCategoryDescription(""); 
      navigate("/termekek");
    } else {
      setExpandedCategory(category.id);
      const formattedCategoryName = formatToUrl(categoryName);
      navigate(`/termekek/${formattedCategoryName}`);
    }
  };

  const filterBySubCategory = (subCategoryName) => {
    const formattedCategoryName = formatToUrl(categoryName);
    const formattedSubCategoryName = formatToUrl(subCategoryName); 
    navigate(`/termekek/${formattedCategoryName}/${formattedSubCategoryName || ''}`);  
  };

  const showAllProducts = () => {
    navigate("/termekek");  
  };

  return (
    <div className="shop-container">
      <div className="shop-col">
        <div className="shop-menu">
          <SearchEngine search={search} setSearch={setSearch} />

          {categories &&
            categories.map((categoryObj, i) => {
              const categoryName = categoryObj.name;
              const categoryId = categoryObj.id;

              return (
                <div key={i}>
                  <button className="shop-btn" onClick={() => filterByCategory(categoryName)}>  
                    {categoryName}
                    <ChevronRightIcon
                      style={{
                        marginLeft: "12px",
                        transform: expandedCategory === categoryId ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </button>
                  {expandedCategory === categoryId && (
                    <div className="subcategories">
                      {subCategories.map((subCategory, j) => (
                        <button key={j} className="shop-sub-btn" onClick={() => filterBySubCategory(subCategory)}>
                          {subCategory}
                        </button>
                      ))}
                    </div>
                  )}
                  <div style={{ justifyContent: "center", alignItems: "center" }}>
                    <div className="shop-line"></div>
                  </div>
                </div>
              );
            })}

          <button style={{ left: 0, marginLeft: "-120px" }} className="shop-btn" onClick={showAllProducts}>
            Összes
            <ChevronRightIcon />
          </button>
        </div>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <p style={{ fontSize: "16px", color: "gray" }}>
            {currentCategoryDescription} 
          </p>
          <div className="shop-products">{displayProducts}</div>
        </div>

        <ToastContainer />
      </div>
      <div className="shop-pagination">
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  );
};


export default ShopPage;
