import React from "react";
import { Card } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import '../style/service.css';

const Services = () => {
  return (
    <div className="service-container">
      <Card className="service-card">
        <img src="./images/ertek.png" alt="Értékesítés" className="service-icon" />
        <h3>ÉRTÉKESÍTÉS</h3>
        <p>Professzionális és modern megoldásokat kínálunk, minőségi technikai eszközökkel.</p>
      </Card>
      <Card className="service-card">
        <BuildIcon className="service-icon" />
        <h3>SZERVÍZ</h3>
        <p>Gyors és precíz szerviz szolgáltatást biztosítunk Magyarország egész területén.</p>
      </Card>
      <Card className="service-card">
        <img src="./images/pipa.png" alt="Kivitelezés" className="service-icon" />
        <h3>KIVITELEZÉS</h3>
        <p>Segítséget nyújtunk a rendelők kialakításának kivitelezésében.</p>
      </Card>
      <Card className="service-card">
        <img src="./images/kez.png" alt="Márkaszerviz" className="service-icon" />
        <h3>MÁRKASZERVÍZ</h3>
        <p>Márkaszerviz szolgáltatást nyújtunk a piacon elismert gyártók termékeire.</p>
      </Card>
    </div>
  );
};

export default Services;