import React from 'react';
import { Card } from '@mui/material';
import Carousel from 'react-elastic-carousel';
import { clientsData } from '../clients';
import '../style/clients.css';

const Clients = () => {
  const breakPoints = [
    { width: 10, itemsToShow: 1 },
    { width: 480, itemsToShow: 2 },
    { width: 780, itemsToShow: 3 },
    { width: 1160, itemsToShow: 4 },
    { width: 1480, itemsToShow: 5 },
  ];

  return (
      <div className="content-wrapper">
        <h1 className="section-title">ELÉGEDETT ÜGYFELEINK</h1>
        <div className="line-container">
          <div className="clientsLine"></div>
        </div>
        <h6 className="clients-description">
          Célkitűzésünk a minőségi ügyfélkiszolgálás és ügyfeleink maximális elégedettsége.<br />
          Cégünk több, mint 15 éve az elégedett ügyfelek megbízható partnere.
        </h6>

        <Carousel breakPoints={breakPoints} className="clientCarousel">
          {clientsData.map((item, i) => (
            <a key={i} href={item.host} className="client-link">
              <Card className="client-card">
                <img className="client-image" src={item.image} alt={item.name} />
                <div className="client-text">
                  <h6 className="client-name">{item.name}</h6>
                </div>
              </Card>
            </a>
          ))}
        </Carousel>
      </div>
  );
};

export default Clients;