import React, { useEffect, useState, useRef } from "react";
import { sliderData } from "../slider-data";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../style/home.css";
//import Model from "./Model";

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  const slideInterval = useRef(null); // useRef használata
  const intervalTime = 4000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        slideInterval.current = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => {
      clearInterval(slideInterval.current);
    };
  }, [currentSlide, autoScroll]);

  return (
    <div style={{maxWidth:"100%"}}>
      {/*<div>
        <Model />
      </div>*/}
      <div className="pic-slider">
        <ChevronLeftIcon className="arrow prev" onClick={prevSlide} />
        <ChevronRightIcon className="arrow next" onClick={nextSlide} />

        {sliderData.map((slide, index) => {
          const { image, heading, desc,link } = slide;
          return (
            <div key={index} className={index === currentSlide ? "pic-slide current" : "pic-slide"}>
              {index === currentSlide && (
                <>
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat", 
                    }}
                    src={image}
                    alt="pic-slide"
                  />
                  <div className="slide-content">
                    <h2 style={{ color: "white" }}>{heading}</h2>
                    <p style={{ color: "white" }}>{desc}</p>
                    <hr />
                    <a href={link} className="slide-content-btn">
                      Ugrás a termékekre
                    </a>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="szechenyi">
        <a href="/szechenyi">
          <img className="szechenyiImg" src="/images/szechenyi.png" alt="" />
        </a>
      </div>
    </div>
  );
};

export default Home;
