import React from 'react'
import { TextField} from "@mui/material";


const SearchEngine = ({setSearch,search}) => {

    return (

    <div className="search" >
        <input
        id="search"
        className="search"
        style={{padding:"6px",margin:"20px",width:"260px",borderColor:'grey',borderRadius:'10px' }}
        placeholder='Keresés..'
        value={search}
        onChange={(e) => setSearch(e.target.value)}/>
    
    </div>
  
  )
}

export default SearchEngine;