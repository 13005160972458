import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FacebookIcon from "@mui/icons-material/Facebook";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import "../style/header.css";

const Header = ({ size }) => {
  const [sidebar, setSidebar] = useState(false);

  // Adds or removes the "active" class based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      header.classList.toggle("active", window.scrollY > 180);
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className="header">
      <div className="container_header">
        <div className="logo">
          <Link to="/">
            <img className="dentalIMG" src="/images/dental.png" alt="Dental Logo" />
          </Link>
        </div>
        <div className="nav">
          <ul className={sidebar ? "nav-links-sidebar" : "nav-links"}>
            <li><Link to="/termekek">Termékek</Link></li>
            <li><Link to="/service">Szolgáltatások</Link></li>
            <li><Link to="/about">Rólunk</Link></li>
            <li><Link to="/contact">Kapcsolat</Link></li>
            <li className="icon">
              <Link to="/cart">
                <ShoppingCartIcon className="HeaderIcon" /> <span>{size}</span>
              </Link>
              <a href="https://www.facebook.com/kft.dentalcoopplus/">
                <FacebookIcon className="HeaderIcon" style={{ color: "blue" }} />
              </a>
            </li>
          </ul>
          <div className="search_info">
            <a href="tel:0036209565480"><h6><PhoneIcon /> +36 94 318 590</h6></a>
            <a href="mailto:info@dentalcoopplus.hu"><h6><EmailIcon /> info@dentalcoopplus.hu</h6></a>
          </div>
        </div>
        <button className="navbar-items-icon" onClick={() => setSidebar(!sidebar)}>
          {sidebar ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>
    </header>
  );
};

export default Header;