import { Col, Row, Descriptions } from "antd";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { products, categories } from "../data";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "react-medium-image-zoom/dist/styles.css";
import "../style/detailspage.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const DetailsPage = ({ handleClick }) => {
  const navigate = useNavigate();

  const formatToUrl = (text) => {
    return text.toLowerCase().replace(/\s+/g, "_");
  };

  const { name } = useParams();
  const formattedName = formatToUrl(name);
  const product = products.find((prod) => formatToUrl(prod.name) === formattedName);

  const category = categories.find((cat) => cat.id === product.category);

  const imageUrl = `https://dentalcoopplus.hu/uploads/${product.image}`;
  const imageUrl2 = `https://dentalcoopplus.hu/uploads/${product.image2}`;
  const imageUrl3 = `https://dentalcoopplus.hu/uploads/${product.image3}`;
  const imageUrl4 = `https://dentalcoopplus.hu/uploads/${product.image4}`;
  const imageUrl5 = `https://dentalcoopplus.hu/uploads/${product.image5}`;

  const formatDescription = (text) => {
    const paragraphs = text.split(/(?<=\.)\s+/).filter(Boolean);

    return paragraphs.map((para, index) => {
      if (para.trim().startsWith("•")) {
        const listItems = para
          .split(/\n/)
          .filter(Boolean)
          .map((item, i) => <li key={i}>{item.replace(/^\s*/, "")}</li>);
        return <ul key={index}>{listItems}</ul>;
      }

      return <p key={index}>{para.trim()}</p>;
    });
  };

  return (
    <div className="postPage" style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginLeft: "20px",
          marginRight: "20px",
        }}>
        <div className="arrowBackIcon" onClick={() => navigate("/termekek")}>
          <ArrowBackIcon style={{ fontSize: "50px" }} />
        </div>
        <div>
          <h1>{product.name}</h1>
        </div>
      </div>

      <br />

      <Row gutter={[16, 16]} className="detailsContainer">
        <Col lg={12} xs={24} className="detailsCol1">
          <CarouselProvider
            naturalSlideWidth={455}
            naturalSlideHeight={376}
            totalSlides={5}
            infinite={true}
            isPlaying={true} // Automatikus lapozás, ha szükséges
            interval={3000} // Lapozás sebessége
            className="detailsImage">
            <Slider>
              {product.image && (
                <Slide index={0}>
                  <img className="detailsImages" src={imageUrl} alt={product.name} />
                </Slide>
              )}
              {product.image2 && (
                <Slide index={1}>
                  <img className="detailsImages" src={imageUrl2} alt={product.name} />
                </Slide>
              )}
              {product.image3 && (
                <Slide index={2}>
                  <img className="detailsImages" src={imageUrl3} alt={product.name} />
                </Slide>
              )}
              {product.image4 && (
                <Slide index={3}>
                  <img className="detailsImages" src={imageUrl4} alt={product.name} />
                </Slide>
              )}
              {product.image5 && (
                <Slide index={4}>
                  <img className="detailsImages" src={imageUrl5} alt={product.name} />
                </Slide>
              )}
            </Slider>
            <ButtonBack className="detBtn">
              <ArrowBackIosNewIcon />
            </ButtonBack>
            <ButtonNext className="detBtn">
              <ArrowForwardIosIcon />
            </ButtonNext>
          </CarouselProvider>
        </Col>
        <Col lg={12} xs={24} className="detailsCol2">
          <div>
            <div className="details-price">
              <Descriptions.Item label="Price">
                <h4>Ára:</h4>
                {(() => {
                  if (product.price > 1) {
                    return product.price * 400 + ",-Ft.";
                  } else {
                    return "Árajánlatkérés";
                  }
                })()}
              </Descriptions.Item>
            </div>
            <div className="details-category">
              <Descriptions.Item label="Category">
                <h4>Termék megnevezése:</h4>
                {product.name}
              </Descriptions.Item>
            </div>
            <div className="details-subcategory">
              <Descriptions.Item label="Subcategory">
                <h4>Kategória:</h4>
                {category.name}
              </Descriptions.Item>
            </div>
            <div className="details-desc">
              <Descriptions.Item label="Description">
                <h4>Termék leírás:</h4>
                {formatDescription(product.desc)}
                <p>{product.desc1}</p>
                <p>{product.desc2}</p>
                <p>{product.desc3}</p>
                <p>{product.desc4}</p>
                <p>{product.desc5}</p>
                <p>{product.desc6}</p>
                <p>{product.desc7}</p>
                <p>{product.desc8}</p>
                <p>{product.desc9}</p>
                <p>{product.desc10}</p>
                <p>{product.desc11}</p>
                <p>{product.desc12}</p>
                <p>{product.desc13}</p>
                <p>{product.desc14}</p>
                <p>{product.desc15}</p>
                <p>{product.desc16}</p>
                <p>{product.desc17}</p>
                <p>{product.desc18}</p>
                <p>{product.desc19}</p>
                <p>{product.desc20}</p>
                <p>{product.desc21}</p>
                <p>{product.desc22}</p>
                <p>{product.desc23}</p>
                <p>{product.desc24}</p>
                <p>{product.desc25}</p>
                <p>{product.desc26}</p>
                <p>{product.desc27}</p>
                <p>{product.desc28}</p>
                <p>{product.desc29}</p>
                <p>{product.desc30}</p>
                <p>{product.desc31}</p>
                <p>{product.desc32}</p>
                <p>{product.desc33}</p>
                <p>{product.desc34}</p>
                <p>{product.desc35}</p>
                <p>{product.desc36}</p>
                <p>{product.desc37}</p>
              </Descriptions.Item>
            </div>

            <br />
            <br />
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DetailsPage;
