import React from "react";
// import About from "../components/About";
import Home from "../components/Home";
import Services from "../components/Services";
import Slider from "../components/Slider";
import Clients from "../components/Clients";
import Companies from "../components/Companies";

const Main = () => {
  return (
    <>
      <div style={{overflow:"hidden"}}>
        <Home />
        <div style={{ marginBottom: "40px" }}></div>
        <Slider />
        <Companies />
        <Services />
        {/* <div
      style={{
        height: "3px", 
        background: "linear-gradient(to right, #197db3, #61bff2)", 
        borderRadius: "6px",  
      }}
    /> */}
        <Clients />
      </div>
    </>
  );
};

export default Main;
