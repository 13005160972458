export const company = [
    {
        id:1,
        image:'/images/sternweber.jpg'
    },
    {
        id:2,
        image:'/images/myray.jpg'
    },
    {
        id:3,
        image:'/images/cattani.jpg'
    },
    {
        id:4,
        image:'/images/nsk.jpg'
    },
    {
        id:5,
        image:'/images/nouvag.png'
    },
    {
        id:6,
        image:'/images/technogaz.jpg'
    },
    {
        id:7,
        image:'/images/tkd.jpg'
    },
    {
        id:8,
        image:'/images/faro.jpg'
    },
    {
        id:9,
        image:'/images/bodeku.jpg'
    },
    {
        id:10,
        image:'/images/zilfor.jpg'
    },
    {
        id:11,
        image:'/images/enbiologo.jpg'
    },
    {
        id:12,
        image:'/images/sternweber.jpg'
    },
    {
        id:13,
        image:'/images/myray.jpg'
    },
    {
        id:14,
        image:'/images/cattani.jpg'
    },
    {
        id:15,
        image:'/images/nsk.jpg'
    },
    {
        id:16,
        image:'/images/nouvag.png'
    },
    {
        id:17,
        image:'/images/technogaz.jpg'
    },
    {
        id:18,
        image:'/images/tkd.jpg'
    },
    {
        id:19,
        image:'/images/faro.jpg'
    },
    {
        id:20,
        image:'/images/bodeku.jpg'
    },
    {
        id:21,
        image:'/images/zilfor.jpg'
    },
    {
        id:22,
        image:'/images/enbiologo.jpg'
    },
    {
        id:23,
        image:'/images/sternweber.jpg'
    },
    {
        id:24,
        image:'/images/myray.jpg'
    },
    {
        id:25,
        image:'/images/cattani.jpg'
    },
    {
        id:26,
        image:'/images/nsk.jpg'
    },
    {
        id:27,
        image:'/images/nouvag.png'
    },
    {
        id:28,
        image:'/images/technogaz.jpg'
    },
    {
        id:29,
        image:'/images/tkd.jpg'
    },
    {
        id:30,
        image:'/images/faro.jpg'
    },
    {
        id:31,
        image:'/images/bodeku.jpg'
    },
    {
        id:32,
        image:'/images/zilfor.jpg'
    },
    {
        id:33,
        image:'/images/enbiologo.jpg'
    },
    {
        id:34,
        image:'/images/sternweber.jpg'
    },
    {
        id:35,
        image:'/images/myray.jpg'
    },
    {
        id:36,
        image:'/images/cattani.jpg'
    },
    {
        id:37,
        image:'/images/nsk.jpg'
    },
    {
        id:38,
        image:'/images/nouvag.png'
    },
    {
        id:39,
        image:'/images/technogaz.jpg'
    },
    {
        id:40,
        image:'/images/tkd.jpg'
    },
    {
        id:41,
        image:'/images/faro.jpg'
    },
    {
        id:42,
        image:'/images/bodeku.jpg'
    },
    {
        id:43,
        image:'/images/zilfor.jpg'
    },
    {
        id:44,
        image:'/images/enbiologo.jpg'
    },
];