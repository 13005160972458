import React from "react";
import ProductCard from "./ProductCard";
import { sliderData2 } from "../slider-data";
import "../style/slider.css";
import ReactElasticCarousel from "react-elastic-carousel";

const Slider = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 }, // A legkisebb nézetekhez
    { width: 480, itemsToShow: 2 }, // Kis képernyőhöz
    { width: 768, itemsToShow: 3 }, // Közepes képernyőkhöz
    { width: 1024, itemsToShow: 4 }, // Nagyobb képernyőkhöz
    { width: 1440, itemsToShow: 5 }, // Extra nagy képernyőkhöz
  ];

  return (
    <div className="slider-container" style={{maxWidth:"100%"}}>
      <h2 className="slider-title">TERMÉKEK</h2>
      <div className="slider-line"></div>
      <div className="slider-wrapper">
        <ReactElasticCarousel showArrows={true} autoPlaySpeed={2500} focusOnSelect={true} breakPoints={breakPoints} className="slider-carousel">
          {sliderData2.map((item, i) => (
            <ProductCard key={i} name={item.heading} image={item.image} desc={item.desc} link={item.link}/>
          ))}
        </ReactElasticCarousel>
      </div>
    </div>
  );
};

export default Slider;
