import React, { useState } from "react";
import "../style/footer.css";
import "../style/aboutpage.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [code, setCode] = useState("");
  const [isAdminInputVisible, setIsAdminInputVisible] = useState(false);

  const navigate = useNavigate();

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmitCode = () => {
    if (code === "Dental2019") {
      localStorage.setItem('isAuthorized', 'true');
      setIsAdminInputVisible(false); 
      navigate("/admin");
      setCode("");
    } else {
      alert("Invalid code. Please try again.");
    }
  };

  return (
    <>
      <footer>
        <div className="container_grid1">
          <div className="box">
            <h2>NYITVATARTÁS</h2>
            <div>
              <p>Hétfő-Csütörtök 09:00-16:30</p>
              <p>Péntek 09:00-15:00</p>
            </div>
          </div>

          <div className="box">
            <h2>ELÉRHETŐSÉGEK</h2>
            <div className="text">
              <p>9700 Szombathely , Sárvár u. 12.</p>
            </div>
            <div className="text">
              <a href="mailto:info@dentalcoopplus.hu">
                <p>info@dentalcoopplus.hu</p>
              </a>
            </div>
            <div className="text">
              <a href="tel:+36209565480">
                <p>+36 20 956 5480</p>
              </a>
            </div>
          </div>

          <div className="box">
            <h2>INFORMÁCIÓK</h2>
            <div style={{ marginTop: "6px" }}>
              <a href="/dpi" className="text">
                Adatkezelési tájékoztató
              </a>
            </div>
            <a href="/impresszum" className="text">
              Impresszum
            </a>
            <div style={{ marginTop: "6px" }}>
              <a href="/privacy" className="text">
                ÁSZF
              </a>
            </div>
          </div>
        </div>

        <div className="legal_container">
          <p style={{ color: "#0f3d7e" }}>® Minden jog fenntartva - Dentalcoopplus Kft. - 2006-2022</p>
          {isAdminInputVisible ? (
            <div>
              <input type="password" style={{ borderRadius: "8px", marginRight: '4px' }} value={code} onChange={handleCodeChange} placeholder="Admin kód" />
              <button
                className="aboutButton"
                style={{
                  marginBottom: "20px",
                  borderRadius: "10px",
                  padding: "2px 20px",
                  backgroundColor: "#0f3d7e",
                  border: "none",
                  color: "white",
                }}
                onClick={handleSubmitCode}>
                Bejelentkezés
              </button>
            </div>
          ) : (
            <button
              className="aboutButton"
              style={{
                marginBottom: "20px",
                borderRadius: "10px",
                padding: "2px 20px",
                backgroundColor: "#0f3d7e",
                border: "none",
                color: "white",
              }}
              onClick={() => setIsAdminInputVisible(true)}>
              Admin
            </button>
          )}
          <label>
            <img className="img_dental" src="/images/dental.png" alt="" />
          </label>
        </div>
      </footer>
    </>
  );
};

export default Footer;
